
import React from "react";

import { Col, Row, Form, Card, Button, Container, InputGroup ,Toast} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FlexContainer=styled.div`
    display:flex;
    width:100%;
    padding:10px;
    flex-wrap: wrap;
    `;
    


    const FlexElement=styled.div`
    flex-grow:1;
    margin:4px;
    `;

    
      
    


export class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        useremail: '',
        userotp: '', 
        ready: false,
        showtoast1:false,
        showtoast2:false,
        showtoast3:false,
      }
    }
    
    submitEmail=(event)=>{
        event.preventDefault();
        //gbconsole.log('111')
        localStorage.setItem('ResetEmail',this.state.useremail);
        //this.props.refreshCallback('recoverpassword');

        const requestOptions1 = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            
        };

        fetch(process.env.REACT_APP_DEBUG_URL+'/api/verifyemail'+"?MobileNo=8697844809&EmailID="+this.state.useremail, requestOptions1)
        .then(response => response.json())
        .then(data => {
          if(data['Status']=='Failure'){
            this.setState({showtoast3:true});
          }else{
            localStorage.setItem('OTP',data['OTP'])
            //gbconsole.log('branches=',data)
            this.setState({showtoast1:true});
          }
          
        });
      }
      toggleShowA=(event)=>{
        this.setState({showtoast1:false});
      }
      toggleShowB=(event)=>{
        this.setState({showtoast2:false});
      }
      toggleShowC=(event)=>{
        this.setState({showtoast3:false});
      }

      submitOtp=(event)=>{
        event.preventDefault();
        //gbconsole.log('111')
        // localStorage.setItem('ResetEmail',this.state.useremail);
        // this.props.refreshCallback('recoverpassword');
        let otp=localStorage.getItem("OTP");
        if(otp!=this.state.userotp){
          this.setState({showtoast2:true});
        }else
        {
          this.props.refreshCallback('recoverpassword');
        }
      }
      handleChange = (event) => {    
        this.setState({[event.target.name]: event.target.value});    
      }



  render() {
    return (
      
         <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link  className="text-gray-700">
               
            </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
                <Form onSubmit={this.submitEmail}>
                  <Row>
                    <Col>
                    <Form.Group controlId="formGridAddress2">
                    <Form.Label htmlFor="email">Your Email</Form.Label>
                       
                       <Form.Control required autoFocus type="email" type="email"  name="useremail" value={this.state.useremail} onChange={this.handleChange} />
                       
                      <Button variant="primary" type="submit" className="margin-top-10" >
                       Verify
                     </Button>
                     
                    </Form.Group>
                     </Col>
                     
                    </Row>
                     </Form>

                     <Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
                     <Toast.Header>            
                     <strong className="mr-auto">Success</strong>                    
                     </Toast.Header>
                    <Toast.Body>Email verified,Otp sent,check your email</Toast.Body>
                     </Toast>

                     <Toast show={this.state.showtoast3} onClose={this.toggleShowC} className="toast-border">
                     <Toast.Header>            
                     <strong className="mr-auto toast-header-failure">Failure</strong>                    
                     </Toast.Header>
                    <Toast.Body>Your Email doesnot exist in our records</Toast.Body>
                     </Toast>

                     <Form onSubmit={this.submitOtp}>
                     <Row>
                    <Col>
                    <Form.Group controlId="formGridAddress2">
                     <Form.Label htmlFor="email">Enter OTP</Form.Label>
                    
                     <Form.Control required autoFocus type="text" type="text"  name="userotp" value={this.state.userotp} onChange={this.handleChange} />
                     <Button variant="primary" type="submit" className="margin-top-10" >
                    Recover password
                  </Button>
                   <Button variant="primary" type="button" className="margin-top-left-10" onClick={(event)=>{event.preventDefault();this.props.refreshCallback('login');}}>
                      Back to login
                    </Button>
                     </Form.Group>
                     </Col>
                     
                     
                  
                    </Row>
                   
                </Form>

                <Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
                     <Toast.Header>            
                     <strong className="mr-auto toast-header-failure">Failure</strong>                    
                     </Toast.Header>
                    <Toast.Body>Otp doesnot match!</Toast.Body>
                     </Toast>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
      
    )
  }
}

export default ForgotPassword
