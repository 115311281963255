import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as FcIcons from "react-icons/fc";
const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  // font-size: 18px;
  font-size: small;

  &:hover {
    background: #632ce4;
    border-left: 4px solid #632ce4;
    cursor: pointer;
    color:yellow;
    text-decoration:none;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #414757;
  height: 40px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  //font-size: 18px;
  font-size: smaller;

  &:hover {
    background: #632ce4;
    cursor: pointer;
    color:yellow;
    text-decoration:none;
  }
`;
const DynamicFaIcon = ({ name }) => {
  const IconComponent = FaIcons[name];

  if (!IconComponent) { // Return a default one
    return <FaIcons.FaBeer />;
  }

  return <IconComponent />;
};

const DynamicAiIcon = ({ name }) => {
  const IconComponent = AiIcons[name];

  if (!IconComponent) { // Return a default one
    return <AiIcons.AiFillAlert />;
  }

  return <IconComponent />;
};

const DynamicIOIcon = ({ name }) => {
  const IconComponent = IoIcons[name];

  if (!IconComponent) { // Return a default one
    return <IoIcons.IoIosApps />;
  }

  return <IconComponent />;
};

const DynamicRiIcon = ({ name }) => {
  const IconComponent = RiIcons[name];

  if (!IconComponent) { // Return a default one
    return <RiIcons.RiAncientGateFill />;
  }

  return <IconComponent />;
};

const DynamicFcIcon = ({ name }) => {
  const IconComponent = FcIcons[name];

  if (!IconComponent) { // Return a default one
    return <RiIcons.RiAncientGateFill />;
  }

  return <IconComponent />;
};

const SubMenu = ({ item,currentLink,parentprops,menuclick }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  const iconSwitch=(icon) =>{
//gbconsole.log("icon=",icon)
    switch(icon.split(".")[0]) {
      case 'FaIcons':
        return <DynamicFaIcon name={icon.split(".")[1]} />;
      case 'AiIcons':
          return <DynamicAiIcon name={icon.split(".")[1]} />;;  
      case 'IoIcons':
          return <DynamicIOIcon name={icon.split(".")[1]} />;; 
      case 'RiIcons':
          return <DynamicRiIcon name={icon.split(".")[1]} />;;    
      case 'FcIcons':
          return <DynamicFcIcon name={icon.split(".")[1]} />;;       
    }
  }

  return (
    <>
      <SidebarLink to="" style={(currentLink==item.path)?{ backgroundColor: '#798e8e', borderLeft:'4px solid #632ce4'}:{}}  onClick={item.subNav && showSubnav}>
        <div>
        { iconSwitch(item.icon)}
          <SidebarLabel  onClick={()=>{parentprops(item.path);menuclick()}}>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to="" style={(currentLink==item.path)?{ backgroundColor: '#798e8e', borderLeft:'4px solid #632ce4'}:{}}>
            { iconSwitch(item.icon)}
              
              <SidebarLabel onClick={()=>{parentprops(item.path);menuclick()}}>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
