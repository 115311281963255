import React from 'react';
import '../style.css';
import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';


class Footer extends React.Component {


    constructor(props) {
        super(props);
    }
    
    componentDidMount() { 
       
      }


      render() { 

        return (

            <>
            footer
            </>
        );
      }




}

export default Footer;