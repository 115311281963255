import React from 'react';

import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl,label,input,h2,h3,h4,h5} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';
import styled from 'styled-components';
import DataGrid from './Misc/DataGrid';
import './Invoice.css';
import logoimage from '../assets/images/yoga.png';
import waitimage from '../assets/images/wait.webp';
import Razorpay from 'razorpay';
class Invoice extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            tablename:'',
            admissionid:this.props.admissionid,
            tableid:0,  
            tablecaption:'',
            tableadvancefilter:0,         
            fielddetails:[],         
            tablelists:[],
            columnstoview:["Fieldvalue"],
            showtoast1:false,
            showtoast2:false,
            showtoast3:false,
            error:'',
            showmodal:false,
            actualvisiblecolumns:[],
            iswizard:0,
            wizardno:0,
            wizardtitle:'',
            wizardbutton:'',
            wizardnos:[],
            wizardbuttons:[],
            wizardtitles:[],
            admission_sel_courseid:0,
            admission_sel_sessionid:0,

            course:'',
            hostelfacility:'',
            hostelfees:'',
            FirstName:'',
            MiddleName:'',
            LastName:'',
            StudentID:'',
            InvoiceID:'',
            InvoiceTotal:'',
            FeeDetails:[],
            IssueDate:'',
            PaymentDate:'',
            InvoiceStatus:'',

            token:null
			
			
            
            
        }
    }
    
    componentDidMount() { 
        const query = new URLSearchParams(window.location.search);//http://localhost:3000/studentadmission?invtoken=5
        const invtoken = query.get('invtoken')

        let postData = {
         
         
            "AdmissionID":(this.props.admissionid==undefined?invtoken:this.props.admissionid)
            
             }
             var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(postData)
              
            };
            let url = process.env.REACT_APP_DEBUG_URL+'/api/'+'getinvoicedetails';

            fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
                this.setState({
                    course:result['course'],
                    hostelfacility:result['hostelfacility'],
                    hostelfees:result['hostelfees'],
                    FirstName:result['firstname'],
                    MiddleName:result['middlenname'],
                    LastName:result['lastname'],
                    StudentID:result['studentid'],
                    InvoiceID:result['invoiceid'],
                    InvoiceTotal:result['invoicetotal'],
                    FeeDetails:result['fees'],
                    IssueDate:result['issuedate'],
                    PaymentDate:result['paymentdate'],
                    InvoiceStatus:result['invoicestatus']

                },()=>{
                    this.setState({ready:true})
                    //gbconsole.log('state=',this.state)
                })
               


            }); 
    
        

        
       
      }

      payNow(){
          var keyid='rzp_test_Cg1ebQ5raoFprW';
          var keysecret='BL72gROuduUGIXFxklQFUuj0';
        

        var instance = new Razorpay({ key_id: keyid, key_secret: keysecret })

var options = {
  amount: 50000,  
  currency: "INR",
  receipt: "order_rcptid_11"
};
// instance.orders.create(options, function(err, order) {
//   //gbconsole.log(order);
// });
instance.orders.fetch('orderId')

      }


      render() { 

        if(this.state.ready==false)
      return(<div className='waitdiv'><center><img className="waitimage" src={waitimage} /></center></div>)
      else
      {
        const feesdetails=this.state.FeeDetails.map((item,i)=>{
           // //gbconsole.log('--fd-----',i)
            return <div class="row mb-2 mb-sm-0 py-25">
            <div class="d-none d-sm-block col-1">{i}</div>
            <div class="col-9 col-sm-5">{item['FeeHead']}</div>
            
            <div class="col-2 text-secondary-d2">Rs&nbsp;{item['Fees']}</div>
        </div>
      
            
                            
      
            })

            const hostelfeesdetails=this.state.hostelfacility!=null&&this.state.hostelfacility!=undefined&&this.state.hostelfacility!=''?<div class="row mb-2 mb-sm-0 py-25">
                 <div class="d-none d-sm-block col-1">{this.state.FeeDetails.length+1}</div>
                 <div class="col-9 col-sm-5">Hostel Fees&nbsp;({this.state.hostelfacility})</div>
                 
                 <div class="col-2 text-secondary-d2">Rs&nbsp;{this.state.hostelfees}</div>
             </div>:null
           
                 
                                 
           
                 
      
        const WorkArea = styled.div`
        background: white;
        left:250;
        height: 100vh;
        display: flex;
        
      `;

     

const tablerowarray=[

{
FirstName:"Admin",
LastName:"admin",
Jobtitle:"Administrator"

} ,
{
FirstName:"Govinda",
LastName:"Basu",
Jobtitle:"User"

} ,
{
FirstName:"Suprio",
LastName:"Basu",
Jobtitle:"User"

} ,





]

        return (

            
            <div>
          
          {/* <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" /> */}

<div class="page-content container">
    <div class="page-header text-blue-d2">
        <h1 class="page-title text-secondary-d1">
            Invoice
            <small class="page-info">
                <i class="fa fa-angle-double-right text-80"></i>
                ID: #{this.state.InvoiceID}
            </small>
        </h1>

        <div class="page-tools">
            <div class="action-buttons">
                <a class="btn bg-white btn-light mx-1px text-95" href="#" data-title="Print">
                    <i class="mr-1 fa fa-print text-primary-m1 text-120 w-2"></i>
                    Print
                </a>
                <a class="btn bg-white btn-light mx-1px text-95" href="#" data-title="PDF">
                    <i class="mr-1 fa fa-file-pdf-o text-danger-m1 text-120 w-2"></i>
                    Export
                </a>
            </div>
        </div>
    </div>

    <div class="container px-0">
        <div class="row mt-4">
            <div class="col-12 col-lg-12">
                <div class="row">
                    <div class="col-12">
                        <div class="text-center text-150">
                            {/* <i class="fa fa-book fa-2x text-success-m2 mr-1"></i> */}
                            <img  src={logoimage} />
                            {/* <span class="text-default-d3">Bootdey.com</span> */}
                        </div>
                    </div>
                </div>
                {/* <!-- .row --> */}

                <hr class="row brc-default-l1 mx-n1 mb-4" />

                <div class="row">
                    <div class="col-sm-6">
                        <div>
                            <span class="text-sm text-grey-m2 align-middle">Student ID:</span>
                            <span class="text-600 text-110 text-blue align-middle">{this.state.StudentID}</span>
                        </div>
                        <div>
                            <span class="text-sm text-grey-m2 align-middle">Student Name:</span>
                            <span class="text-600 text-110 text-blue align-middle">{this.state.FirstName+' '+(this.state.MiddleName==null?'':(this.state.MiddleName+' '))+(this.state.LastName==null?'':this.state.LastName)}</span>
                        </div>
                        {/* <div class="text-grey-m2">
                            <div class="my-1">
                                Street, City
                            </div>
                            <div class="my-1">
                                State, Country
                            </div>
                            <div class="my-1"><i class="fa fa-phone fa-flip-horizontal text-secondary"></i> <b class="text-600">111-111-111</b></div>
                        </div> */}
                    </div>
                    {/* <!-- /.col --> */}

                    <div class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                        <hr class="d-sm-none" />
                        <div class="text-grey-m2">
                            <div class="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                Invoice
                            </div>

                            <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span class="text-600 text-90">ID:</span> #{this.state.InvoiceID}</div>

                            <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span class="text-600 text-90">Issue Date:</span> {this.state.IssueDate}</div>

                            <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span class="text-600 text-90">Status:</span> <span class="badge badge-warning badge-pill px-25">{this.state.InvoiceStatus}</span></div>
                        </div>
                    </div>
                     {/* /.col  */}
                </div>

                <div class="mt-4">
                    <div class="row text-600 text-white bgc-default-tp1 py-25">
                        <div class="d-none d-sm-block col-1">#</div>
                        <div class="col-9 col-sm-5">Description</div>
                        {/* <div class="d-none d-sm-block col-4 col-sm-2">Qty</div>
                        <div class="d-none d-sm-block col-sm-2">Unit Price</div> */}
                        <div class="col-2">Amount</div>
                    </div>
                    

                    <div class="text-95 text-secondary-d3">
                        {feesdetails}
                        {hostelfeesdetails}

                        {/* <div class="row mb-2 mb-sm-0 py-25">
                            <div class="d-none d-sm-block col-1">3</div>
                            <div class="col-9 col-sm-5">Software development</div>
                            <div class="d-none d-sm-block col-2">--</div>
                            <div class="d-none d-sm-block col-2 text-95">$1,000</div>
                            <div class="col-2 text-secondary-d2">$1,000</div>
                        </div>

                        <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                            <div class="d-none d-sm-block col-1">4</div>
                            <div class="col-9 col-sm-5">Consulting</div>
                            <div class="d-none d-sm-block col-2">1 Year</div>
                            <div class="d-none d-sm-block col-2 text-95">$500</div>
                            <div class="col-2 text-secondary-d2">$500</div>
                        </div> */}
                    </div>

                    <div class="row border-b-2 brc-default-l2"></div>

                     {/* or use a table instead  */}
                    
            {/* <div class="table-responsive">
                <table class="table table-striped table-borderless border-0 border-b-2 brc-default-l1">
                    <thead class="bg-none bgc-default-tp1">
                        <tr class="text-white">
                            <th class="opacity-2">#</th>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Unit Price</th>
                            <th width="140">Amount</th>
                        </tr>
                    </thead>

                    <tbody class="text-95 text-secondary-d3">
                        <tr></tr>
                        <tr>
                            <td>1</td>
                            <td>Domain registration</td>
                            <td>2</td>
                            <td class="text-95">$10</td>
                            <td class="text-secondary-d2">$20</td>
                        </tr> 
                    </tbody>
                </table>
            </div> */}
            

                    <div class="row mt-3">
                        <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                            Extra note such as company or payment information...
                        </div>

                        <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                            {/* <div class="row my-2">
                                <div class="col-7 text-right">
                                    SubTotal
                                </div>
                                <div class="col-5">
                                    <span class="text-120 text-secondary-d1">$2,250</span>
                                </div>
                            </div> */}

                            {/* <div class="row my-2">
                                <div class="col-7 text-right">
                                    Tax (10%)
                                </div>
                                <div class="col-5">
                                    <span class="text-110 text-secondary-d1">$225</span>
                                </div>
                            </div> */}

                            <div class="row my-2 align-items-center bgc-primary-l3 p-2">
                                <div class="col-7 text-right">
                                    Total Amount
                                </div>
                                <div class="col-5">
                                    <span class="text-150 text-success-d3 opacity-2">Rs&nbsp; {this.state.InvoiceTotal}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />
{this.state.InvoiceStatus=='Unpaid'?
                    <div>
                        <span class="text-secondary-d1 text-105">Thank you </span>
                        <a href={'https://schoolfrontend.modernelectronic.co.in/payments?invtoken='+this.state.InvoiceID} class="btn btn-info btn-bold px-4 float-right mt-3 mt-lg-0">Proceed to pay</a>
                        
                    </div>:null
      }
                </div>
            </div>
        </div>
    </div>
</div>

            </div>
        );
      }

    }


}

export default Invoice;
 