import React from 'react';
import '../style.css';
import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl,header,a,span,label,ul,li,h4,h1,h2,small,} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';


class Header extends React.Component {


    constructor(props) {
        super(props);
    }
    
    componentDidMount() { 
       
      }


      render() { 

        return (

            <>
            
    

            </>
        );
      }




}

export default Header;