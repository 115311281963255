import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as FcIcons from "react-icons/fc";

export const SidebarData = [
  {
    title: 'Overview',
    path: '',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Users',
        path: 'overview',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Revenue',
        path: '',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Role',
    path: 'example1',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  }
  ,
  {
    title: 'Menu Master',
    path: 'menumaster',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  },
  {
    title: 'Role assigned to user',
    path: 'roleassigned',
    icon: <FaIcons.FaCartPlus />
  }
  ,
  {
    title: 'Menu assigned to role',
    path: 'rolemenu',
    icon: <FaIcons.FaCartPlus />
  },
  {
    title: 'Team',
    path: '',
    icon: <IoIcons.IoMdPeople />
  },
  {
    title: 'Messages',
    path: '',
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Message 1',
        path: '',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Message 2',
        path: '',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Support',
    path: '',
    icon: <IoIcons.IoMdHelpCircle />
  }
];
