import React, { Component } from 'react'
import SortableTree,{removeNodeAtPath,find,getTreeFromFlatData,getFlatDataFromTree,getNodeAtPath,changeNodeAtPath,toggleExpandedForAll} from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; 
import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl,label,input,h2,h3,h4,h5} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';
import * as BsIcons from 'react-icons/bs';
import DataGrid from './Misc/DataGrid';

import styled from 'styled-components';
const REACT_VERSION = React.version;
const Containerdiv=styled.div`
    display:flex;
   
    `;
const RoleUpdate=styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:15px;
    margin-right:15px;
    padding:10px;
    `;
    const Treeview=styled.div`
    display:flex;
    justify-content:flex-start;
    margin-top:15px;
    margin-right:15px;
    padding:10px;
    height:500px;
    overflow:scroll;
    flex:50%;
    `;
    const Rightview=styled.div`
    display:flex;
    justify-content:flex-start;
    margin-top:15px;
    margin-right:15px;
    padding:10px;
   background-color:azure;
    
    flex:50%;
    `;

    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
class RoleMenu extends Component {
    constructor(props) {
        super(props)
       

        

    this.state = {
      // treeData: [
      //   { id:1,title: 'Chicken',subtitle: 'sub', children: [{ id:4,title: 'Egg' }] },
      //   { id:2,title: 'Fish', children: [{ id:5,title: 'fingerline' }] },
      //   { id:3,title: 'Fruit', children: [{ id:6,title: 'Apple' },{ id:7,title: 'Banana' },{ id:8,title: 'Mango' }] },
      // ],
      treeData:[],
      Menus:[],
      selectedrole:0,
      existingRoles:[],
      MenuRoles:[],
      ready:false,
      columnstoview:["MenuText","MenuUrl"],
      selectedidarray:[],
    };
    }
    checknodeexist(node_id){

      const flatData = getFlatDataFromTree({
        treeData: this.state.treeData,
        getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
        ignoreCollapsed: false // Makes sure you traverse every node in the tree, not just the visible ones
      }).map(({ node, path, getNodeKey }) => ({
        id: node.id,
        name: node.title,
        v:node.v,
        e:node.e,
        a:node.a,
        d:node.d,
  
        // The last entry in the path is this node's key
        // The second to last entry (accessed here) is the parent node's key
        parent: path.length > 1 ? path[path.length - 2] : null,
  
        children: `${getNodeAtPath({
          treeData: this.state.treeData,
          path: [1],
          getNodeKey
        })}`
      }));

      var exist=false;
      for(var i=0;i<flatData.length;i++){
        if(flatData[i]['id']==node_id){
        exist=true;
        break;
        }
      }

   return exist;
    }
    getdata=(event)=>{
      if(this.state.selectedrole==0 ){
        alert('user role not selected');
        return;
      }
      if(this.state.treeData.length<=0){
        alert('menu empty');
        return;
      }
      const flatData = getFlatDataFromTree({
        treeData: this.state.treeData,
        getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
        ignoreCollapsed: false // Makes sure you traverse every node in the tree, not just the visible ones
      }).map(({ node, path, getNodeKey }) => ({
        MenuID: node.id,
        name: node.title,
        ViewPermission:node.v==undefined?0:node.v,
        EditPermission:node.e==undefined?0:node.e,
        CreatePermission:node.a==undefined?0:node.a,
        DeletePermission:node.d==undefined?0:node.d,
  
        // The last entry in the path is this node's key
        // The second to last entry (accessed here) is the parent node's key
        ParentMenuID: path.length > 1 ? path[path.length - 2] : null,
  
        children: `${getNodeAtPath({
          treeData: this.state.treeData,
          path: [1],
          getNodeKey
        })}`
      }));
      //gbconsole.log(flatData);
      //gbconsole.log('role=',this.state.selectedrole);


      let postData = {
           
        "RoleID": this.state.selectedrole ,   
        "MenuAssignedToRoles":flatData,
        

  
         }
       //gbconsole.log('postdata=',postData)
       let url = process.env.REACT_APP_DEBUG_URL+'/api/updatemenurole';
       let token =localStorage.getItem("UserToken");
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
        body: JSON.stringify(postData)
       // body: postData
    };
  
    fetch(url, requestOptions)
          .then(res => res.json())
          .then(result => {
            
          this.setState({showtoast1:true});
          
          localStorage.setItem("MenuRoles", JSON.stringify(result.menuroles));
          var menuRoles=JSON.parse(localStorage.getItem("MenuRoles") || "[]");
          this.setState({MenuRoles:menuRoles,selectedrole:0,treeData:[]},()=>{
            alert('menu updated');
            document.getElementById("selectedrole").value=0;
         //gbconsole.log('menuroles=',this.state.MenuRoles)
            //this.setState({ready:true})
          })
  
          }); 


    }
    handleChange = (event) => {    
      this.setState({[event.target.name]: event.target.value},
        ()=>{
          //gbconsole.log('selected role=',this.state.selectedrole);

          let tData = [];
          var menuroles=[... this.state.MenuRoles];
          //gbconsole.log('menuroles=',menuroles);
          for(var i=0;i<menuroles.length;i++){
            if(menuroles[i]['RoleID']==this.state.selectedrole){
              var MenuItem={};
              MenuItem['id']=menuroles[i]['MenuID'];
              MenuItem['title']=menuroles[i]['MenuText'];
              MenuItem['parent']=menuroles[i]['ParentMenuID'];
              MenuItem['v']=menuroles[i]['ViewPermission'];
              MenuItem['e']=menuroles[i]['EditPermission'];
              MenuItem['d']=menuroles[i]['DeletePermission'];
              MenuItem['a']=menuroles[i]['CreatePermission'];
              tData.push(MenuItem);

            }
           

          }
          //gbconsole.log('tdata===',tData);

            //     { id: 1, title: 'Chicken', parent: null,v:0,e:0,d:0,a:0},
            //     { id: 2, title: 'Egg', parent: 1,v:0,e:0,d:0,a:0}
            //   ]

              const tData1 = getTreeFromFlatData({
            flatData: tData,
            getKey: (node) => node.id,
            getParentKey: (node) => node.parent,
            rootKey: null
          })

        //this.setState({treeData:tData},()=>{this.setState({ready:true})})

        this.setState({
          treeData: toggleExpandedForAll({
            treeData: tData1,
            expanded:true
          }),
        },()=>{
            
          this.setState({ready:true},async()=>{
            //await sleep(10);
            for(var i=0;i<tData.length;i++){
              document.getElementById("v"+tData[i].id).checked=false;
              document.getElementById("a"+tData[i].id).checked=false;
              document.getElementById("d"+tData[i].id).checked=false;
              document.getElementById("e"+tData[i].id).checked=false;

            }
            for(var i=0;i<tData.length;i++){
              if(tData[i].v==1){
                document.getElementById("v"+tData[i].id).checked=true;
              }
              if(tData[i].a==1){
                document.getElementById("a"+tData[i].id).checked=true;
              }
              if(tData[i].d==1){
                document.getElementById("d"+tData[i].id).checked=true;
              }
              if(tData[i].e==1){
                document.getElementById("e"+tData[i].id).checked=true;
              }

            }
          })

          
        
        
        });
          // this.setState({treeData:tData1},()=>{
            
          //   this.setState({ready:true},async()=>{
          //     await sleep(5000);
          //     for(var i=0;i<tData.length;i++){
          //       if(tData[i].v==1){
          //         document.getElementById("v"+tData[i].id).checked=true;
          //       }
          //       if(tData[i].a==1){
          //         document.getElementById("a"+tData[i].id).checked=true;
          //       }
          //       if(tData[i].d==1){
          //         document.getElementById("d"+tData[i].id).checked=true;
          //       }
          //       if(tData[i].e==1){
          //         document.getElementById("e"+tData[i].id).checked=true;
          //       }
  
          //     }
          //   })

            
          
          
          // })


        });    
    }

    componentDidMount() { 

      var userRoles=JSON.parse(localStorage.getItem("UserRoles") || "[]");
      var menuRoles=JSON.parse(localStorage.getItem("MenuRoles") || "[]");
        
      var Menus=JSON.parse(localStorage.getItem("Menus") || "[]");
      var tData=[];
     
      this.setState({Menus:Menus},()=>{
        for(var i=0;i<Menus.length;i++){
          var MenuItem={};
          MenuItem['id']=Menus[i]['ID'];
          MenuItem['title']=Menus[i]['MenuText'];
          tData.push(MenuItem);


        }
        this.setState({existingRoles:userRoles},()=>{
          //gbconsole.log('roles=',this.state.existingRoles.length," first role=",this.state.existingRoles[0]['ID']);
        //   const testData = [
        //     { id: 1, title: 'Chicken', parent: null,v:0,e:0,d:0,a:0},
        //     { id: 2, title: 'Egg', parent: 1,v:0,e:0,d:0,a:0}
        //   ]

        //   const tData1 = getTreeFromFlatData({
        //     flatData: testData,
        //     getKey: (node) => node.id,
        //     getParentKey: (node) => node.parent,
        //     rootKey: null
        //   })

        //  // this.setState({treeData:tData},()=>{this.setState({ready:true})})
        //   this.setState({treeData:tData1},()=>{this.setState({ready:true})})

        });
       
        this.setState({MenuRoles:menuRoles},()=>{

          this.setState({ready:true})
        })

        
        
        
      
      });
      //gbconsole.log('Menus=',Menus);
    }
    async addNode(event){
      //gbconsole.log('addnode called');
      
      if(this.state.selectedidarray.length==0){
        alert('No menu is selected');
      }else{
  //gbconsole.log(this.state.selectedidarray);

let treedata = [...this.state.treeData]; 
for(var i=0;i<this.state.selectedidarray.length;i++){
  for(var j=0;j<this.state.Menus.length;j++){
    if(this.state.Menus[j]['ID']==this.state.selectedidarray[i]){
      var node_exists=this.checknodeexist(this.state.selectedidarray[i]);
      if(node_exists==false){
        var MenuItem={};
        MenuItem['id']=this.state.Menus[j]['ID'];
        MenuItem['title']=this.state.Menus[j]['MenuText'];
        treedata.push(MenuItem);
        this.setState({treeData:treedata})

      }
      
     
      break;
    }
  }

}
      


var checkboxes=document.getElementsByTagName("input")
        for(var i=0;i<checkboxes.length;i++){
          if(checkboxes[i].type=='checkbox'&& ((checkboxes[i].name=='datagrid' &&checkboxes[i].checked==true))||(checkboxes[i].id=='selectall' &&checkboxes[i].checked==true)){
            checkboxes[i].click();
            await sleep(10)
            //gbconsole.log('selectedidarray=(',i,')=',this.state.selectedidarray)
           
          }
        }
        var Menus=JSON.parse(localStorage.getItem("Menus") || "[]");
        this.setState({selectedidarray:[],Menus:Menus},()=>{
          
        })
      }


    }
    changeNode(event,rowInfo,permtype) {
      //gbconsole.log('eventtarget=',event.target.checked,' label=',event.target)
      let {node, treeIndex, path} = rowInfo;
      //gbconsole.log(rowInfo)
      //gbconsole.log('path=',path);
      let found_node = getNodeAtPath({
        treeData: this.state.treeData,
        path    : path, // list of tree indexes on the editing node
        getNodeKey: ({ treeIndex }) =>  treeIndex,
        ignoreCollapsed : true
     })
//gbconsole.log('foundnode=',found_node.node.v);
let permvalue=event.target.checked==true?1:0;
if(permtype==1)
   found_node.node.v=permvalue;
   if(permtype==2)
   found_node.node.e=permvalue;  
   if(permtype==3)
   found_node.node.a=permvalue;
   if(permtype==4)
   found_node.node.d=permvalue;
     this.setState({treeData : this.state.treeData})
   

    

    }
    removeAll(event){
 //gbconsole.log('remove all')
this.setState({treeData:[]})
    }

removeNode(rowInfo) {
let {node, treeIndex, path} = rowInfo;
//gbconsole.log(rowInfo)
this.setState({ treeData : removeNodeAtPath({
  treeData: this.state.treeData,
  path: path,   // You can use path from here
  getNodeKey: ({node: TreeNode, treeIndex: number}) => {
      // //gbconsole.log(number);
      return number;
  },
  ignoreCollapsed: false,
})
})
}
showrolemenus(){

}
setselectedarray=(selectionarray)=>{
   
  this.setState({selectedidarray:selectionarray},()=>{
  //gbconsole.log('selectedidarray=',this.state.selectedidarray)
  
});

}


    render() {
      if(this.state.ready==false)
      return <div></div>
      else
        return (
          <div>
          <RoleUpdate>
             
              <Form.Control as="select" id="selectedrole" className="margin-5 w-60" onChange={this.handleChange} name="selectedrole">
                <option key ={0} value={0}>Select Role</option>
              
               {
              (()=>{
                if(this.state.existingRoles!=undefined&&this.state.existingRoles.length>0){
                
                  let roles=[];
                    
                  for(var i=0;i<this.state.existingRoles.length;i++){
                   
                    roles.push(<option key={this.state.existingRoles[i]['ID']} value={this.state.existingRoles[i]['ID']}>{this.state.existingRoles[i]['Name']} </option>);
                                          
                    
                    

                }
               
                return roles;

                }
                else
                return null;
                  
                    
               })()
             }
             </Form.Control>
             <Button variant="primary" type="button" className="w-40 margin-top-5 height-36" 
             onClick={this.getdata}>Assign Menus to Role</Button>
        

              </RoleUpdate>
              <Container>
                <Row>
                  <Col>
                  <Treeview>

<button label='Delete' className="fitcontent"
                onClick={(event) => this.removeAll(event)}><BsIcons.BsTrash/></button>

<SortableTree isVirtualized={false}
treeData={this.state.treeData}
maxDepth={2}
onChange={treeData => this.setState({ treeData },()=>{
  //gbconsole.log('treedata=',treeData)
})}

generateNodeProps={rowInfo => ({
buttons: [
       <div >
          <Form.Check className="Inline" type="checkbox" label="v" id={"v"+rowInfo.node.id} onClick={(event) => this.changeNode(event,rowInfo,1)} />
          <Form.Check className="Inline" type="checkbox" label="e" id={"e"+rowInfo.node.id} onClick={(event) => this.changeNode(event,rowInfo,2)} />
          <Form.Check className="Inline" type="checkbox" label="a" id={"a"+rowInfo.node.id} onClick={(event) => this.changeNode(event,rowInfo,3)} />
          <Form.Check className="Inline" type="checkbox" label="d" id={"d"+rowInfo.node.id} onClick={(event) => this.changeNode(event,rowInfo,4)} />

      <button label='Delete'
                onClick={(event) => this.removeNode(rowInfo)}><BsIcons.BsTrash/></button>
       
        </div>,
     ],
style: {
       height: '50px',
       margin:'10px',
     }
})}
/>
</Treeview>
                  </Col>
                  <Col>
                  
                  {/* <Rightview> */}
                  <button label='Delete' className="Primary"
                              onClick={(event) => this.addNode(event)}>Add</button>
          <DataGrid  setselectedarray={this.setselectedarray} rowsarray={this.state.Menus} columns={this.state.columnstoview} showeditbutton={false} showselection={true}/>
           
              
             {/* </Rightview>        */}
                  
                  </Col>
                </Row>
                </Container>
              {/* <Containerdiv>
              
             
         
          </Containerdiv> */}
          </div>
        )
    }
}

export default RoleMenu