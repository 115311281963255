import React, { Component } from 'react'
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Alert,Toast,Modal} from 'react-bootstrap'
import styled from 'styled-components';
import DataGrid from '../Components/Misc/DataGrid';
import RoleEditModal from '../Components/RoleEditModal';
import { IconData } from './IconData';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as FcIcons from "react-icons/fc";
const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    background-color: white;
    font-weight: bold;
    text-align: center;`;

    const AddRole=styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:15px;
    margin-right:15px;
    `;
    const IconDiv=styled.div`
    border: 1px solid #000;
    padding: 5px;
    width: 28px;
    margin-top: 3px;
    `;

    const DynamicFaIcon = ({ name }) => {
      const IconComponent = FaIcons[name];
    
      if (!IconComponent) { // Return a default one
        return <FaIcons.FaBeer />;
      }
    
      return <IconComponent />;
    };
    
    const DynamicAiIcon = ({ name }) => {
      const IconComponent = AiIcons[name];
    
      if (!IconComponent) { // Return a default one
        return <AiIcons.AiFillAlert />;
      }
    
      return <IconComponent />;
    };
    
    const DynamicIOIcon = ({ name }) => {
      const IconComponent = IoIcons[name];
    
      if (!IconComponent) { // Return a default one
        return <IoIcons.IoIosApps />;
      }
    
      return <IconComponent />;
    };
    
    const DynamicRiIcon = ({ name }) => {
      const IconComponent = RiIcons[name];
    
      if (!IconComponent) { // Return a default one
        return <RiIcons.RiAncientGateFill />;
      }
    
      return <IconComponent />;
    };
    const DynamicFcIcon = ({ name }) => {
      const IconComponent = FcIcons[name];
    
      if (!IconComponent) { // Return a default one
        return <RiIcons.RiAncientGateFill />;
      }
    
      return <IconComponent />;
    };

    const iconSwitch=(icon) =>{
      //gbconsole.log("icon=",icon)
          switch(icon.split(".")[0]) {
            case 'FaIcons':
              return <DynamicFaIcon name={icon.split(".")[1]} />;
            case 'AiIcons':
                return <DynamicAiIcon name={icon.split(".")[1]} />;;  
            case 'IoIcons':
                return <DynamicIOIcon name={icon.split(".")[1]} />;; 
            case 'RiIcons':
                return <DynamicRiIcon name={icon.split(".")[1]} />;;  
            case 'FcIcons':
                return <DynamicFcIcon name={icon.split(".")[1]} />;;        
          }
        }

class MenuMaster extends Component {
    constructor(props) {
        super(props)

        this.state = {
            rolename: '',
            roledescription: '',
            Menus:[],
            ready:false,
            showtoast1:false,
            showtoast2:false,
            showtoast3:false,
            error:'',
            MenuID:0,
            MenuText:'',
            Parent_Menu_ID:0,
            MenuUrl:'',
            Description:'',
            MenuIcon:'',
            MenuOrder:0,
            showmodal:false,
            showiconmodal:false,
            columnstoview:["MenuText","MenuUrl","MenuIcon","Description"],
            
             
            }
    }

    toggleShowA=(event)=>{
        this.setState({showtoast1:false});
      }
      toggleShowB=(event)=>{
        this.setState({showtoast2:false});
        this.setState({error:''});
      }
      toggleShowC=(event)=>{
        this.setState({showtoast3:false});
      }
      
      componentDidMount() { 
        
        var Menus=JSON.parse(localStorage.getItem("Menus") || "[]");
        this.setState({Menus:Menus},()=>{this.setState({ready:true})});
        //gbconsole.log('Menus=',Menus);
      }
      
        submitRole=(event)=>{
          event.preventDefault();
          
          let postData = {
           
            "MenuText": this.state.MenuText ,   
            "Parent_Menu_ID":this.state.Parent_Menu_ID,
            "MenuUrl":this.state.MenuUrl,
            "Description":this.state.Description,
            "MenuIcon":this.state.MenuIcon,
            "MenuOrder":this.state.MenuOrder,
            "recordid":this.state.MenuID,

      
             }
           //gbconsole.log('postdata=',postData)
           let url = process.env.REACT_APP_DEBUG_URL+'/api/updatemenu';
           let token =localStorage.getItem("UserToken");
           const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
            body: JSON.stringify(postData)
           // body: postData
        };
      
        fetch(url, requestOptions)
              .then(res => res.json())
              .then(result => {
                //gbconsole.log(result)          
                var status=result['Status'];
                
          if(status=='Failure'||status=='ERROR'){
          
          //gbconsole.log("error=",result['Message'])
          this.setState({error:result['Message']});
         
          this.setState({showtoast2:true});
                
          }else
          {
             
              localStorage.setItem("Menus", JSON.stringify(result.menus));
              var Menus=JSON.parse(localStorage.getItem("Menus") || "[]");
              this.setState({Menus:Menus});
              //gbconsole.log('Menus=',Menus);
              this.setState({
              MenuText:'',
              Parent_Menu_ID:0,
              MenuUrl:'',
              Description:'',
              MenuIcon:'',
              MenuOrder:0,
              MenuID:0,
              showmodal:false
            });
              this.setState({showtoast1:true});
              
          }   
      
              }); 
      
      
        }
        markup (val) {
              return { __html: val }
          }
        hideModal=()=>{
          this.setState({showmodal:false,showiconmodal:false,MenuID:0});
      }
      hideIconModal=()=>{
        this.setState({showiconmodal:false});
    }
    Showicons=(event)=>{
      this.setState({showiconmodal:true});

    }
        handleChange = (event) => {    
          this.setState({[event.target.name]: event.target.value});    
        }
        setIcon=(icon)=>{
         this.setState({MenuIcon:icon,showiconmodal:false},()=>{})
        }
        seteditid=(editid)=>{
         
          for(var i=0;i<this.state.Menus.length;i++){
            if(this.state.Menus[i]['ID']==editid){
              this.setState({
                MenuText:this.state.Menus[i]['MenuText'],
                Parent_Menu_ID:this.state.Menus[i]['Parent_Menu_ID'],
                MenuUrl:this.state.Menus[i]['MenuUrl'],
                Description:this.state.Menus[i]['Description'],
                MenuIcon:this.state.Menus[i]['MenuIcon'],
                MenuOrder:this.state.Menus[i]['MenuOrder']
               
              
              });
              break;
            }
          }
        this.setState({MenuID:editid},()=>{
          //gbconsole.log('MenuID=',this.state.MenuID)
          this.setState({showmodal:true},()=>{
           
          })
        });
        
        }
        
          render() {
            
                  if(this.state.ready==false)
                  return(<div></div>)
                  else
           
      
              return (
                  <Container>
                <Row>
                    <Col>
                    
      
      
      
                    </Col>
               
               </Row>
              
                <AddRole>
                 <Button variant="primary" type="button" onClick={()=>{this.setState({showmodal:true,MenuID:0,MenuText:'',Description:'',MenuUrl:'',MenuIcon:'',MenuOrder:''})}}>Add Menu</Button>
                 </AddRole>
               
               <Row>
                    <Col>
                    
                    <Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
      <Toast.Header>            
      <strong className="mr-auto toast-header-success">Success</strong>                    
      </Toast.Header>
      <Toast.Body>Menu created/updated</Toast.Body>
      </Toast>
                    <FormHeader>Existing Menus</FormHeader>
                    
                    <DataGrid  rowsarray={this.state.Menus}  seteditid={this.seteditid } columns={this.state.columnstoview} />
      
                    </Col>
              </Row>
      
      
      
              <Modal show={this.state.showmodal} onHide={this.hideModal} aria-labelledby="contained-modal-title-vcenter">
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Update Menu
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="show-grid">
                    <Container>
                    <Row>
                    <Col>
                    
                    <Form onSubmit={this.submitRole}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Menu Text *</Form.Label>
          <Form.Control type="text"  name="MenuText" value={this.state.MenuText} onChange={this.handleChange} />
         
        </Form.Group>
{/*
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Parent Menu *</Form.Label>
          <Form.Control type="text"  name="Parent_Menu_ID" value={this.state.Parent_Menu_ID} onChange={this.handleChange} />
         
        </Form.Group>
*/ }
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Menu Url *</Form.Label>
          <Form.Control type="text"  name="MenuUrl" value={this.state.MenuUrl} onChange={this.handleChange} />
         
        </Form.Group>
      
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" rows={3} name="Description" value={this.state.Description} onChange={this.handleChange} />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Menu Icon</Form.Label>
          <Row>
            <Col xs="12" md="6" lg="8">
          <Form.Control type="text"  name="MenuIcon" value={this.state.MenuIcon}  onChange={this.handleChange} />
          </Col>
          <Col>
          <Button variant="primary" type="button" onClick={this.Showicons}>
          Show Icons
        </Button>
        </Col>
         </Row>
        </Form.Group>
{/*
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Menu Order</Form.Label>
          <Form.Control type="text"  name="MenuOrder" value={this.state.MenuOrder} onChange={this.handleChange} />
         
        </Form.Group>
*/ }
        <Button variant="primary" type="submit">
          Submit
        </Button>
        
      </Form>
      
      
      
      <Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
      <Toast.Header>            
      <strong className="mr-auto toast-header-failure">Failure</strong>                    
      </Toast.Header>
      <Toast.Body dangerouslySetInnerHTML={this.markup(this.state.error)}></Toast.Body>
      </Toast>
      
                    </Col>
               
               </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={this.hideModal}>Close</Button>
                  </Modal.Footer>
                </Modal>







                <Modal show={this.state.showiconmodal} onHide={this.hideIconModal} aria-labelledby="contained-modal-title-vcenter">
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Select Icon
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="show-grid">
                    <Container>
                    <Row>
                   
                      {
                      
                      IconData.map((item,i)=>{

                        return  <Col>
                        <IconDiv onClick={()=>{this.setState({MenuIcon:item,showiconmodal:false})}}>
                        {iconSwitch(item)}
                        </IconDiv>
                    
                   
                    </Col>
                        })
                      
                      }
                      
                   
               
               </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={this.hideIconModal}>Close</Button>
                  </Modal.Footer>
                </Modal>
      
      
      
      
      
      
      
      
      
      </Container>
              )
          }
}

export default MenuMaster