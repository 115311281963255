import React, { Component } from 'react'
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Alert,Toast,Modal} from 'react-bootstrap'
import styled from 'styled-components';
import DataGrid from '../Components/Misc/DataGrid';
import profileicon from '../assets/images/profile_Icon.png';

const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    // background-color: white;
    font-weight: bold;
    text-align: center;
    font-size:50px;
    `;
    
class UserActivation extends Component {

    constructor(props) {
        super(props);
        this.state = {
          rolename: '',
          roledescription: '',
          users:[],
          ready:false,
          showtoast1:false,
          showtoast2:false,
          showtoast3:false,
          error:'',
          roleeditid:0,
          showmodal:false,
          columnstoview:["UserName","FirstName",,"MiddleName","LastName","Status"],
          
           
          }
    }

    componentDidMount() { 
  //gbconsole.log(localStorage.getItem("Users"));
        var users=JSON.parse(localStorage.getItem("Users") || "[]");
       this.setState({users:users},()=>{this.setState({ready:true})});
       //gbconsole.log('users=',users);
      }
      userchangepassword=(userid,username)=>{
        console.log('userid=',userid)
        console.log('username=',username)
       this.props.refreshCallback("changepassword2",userid,username)
      }
      
      activationcallback=(editid)=>{
        //gbconsole.log('editid=',editid)

        let postData = {
     
          
          "UserID":editid
    
           }
         //gbconsole.log('postdata=',postData)
         let url = process.env.REACT_APP_DEBUG_URL+'/api/updateuserstatus';
         let token =localStorage.getItem("UserToken");
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
          body: JSON.stringify(postData)
         // body: postData
      };
    
      fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
              //gbconsole.log(result)          
              var status=result['status'];
              
        if(status=='Failure'){
        
        //gbconsole.log("error=",result['error'])
     //   this.setState({error:result['error']});
       
     //   this.setState({showtoast2:true});
              
        }else
        {
           
            localStorage.setItem("Users", JSON.stringify(result.users));
            var users=JSON.parse(localStorage.getItem("Users") || "[]");
            this.setState({ready:false,users:users},()=>{this.setState({ready:true})});
            //gbconsole.log('users=',users);
           
            
        }   
    
            }); 



      }

    render() {
        if(this.state.ready==false)
        return(<div></div>)
        else
 

    return (
      <div>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
             
            
             
             
              
             <ul className="navbar-nav ml-auto">
              
               <li className="nav-item dropdown no-arrow d-sm-none">
                 
                
                 <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                  
                 </div>
               </li>
               
               <li className="nav-item dropdown no-arrow mx-1">
                
                 <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                   
                 </div>
               </li>
              
               <li className="nav-item dropdown no-arrow mx-1">
                
                
                 <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
                   
                 </div>
               </li>
               <div className="topbar-divider d-none d-sm-block" />
              
               <li className="nav-item dropdown no-arrow">
                 <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   <span className="mr-2 d-none d-lg-inline text-gray-600 small">{localStorage.getItem('FirstName')}</span>
                   <img className="img-profile rounded-circle" src={profileicon} />
                 </a>
               
                  
               </li>
             </ul>
           </nav>
        <Container>
            <Row>
                <Col>
        <FormHeader>Existing Users</FormHeader>
              
        <DataGrid  rowsarray={this.state.users} showeditbutton={false} activationcallback={this.activationcallback } userchangepassword={this.userchangepassword}   columns={this.state.columnstoview} />

        </Col>
  </Row>
  </Container>
  </div>
    );
    }
}

export default UserActivation;
