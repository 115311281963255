import React from 'react';


export const IconData = [
  
 'FaIcons.Fa500Px',
 'FaIcons.FaAccessibleIcon',
 'FaIcons.FaAccusoft',
 'FaIcons.FaAcquisitionsIncorporated',
 'FaIcons.FaAdn',
 'FaIcons.FaAdversal',
 'FaIcons.FaAffiliatetheme',
 'FaIcons.FaAirbnb',
 'FaIcons.FaAlgolia',
 'FaIcons.FaAlipay',
 'FaIcons.FaAmazonPay',
 'FaIcons.FaAmazon',
 'FaIcons.FaAmilia',
 'FaIcons.FaAndroid',
 'FaIcons.FaAngellist',
 'FaIcons.FaAngrycreative',
 'FaIcons.FaAngular',
 'FaIcons.FaAppStoreIos',
 'FaIcons.FaAppStore',
 'FaIcons.FaApper',
 'FaIcons.FaApplePay',
 'FaIcons.FaApple',
 'FaIcons.FaArtstation',
 'FaIcons.FaAsymmetrik',
 'FaIcons.FaAtlassian',
 'FaIcons.FaAudible',
 'FaIcons.FaAutoprefixer',
 'FaIcons.FaAvianex',
 'FaIcons.FaAviato',
 'FaIcons.FaAws',
 'FaIcons.FaBandcamp',
 'FaIcons.FaBattleNet',
 'FaIcons.FaBehanceSquare',
 'FaIcons.FaBehance',
 'FaIcons.FaBimobject',
 'FaIcons.FaBitbucket',
 'FaIcons.FaBitcoin',
 'FaIcons.FaBity',
 'FaIcons.FaBlackTie',
 'FaIcons.FaBlackberry',
 'FaIcons.FaBloggerB',
 'FaIcons.FaBlogger',
 'FaIcons.FaBluetoothB',
 'FaIcons.FaBluetooth',
 'FaIcons.FaBootstrap',
 'FaIcons.FaBtc',
 'FaIcons.FaBuffer',
 'FaIcons.FaBuromobelexperte',
 'FaIcons.FaBuyNLarge',
 'FaIcons.FaBuysellads',
 'FaIcons.FaCanadianMapleLeaf',
 'FaIcons.FaCcAmazonPay',
 'FaIcons.FaCcAmex',
 'FaIcons.FaCcApplePay',
 'FaIcons.FaCcDinersClub',
 'FaIcons.FaCcDiscover',
 'FaIcons.FaCcJcb',
 'FaIcons.FaCcMastercard',
 'FaIcons.FaCcPaypal',
 'FaIcons.FaCcStripe',
 'FaIcons.FaCcVisa',
 'FaIcons.FaCentercode',
 'FaIcons.FaCentos',
 'FaIcons.FaChrome',
 'FaIcons.FaChromecast',
 'FaIcons.FaCloudflare',
 'FaIcons.FaCloudscale',
 'FaIcons.FaCloudsmith',
 'FaIcons.FaCloudversify',
 'FaIcons.FaCodepen',
 'FaIcons.FaCodiepie',
 'FaIcons.FaConfluence',
 'FaIcons.FaConnectdevelop',
 'FaIcons.FaContao',
 'FaIcons.FaCottonBureau',
 'FaIcons.FaCpanel',
 'FaIcons.FaCreativeCommonsBy',
 'FaIcons.FaCreativeCommonsNcEu',
 'FaIcons.FaCreativeCommonsNcJp',
 'FaIcons.FaCreativeCommonsNc',
 'FaIcons.FaCreativeCommonsNd',
 'FaIcons.FaCreativeCommonsPdAlt',
 'FaIcons.FaCreativeCommonsPd',
 'FaIcons.FaCreativeCommonsRemix',
 'FaIcons.FaCreativeCommonsSa',
 'FaIcons.FaCreativeCommonsSamplingPlus',
 'FaIcons.FaCreativeCommonsSampling',
 'FaIcons.FaCreativeCommonsShare',
 'FaIcons.FaCreativeCommonsZero',
 'FaIcons.FaCreativeCommons',
 'FaIcons.FaCriticalRole',
 'FaIcons.FaCss3Alt',
 'FaIcons.FaCss3',
 'FaIcons.FaCuttlefish',
 'FaIcons.FaDAndDBeyond',
 'FaIcons.FaDAndD',
 'FaIcons.FaDailymotion',
 'FaIcons.FaDashcube',
 'FaIcons.FaDeezer',
 'FaIcons.FaDelicious',
 'FaIcons.FaDeploydog',
 'FaIcons.FaDeskpro',
 'FaIcons.FaDev',
 'FaIcons.FaDeviantart',
 'FaIcons.FaDhl',
 'FaIcons.FaDiaspora',
 'FaIcons.FaDigg',
 'FaIcons.FaDigitalOcean',
 'FaIcons.FaDiscord',
 'FaIcons.FaDiscourse',
 'FaIcons.FaDochub',
 'FaIcons.FaDocker',
 'FaIcons.FaDraft2Digital',
 'FaIcons.FaDribbbleSquare',
 'FaIcons.FaDribbble',
 'FaIcons.FaDropbox',
 'FaIcons.FaDrupal',
 'FaIcons.FaDyalog',
 'FaIcons.FaEarlybirds',
 'FaIcons.FaEbay',
 'FaIcons.FaEdgeLegacy',
 'FaIcons.FaEdge',
 'FaIcons.FaElementor',
 'FaIcons.FaEllo',
 'FaIcons.FaEmber',
 'FaIcons.FaEmpire',
 'FaIcons.FaEnvira',
 'FaIcons.FaErlang',
 'FaIcons.FaEthereum',
 'FaIcons.FaEtsy',
 'FaIcons.FaEvernote',
 'FaIcons.FaExpeditedssl',
 'FaIcons.FaFacebookF',
 'FaIcons.FaFacebookMessenger',
 'FaIcons.FaFacebookSquare',
 'FaIcons.FaFacebook',
 'FaIcons.FaFantasyFlightGames',
 'FaIcons.FaFedex',
 'FaIcons.FaFedora',
 'FaIcons.FaFigma',
 'FaIcons.FaFirefoxBrowser',
 'FaIcons.FaFirefox',
 'FaIcons.FaFirstOrderAlt',
 'FaIcons.FaFirstOrder',
 'FaIcons.FaFirstdraft',
 'FaIcons.FaFlickr',
 'FaIcons.FaFlipboard',
 'FaIcons.FaFly',
 'FaIcons.FaFontAwesomeAlt',
 'FaIcons.FaFontAwesomeFlag',
 'FaIcons.FaFontAwesomeLogoFull',
 'FaIcons.FaFontAwesome',
 'FaIcons.FaFonticonsFi',
 'FaIcons.FaFonticons',
 'FaIcons.FaFortAwesomeAlt',
 'FaIcons.FaFortAwesome',
 'FaIcons.FaForumbee',
 'FaIcons.FaFoursquare',
 'FaIcons.FaFreeCodeCamp',
 'FaIcons.FaFreebsd',
 'FaIcons.FaFulcrum',
 'FaIcons.FaGalacticRepublic',
 'FaIcons.FaGalacticSenate',
 'FaIcons.FaGetPocket',
 'FaIcons.FaGgCircle',
 'FaIcons.FaGg',
 'FaIcons.FaGitAlt',
 'FaIcons.FaGitSquare',
 'FaIcons.FaGit',
 'FaIcons.FaGithubAlt',
 'FaIcons.FaGithubSquare',
 'FaIcons.FaGithub',
 'FaIcons.FaGitkraken',
 'FaIcons.FaGitlab',
 'FaIcons.FaGitter',
 'FaIcons.FaGlideG',
 'FaIcons.FaGlide',
 'FaIcons.FaGofore',
 'FaIcons.FaGoodreadsG',
 'FaIcons.FaGoodreads',
 'FaIcons.FaGoogleDrive',
 'FaIcons.FaGooglePay',
 'FaIcons.FaGooglePlay',
 'FaIcons.FaGooglePlusG',
 'FaIcons.FaGooglePlusSquare',
 'FaIcons.FaGooglePlus',
 'FaIcons.FaGoogleWallet',
 'FaIcons.FaGoogle',
 'FaIcons.FaGratipay',
 'FaIcons.FaGrav',
 'FaIcons.FaGripfire',
 'FaIcons.FaGrunt',
 'FaIcons.FaGuilded',
 'FaIcons.FaGulp',
 'FaIcons.FaHackerNewsSquare',
 'FaIcons.FaHackerNews',
 'FaIcons.FaHackerrank',
 'FaIcons.FaHips',
 'FaIcons.FaHireAHelper',
 'FaIcons.FaHive',
 'FaIcons.FaHooli',
 'FaIcons.FaHornbill',
 'FaIcons.FaHotjar',
 'FaIcons.FaHouzz',
 'FaIcons.FaHtml5',
 'FaIcons.FaHubspot',
 'FaIcons.FaIdeal',
 'FaIcons.FaImdb',
 'FaIcons.FaInnosoft',
 'FaIcons.FaInstagramSquare',
 'FaIcons.FaInstagram',
 'FaIcons.FaInstalod',
 'FaIcons.FaIntercom',
 'FaIcons.FaInternetExplorer',
 'FaIcons.FaInvision',
 'FaIcons.FaIoxhost',
 'FaIcons.FaItchIo',
 'FaIcons.FaItunesNote',
 'FaIcons.FaItunes',
 'FaIcons.FaJava',
 'FaIcons.FaJediOrder',
 'FaIcons.FaJenkins',
 'FaIcons.FaJira',
 'FaIcons.FaJoget',
 'FaIcons.FaJoomla',
 'FaIcons.FaJsSquare',
 'FaIcons.FaJs',
 'FaIcons.FaJsfiddle',
 'FaIcons.FaKaggle',
 'FaIcons.FaKeybase',
 'FaIcons.FaKeycdn',
 'FaIcons.FaKickstarterK',
 'FaIcons.FaKickstarter',
 'FaIcons.FaKorvue',
 'FaIcons.FaLaravel',
 'FaIcons.FaLastfmSquare',
 'FaIcons.FaLastfm',
 'FaIcons.FaLeanpub',
 'FaIcons.FaLess',
 'FaIcons.FaLine',
 'FaIcons.FaLinkedinIn',
 'FaIcons.FaLinkedin',
 'FaIcons.FaLinode',
 'FaIcons.FaLinux',
 'FaIcons.FaLyft',
 'FaIcons.FaMagento',
 'FaIcons.FaMailchimp',
 'FaIcons.FaMandalorian',
 'FaIcons.FaMarkdown',
 'FaIcons.FaMastodon',
 'FaIcons.FaMaxcdn',
 'FaIcons.FaMdb',
 'FaIcons.FaMedapps',
 'FaIcons.FaMediumM',
 'FaIcons.FaMedium',
 'FaIcons.FaMedrt',
 'FaIcons.FaMeetup',
 'FaIcons.FaMegaport',
 'FaIcons.FaMendeley',
 'FaIcons.FaMicroblog',
 'FaIcons.FaMicrosoft',
 'FaIcons.FaMix',
 'FaIcons.FaMixcloud',
 'FaIcons.FaMixer',
 'FaIcons.FaMizuni',
 'FaIcons.FaModx',
 'FaIcons.FaMonero',
 'FaIcons.FaNapster',
 'FaIcons.FaNeos',
 'FaIcons.FaNimblr',
 'FaIcons.FaNodeJs',
 'FaIcons.FaNode',
 'FaIcons.FaNpm',
 'FaIcons.FaNs8',
 'FaIcons.FaNutritionix',
 'FaIcons.FaOctopusDeploy',
 'FaIcons.FaOdnoklassnikiSquare',
 'FaIcons.FaOdnoklassniki',
 'FaIcons.FaOldRepublic',
 'FaIcons.FaOpencart',
 'FaIcons.FaOpenid',
 'FaIcons.FaOpera',
 'FaIcons.FaOptinMonster',
 'FaIcons.FaOrcid',
 'FaIcons.FaOsi',
 'FaIcons.FaPage4',
 'FaIcons.FaPagelines',
 'FaIcons.FaPalfed',
 'FaIcons.FaPatreon',
 'FaIcons.FaPaypal',
 'FaIcons.FaPennyArcade',
 'FaIcons.FaPerbyte',
 'FaIcons.FaPeriscope',
 'FaIcons.FaPhabricator',
 'FaIcons.FaPhoenixFramework',
 'FaIcons.FaPhoenixSquadron',
 'FaIcons.FaPhp',
 'FaIcons.FaPiedPiperAlt',
 'FaIcons.FaPiedPiperHat',
 'FaIcons.FaPiedPiperPp',
 'FaIcons.FaPiedPiperSquare',
 'FaIcons.FaPiedPiper',
 'FaIcons.FaPinterestP',
 'FaIcons.FaPinterestSquare',
 'FaIcons.FaPinterest',
 'FaIcons.FaPlaystation',
 'FaIcons.FaProductHunt',
 'FaIcons.FaPushed',
 'FaIcons.FaPython',
 'FaIcons.FaQq',
 'FaIcons.FaQuinscape',
 'FaIcons.FaQuora',
 'FaIcons.FaRProject',
 'FaIcons.FaRaspberryPi',
 'FaIcons.FaRavelry',
 'FaIcons.FaReact',
 'FaIcons.FaReacteurope',
 'FaIcons.FaReadme',
 'FaIcons.FaRebel',
 'FaIcons.FaRedRiver',
 'FaIcons.FaRedditAlien',
 'FaIcons.FaRedditSquare',
 'FaIcons.FaReddit',
 'FaIcons.FaRedhat',
 'FaIcons.FaRenren',
 'FaIcons.FaReplyd',
 'FaIcons.FaResearchgate',
 'FaIcons.FaResolving',
 'FaIcons.FaRev',
 'FaIcons.FaRocketchat',
 'FaIcons.FaRockrms',
 'FaIcons.FaRust',
 'FaIcons.FaSafari',
 'FaIcons.FaSalesforce',
 'FaIcons.FaSass',
 'FaIcons.FaSchlix',
 'FaIcons.FaScribd',
 'FaIcons.FaSearchengin',
 'FaIcons.FaSellcast',
 'FaIcons.FaSellsy',
 'FaIcons.FaServicestack',
 'FaIcons.FaShirtsinbulk',
 'FaIcons.FaShopify',
 'FaIcons.FaShopware',
 'FaIcons.FaSimplybuilt',
 'FaIcons.FaSistrix',
 'FaIcons.FaSith',
 'FaIcons.FaSketch',
 'FaIcons.FaSkyatlas',
 'FaIcons.FaSkype',
 'FaIcons.FaSlackHash',
 'FaIcons.FaSlack',
 'FaIcons.FaSlideshare',
 'FaIcons.FaSnapchatGhost',
 'FaIcons.FaSnapchatSquare',
 'FaIcons.FaSnapchat',
 'FaIcons.FaSoundcloud',
 'FaIcons.FaSourcetree',
 'FaIcons.FaSpeakap',
 'FaIcons.FaSpeakerDeck',
 'FaIcons.FaSpotify',
 'FaIcons.FaSquarespace',
 'FaIcons.FaStackExchange',
 'FaIcons.FaStackOverflow',
 'FaIcons.FaStackpath',
 'FaIcons.FaStaylinked',
 'FaIcons.FaSteamSquare',
 'FaIcons.FaSteamSymbol',
 'FaIcons.FaSteam',
 'FaIcons.FaStickerMule',
 'FaIcons.FaStrava',
 'FaIcons.FaStripeS',
 'FaIcons.FaStripe',
 'FaIcons.FaStudiovinari',
 'FaIcons.FaStumbleuponCircle',
 'FaIcons.FaStumbleupon',
 'FaIcons.FaSuperpowers',
 'FaIcons.FaSupple',
 'FaIcons.FaSuse',
 'FaIcons.FaSwift',
 'FaIcons.FaSymfony',
 'FaIcons.FaTeamspeak',
 'FaIcons.FaTelegramPlane',
 'FaIcons.FaTelegram',
 'FaIcons.FaTencentWeibo',
 'FaIcons.FaTheRedYeti',
 'FaIcons.FaThemeco',
 'FaIcons.FaThemeisle',
 'FaIcons.FaThinkPeaks',
 'FaIcons.FaTiktok',
 'FaIcons.FaTradeFederation',
 'FaIcons.FaTrello',
 'FaIcons.FaTripadvisor',
 'FaIcons.FaTumblrSquare',
 'FaIcons.FaTumblr',
 'FaIcons.FaTwitch',
 'FaIcons.FaTwitterSquare',
 'FaIcons.FaTwitter',
 'FaIcons.FaTypo3',
 'FaIcons.FaUber',
 'FaIcons.FaUbuntu',
 'FaIcons.FaUikit',
 'FaIcons.FaUmbraco',
 'FaIcons.FaUncharted',
 'FaIcons.FaUniregistry',
 'FaIcons.FaUnity',
 'FaIcons.FaUnsplash',
 'FaIcons.FaUntappd',
 'FaIcons.FaUps',
 'FaIcons.FaUsb',
 'FaIcons.FaUsps',
 'FaIcons.FaUssunnah',
 'FaIcons.FaVaadin',
 'FaIcons.FaViacoin',
 'FaIcons.FaViadeoSquare',
 'FaIcons.FaViadeo',
 'FaIcons.FaViber',
 'FaIcons.FaVimeoSquare',
 'FaIcons.FaVimeoV',
 'FaIcons.FaVimeo',
 'FaIcons.FaVine',
 'FaIcons.FaVk',
 'FaIcons.FaVnv',
 'FaIcons.FaVuejs',
 'FaIcons.FaWatchmanMonitoring',
 'FaIcons.FaWaze',
 'FaIcons.FaWeebly',
 'FaIcons.FaWeibo',
 'FaIcons.FaWeixin',
 'FaIcons.FaWhatsappSquare',
 'FaIcons.FaWhatsapp',
 'FaIcons.FaWhmcs',
 'FaIcons.FaWikipediaW',
 'FaIcons.FaWindows',
 'FaIcons.FaWix',
 'FaIcons.FaWizardsOfTheCoast',
 'FaIcons.FaWodu',
 'FaIcons.FaWolfPackBattalion',
 'FaIcons.FaWordpressSimple',
 'FaIcons.FaWordpress',
 'FaIcons.FaWpbeginner',
 'FaIcons.FaWpexplorer',
 'FaIcons.FaWpforms',
 'FaIcons.FaWpressr',
 'FaIcons.FaXbox',
 'FaIcons.FaXingSquare',
 'FaIcons.FaXing',
 'FaIcons.FaYCombinator',
 'FaIcons.FaYahoo',
 'FaIcons.FaYammer',
 'FaIcons.FaYandexInternational',
 'FaIcons.FaYandex',
 'FaIcons.FaYarn',
 'FaIcons.FaYelp',
 'FaIcons.FaYoast',
 'FaIcons.FaYoutubeSquare',
 'FaIcons.FaYoutube',
 'FaIcons.FaZhihu',
 'FaIcons.FaAd',
 'FaIcons.FaAddressBook',
 'FaIcons.FaAddressCard',
 'FaIcons.FaAdjust',
 'FaIcons.FaAirFreshener',
 'FaIcons.FaAlignCenter',
 'FaIcons.FaAlignJustify',
 'FaIcons.FaAlignLeft',
 'FaIcons.FaAlignRight',
 'FaIcons.FaAllergies',
 'FaIcons.FaAmbulance',
 'FaIcons.FaAmericanSignLanguageInterpreting',
 'FaIcons.FaAnchor',
 'FaIcons.FaAngleDoubleDown',
 'FaIcons.FaAngleDoubleLeft',
 'FaIcons.FaAngleDoubleRight',
 'FaIcons.FaAngleDoubleUp',
 'FaIcons.FaAngleDown',
 'FaIcons.FaAngleLeft',
 'FaIcons.FaAngleRight',
 'FaIcons.FaAngleUp',
 'FaIcons.FaAngry',
 'FaIcons.FaAnkh',
 'FaIcons.FaAppleAlt',
 'FaIcons.FaArchive',
 'FaIcons.FaArchway',
 'FaIcons.FaArrowAltCircleDown',
 'FaIcons.FaArrowAltCircleLeft',
 'FaIcons.FaArrowAltCircleRight',
 'FaIcons.FaArrowAltCircleUp',
 'FaIcons.FaArrowCircleDown',
 'FaIcons.FaArrowCircleLeft',
 'FaIcons.FaArrowCircleRight',
 'FaIcons.FaArrowCircleUp',
 'FaIcons.FaArrowDown',
 'FaIcons.FaArrowLeft',
 'FaIcons.FaArrowRight',
 'FaIcons.FaArrowUp',
 'FaIcons.FaArrowsAltH',
 'FaIcons.FaArrowsAltV',
 'FaIcons.FaArrowsAlt',
 'FaIcons.FaAssistiveListeningSystems',
 'FaIcons.FaAsterisk',
 'FaIcons.FaAt',
 'FaIcons.FaAtlas',
 'FaIcons.FaAtom',
 'FaIcons.FaAudioDescription',
 'FaIcons.FaAward',
 'FaIcons.FaBabyCarriage',
 'FaIcons.FaBaby',
 'FaIcons.FaBackspace',
 'FaIcons.FaBackward',
 'FaIcons.FaBacon',
 'FaIcons.FaBacteria',
 'FaIcons.FaBacterium',
 'FaIcons.FaBahai',
 'FaIcons.FaBalanceScaleLeft',
 'FaIcons.FaBalanceScaleRight',
 'FaIcons.FaBalanceScale',
 'FaIcons.FaBan',
 'FaIcons.FaBandAid',
 'FaIcons.FaBarcode',
 'FaIcons.FaBars',
 'FaIcons.FaBaseballBall',
 'FaIcons.FaBasketballBall',
 'FaIcons.FaBath',
 'FaIcons.FaBatteryEmpty',
 'FaIcons.FaBatteryFull',
 'FaIcons.FaBatteryHalf',
 'FaIcons.FaBatteryQuarter',
 'FaIcons.FaBatteryThreeQuarters',
 'FaIcons.FaBed',
 'FaIcons.FaBeer',
 'FaIcons.FaBellSlash',
 'FaIcons.FaBell',
 'FaIcons.FaBezierCurve',
 'FaIcons.FaBible',
 'FaIcons.FaBicycle',
 'FaIcons.FaBiking',
 'FaIcons.FaBinoculars',
 'FaIcons.FaBiohazard',
 'FaIcons.FaBirthdayCake',
 'FaIcons.FaBlenderPhone',
 'FaIcons.FaBlender',
 'FaIcons.FaBlind',
 'FaIcons.FaBlog',
 'FaIcons.FaBold',
 'FaIcons.FaBolt',
 'FaIcons.FaBomb',
 'FaIcons.FaBone',
 'FaIcons.FaBong',
 'FaIcons.FaBookDead',
 'FaIcons.FaBookMedical',
 'FaIcons.FaBookOpen',
 'FaIcons.FaBookReader',
 'FaIcons.FaBook',
 'FaIcons.FaBookmark',
 'FaIcons.FaBorderAll',
 'FaIcons.FaBorderNone',
 'FaIcons.FaBorderStyle',
 'FaIcons.FaBowlingBall',
 'FaIcons.FaBoxOpen',
 'FaIcons.FaBoxTissue',
 'FaIcons.FaBox',
 'FaIcons.FaBoxes',
 'FaIcons.FaBraille',
 'FaIcons.FaBrain',
 'FaIcons.FaBreadSlice',
 'FaIcons.FaBriefcaseMedical',
 'FaIcons.FaBriefcase',
 'FaIcons.FaBroadcastTower',
 'FaIcons.FaBroom',
 'FaIcons.FaBrush',
 'FaIcons.FaBug',
 'FaIcons.FaBuilding',
 'FaIcons.FaBullhorn',
 'FaIcons.FaBullseye',
 'FaIcons.FaBurn',
 'FaIcons.FaBusAlt',
 'FaIcons.FaBus',
 'FaIcons.FaBusinessTime',
 'FaIcons.FaCalculator',
 'FaIcons.FaCalendarAlt',
 'FaIcons.FaCalendarCheck',
 'FaIcons.FaCalendarDay',
 'FaIcons.FaCalendarMinus',
 'FaIcons.FaCalendarPlus',
 'FaIcons.FaCalendarTimes',
 'FaIcons.FaCalendarWeek',
 'FaIcons.FaCalendar',
 'FaIcons.FaCameraRetro',
 'FaIcons.FaCamera',
 'FaIcons.FaCampground',
 'FaIcons.FaCandyCane',
 'FaIcons.FaCannabis',
 'FaIcons.FaCapsules',
 'FaIcons.FaCarAlt',
 'FaIcons.FaCarBattery',
 'FaIcons.FaCarCrash',
 'FaIcons.FaCarSide',
 'FaIcons.FaCar',
 'FaIcons.FaCaravan',
 'FaIcons.FaCaretDown',
 'FaIcons.FaCaretLeft',
 'FaIcons.FaCaretRight',
 'FaIcons.FaCaretSquareDown',
 'FaIcons.FaCaretSquareLeft',
 'FaIcons.FaCaretSquareRight',
 'FaIcons.FaCaretSquareUp',
 'FaIcons.FaCaretUp',
 'FaIcons.FaCarrot',
 'FaIcons.FaCartArrowDown',
 'FaIcons.FaCartPlus',
 'FaIcons.FaCashRegister',
 'FaIcons.FaCat',
 'FaIcons.FaCertificate',
 'FaIcons.FaChair',
 'FaIcons.FaChalkboardTeacher',
 'FaIcons.FaChalkboard',
 'FaIcons.FaChargingStation',
 'FaIcons.FaChartArea',
 'FaIcons.FaChartBar',
 'FaIcons.FaChartLine',
 'FaIcons.FaChartPie',
 'FaIcons.FaCheckCircle',
 'FaIcons.FaCheckDouble',
 'FaIcons.FaCheckSquare',
 'FaIcons.FaCheck',
 'FaIcons.FaCheese',
 'FaIcons.FaChessBishop',
 'FaIcons.FaChessBoard',
 'FaIcons.FaChessKing',
 'FaIcons.FaChessKnight',
 'FaIcons.FaChessPawn',
 'FaIcons.FaChessQueen',
 'FaIcons.FaChessRook',
 'FaIcons.FaChess',
 'FaIcons.FaChevronCircleDown',
 'FaIcons.FaChevronCircleLeft',
 'FaIcons.FaChevronCircleRight',
 'FaIcons.FaChevronCircleUp',
 'FaIcons.FaChevronDown',
 'FaIcons.FaChevronLeft',
 'FaIcons.FaChevronRight',
 'FaIcons.FaChevronUp',
 'FaIcons.FaChild',
 'FaIcons.FaChurch',
 'FaIcons.FaCircleNotch',
 'FaIcons.FaCircle',
 'FaIcons.FaCity',
 'FaIcons.FaClinicMedical',
 'FaIcons.FaClipboardCheck',
 'FaIcons.FaClipboardList',
 'FaIcons.FaClipboard',
 'FaIcons.FaClock',
 'FaIcons.FaClone',
 'FaIcons.FaClosedCaptioning',
 'FaIcons.FaCloudDownloadAlt',
 'FaIcons.FaCloudMeatball',
 'FaIcons.FaCloudMoonRain',
 'FaIcons.FaCloudMoon',
 'FaIcons.FaCloudRain',
 'FaIcons.FaCloudShowersHeavy',
 'FaIcons.FaCloudSunRain',
 'FaIcons.FaCloudSun',
 'FaIcons.FaCloudUploadAlt',
 'FaIcons.FaCloud',
 'FaIcons.FaCocktail',
 'FaIcons.FaCodeBranch',
 'FaIcons.FaCode',
 'FaIcons.FaCoffee',
 'FaIcons.FaCog',
 'FaIcons.FaCogs',
 'FaIcons.FaCoins',
 'FaIcons.FaColumns',
 'FaIcons.FaCommentAlt',
 'FaIcons.FaCommentDollar',
 'FaIcons.FaCommentDots',
 'FaIcons.FaCommentMedical',
 'FaIcons.FaCommentSlash',
 'FaIcons.FaComment',
 'FaIcons.FaCommentsDollar',
 'FaIcons.FaComments',
 'FaIcons.FaCompactDisc',
 'FaIcons.FaCompass',
 'FaIcons.FaCompressAlt',
 'FaIcons.FaCompressArrowsAlt',
 'FaIcons.FaCompress',
 'FaIcons.FaConciergeBell',
 'FaIcons.FaCookieBite',
 'FaIcons.FaCookie',
 'FaIcons.FaCopy',
 'FaIcons.FaCopyright',
 'FaIcons.FaCouch',
 'FaIcons.FaCreditCard',
 'FaIcons.FaCropAlt',
 'FaIcons.FaCrop',
 'FaIcons.FaCross',
 'FaIcons.FaCrosshairs',
 'FaIcons.FaCrow',
 'FaIcons.FaCrown',
 'FaIcons.FaCrutch',
 'FaIcons.FaCube',
 'FaIcons.FaCubes',
 'FaIcons.FaCut',
 'FaIcons.FaDatabase',
 'FaIcons.FaDeaf',
 'FaIcons.FaDemocrat',
 'FaIcons.FaDesktop',
 'FaIcons.FaDharmachakra',
 'FaIcons.FaDiagnoses',
 'FaIcons.FaDiceD20',
 'FaIcons.FaDiceD6',
 'FaIcons.FaDiceFive',
 'FaIcons.FaDiceFour',
 'FaIcons.FaDiceOne',
 'FaIcons.FaDiceSix',
 'FaIcons.FaDiceThree',
 'FaIcons.FaDiceTwo',
 'FaIcons.FaDice',
 'FaIcons.FaDigitalTachograph',
 'FaIcons.FaDirections',
 'FaIcons.FaDisease',
 'FaIcons.FaDivide',
 'FaIcons.FaDizzy',
 'FaIcons.FaDna',
 'FaIcons.FaDog',
 'FaIcons.FaDollarSign',
 'FaIcons.FaDollyFlatbed',
 'FaIcons.FaDolly',
 'FaIcons.FaDonate',
 'FaIcons.FaDoorClosed',
 'FaIcons.FaDoorOpen',
 'FaIcons.FaDotCircle',
 'FaIcons.FaDove',
 'FaIcons.FaDownload',
 'FaIcons.FaDraftingCompass',
 'FaIcons.FaDragon',
 'FaIcons.FaDrawPolygon',
 'FaIcons.FaDrumSteelpan',
 'FaIcons.FaDrum',
 'FaIcons.FaDrumstickBite',
 'FaIcons.FaDumbbell',
 'FaIcons.FaDumpsterFire',
 'FaIcons.FaDumpster',
 'FaIcons.FaDungeon',
 'FaIcons.FaEdit',
 'FaIcons.FaEgg',
 'FaIcons.FaEject',
 'FaIcons.FaEllipsisH',
 'FaIcons.FaEllipsisV',
 'FaIcons.FaEnvelopeOpenText',
 'FaIcons.FaEnvelopeOpen',
 'FaIcons.FaEnvelopeSquare',
 'FaIcons.FaEnvelope',
 'FaIcons.FaEquals',
 'FaIcons.FaEraser',
 'FaIcons.FaEthernet',
 'FaIcons.FaEuroSign',
 'FaIcons.FaExchangeAlt',
 'FaIcons.FaExclamationCircle',
 'FaIcons.FaExclamationTriangle',
 'FaIcons.FaExclamation',
 'FaIcons.FaExpandAlt',
 'FaIcons.FaExpandArrowsAlt',
 'FaIcons.FaExpand',
 'FaIcons.FaExternalLinkAlt',
 'FaIcons.FaExternalLinkSquareAlt',
 'FaIcons.FaEyeDropper',
 'FaIcons.FaEyeSlash',
 'FaIcons.FaEye',
 'FaIcons.FaFan',
 'FaIcons.FaFastBackward',
 'FaIcons.FaFastForward',
 'FaIcons.FaFaucet',
 'FaIcons.FaFax',
 'FaIcons.FaFeatherAlt',
 'FaIcons.FaFeather',
 'FaIcons.FaFemale',
 'FaIcons.FaFighterJet',
 'FaIcons.FaFileAlt',
 'FaIcons.FaFileArchive',
 'FaIcons.FaFileAudio',
 'FaIcons.FaFileCode',
 'FaIcons.FaFileContract',
 'FaIcons.FaFileCsv',
 'FaIcons.FaFileDownload',
 'FaIcons.FaFileExcel',
 'FaIcons.FaFileExport',
 'FaIcons.FaFileImage',
 'FaIcons.FaFileImport',
 'FaIcons.FaFileInvoiceDollar',
 'FaIcons.FaFileInvoice',
 'FaIcons.FaFileMedicalAlt',
 'FaIcons.FaFileMedical',
 'FaIcons.FaFilePdf',
 'FaIcons.FaFilePowerpoint',
 'FaIcons.FaFilePrescription',
 'FaIcons.FaFileSignature',
 'FaIcons.FaFileUpload',
 'FaIcons.FaFileVideo',
 'FaIcons.FaFileWord',
 'FaIcons.FaFile',
 'FaIcons.FaFillDrip',
 'FaIcons.FaFill',
 'FaIcons.FaFilm',
 'FaIcons.FaFilter',
 'FaIcons.FaFingerprint',
 'FaIcons.FaFireAlt',
 'FaIcons.FaFireExtinguisher',
 'FaIcons.FaFire',
 'FaIcons.FaFirstAid',
 'FaIcons.FaFish',
 'FaIcons.FaFistRaised',
 'FaIcons.FaFlagCheckered',
 'FaIcons.FaFlagUsa',
 'FaIcons.FaFlag',
 'FaIcons.FaFlask',
 'FaIcons.FaFlushed',
 'FaIcons.FaFolderMinus',
 'FaIcons.FaFolderOpen',
 'FaIcons.FaFolderPlus',
 'FaIcons.FaFolder',
 'FaIcons.FaFont',
 'FaIcons.FaFootballBall',
 'FaIcons.FaForward',
 'FaIcons.FaFrog',
 'FaIcons.FaFrownOpen',
 'FaIcons.FaFrown',
 'FaIcons.FaFunnelDollar',
 'FaIcons.FaFutbol',
 'FaIcons.FaGamepad',
 'FaIcons.FaGasPump',
 'FaIcons.FaGavel',
 'FaIcons.FaGem',
 'FaIcons.FaGenderless',
 'FaIcons.FaGhost',
 'FaIcons.FaGift',
 'FaIcons.FaGifts',
 'FaIcons.FaGlassCheers',
 'FaIcons.FaGlassMartiniAlt',
 'FaIcons.FaGlassMartini',
 'FaIcons.FaGlassWhiskey',
 'FaIcons.FaGlasses',
 'FaIcons.FaGlobeAfrica',
 'FaIcons.FaGlobeAmericas',
 'FaIcons.FaGlobeAsia',
 'FaIcons.FaGlobeEurope',
 'FaIcons.FaGlobe',
 'FaIcons.FaGolfBall',
 'FaIcons.FaGopuram',
 'FaIcons.FaGraduationCap',
 'FaIcons.FaGreaterThanEqual',
 'FaIcons.FaGreaterThan',
 'FaIcons.FaGrimace',
 'FaIcons.FaGrinAlt',
 'FaIcons.FaGrinBeamSweat',
 'FaIcons.FaGrinBeam',
 'FaIcons.FaGrinHearts',
 'FaIcons.FaGrinSquintTears',
 'FaIcons.FaGrinSquint',
 'FaIcons.FaGrinStars',
 'FaIcons.FaGrinTears',
 'FaIcons.FaGrinTongueSquint',
 'FaIcons.FaGrinTongueWink',
 'FaIcons.FaGrinTongue',
 'FaIcons.FaGrinWink',
 'FaIcons.FaGrin',
 'FaIcons.FaGripHorizontal',
 'FaIcons.FaGripLinesVertical',
 'FaIcons.FaGripLines',
 'FaIcons.FaGripVertical',
 'FaIcons.FaGuitar',
 'FaIcons.FaHSquare',
 'FaIcons.FaHamburger',
 'FaIcons.FaHammer',
 'FaIcons.FaHamsa',
 'FaIcons.FaHandHoldingHeart',
 'FaIcons.FaHandHoldingMedical',
 'FaIcons.FaHandHoldingUsd',
 'FaIcons.FaHandHoldingWater',
 'FaIcons.FaHandHolding',
 'FaIcons.FaHandLizard',
 'FaIcons.FaHandMiddleFinger',
 'FaIcons.FaHandPaper',
 'FaIcons.FaHandPeace',
 'FaIcons.FaHandPointDown',
 'FaIcons.FaHandPointLeft',
 'FaIcons.FaHandPointRight',
 'FaIcons.FaHandPointUp',
 'FaIcons.FaHandPointer',
 'FaIcons.FaHandRock',
 'FaIcons.FaHandScissors',
 'FaIcons.FaHandSparkles',
 'FaIcons.FaHandSpock',
 'FaIcons.FaHandsHelping',
 'FaIcons.FaHandsWash',
 'FaIcons.FaHands',
 'FaIcons.FaHandshakeAltSlash',
 'FaIcons.FaHandshakeSlash',
 'FaIcons.FaHandshake',
 'FaIcons.FaHanukiah',
 'FaIcons.FaHardHat',
 'FaIcons.FaHashtag',
 'FaIcons.FaHatCowboySide',
 'FaIcons.FaHatCowboy',
 'FaIcons.FaHatWizard',
 'FaIcons.FaHdd',
 'FaIcons.FaHeadSideCoughSlash',
 'FaIcons.FaHeadSideCough',
 'FaIcons.FaHeadSideMask',
 'FaIcons.FaHeadSideVirus',
 'FaIcons.FaHeading',
 'FaIcons.FaHeadphonesAlt',
 'FaIcons.FaHeadphones',
 'FaIcons.FaHeadset',
 'FaIcons.FaHeartBroken',
 'FaIcons.FaHeart',
 'FaIcons.FaHeartbeat',
 'FaIcons.FaHelicopter',
 'FaIcons.FaHighlighter',
 'FaIcons.FaHiking',
 'FaIcons.FaHippo',
 'FaIcons.FaHistory',
 'FaIcons.FaHockeyPuck',
 'FaIcons.FaHollyBerry',
 'FaIcons.FaHome',
 'FaIcons.FaHorseHead',
 'FaIcons.FaHorse',
 'FaIcons.FaHospitalAlt',
 'FaIcons.FaHospitalSymbol',
 'FaIcons.FaHospitalUser',
 'FaIcons.FaHospital',
 'FaIcons.FaHotTub',
 'FaIcons.FaHotdog',
 'FaIcons.FaHotel',
 'FaIcons.FaHourglassEnd',
 'FaIcons.FaHourglassHalf',
 'FaIcons.FaHourglassStart',
 'FaIcons.FaHourglass',
 'FaIcons.FaHouseDamage',
 'FaIcons.FaHouseUser',
 'FaIcons.FaHryvnia',
 'FaIcons.FaICursor',
 'FaIcons.FaIceCream',
 'FaIcons.FaIcicles',
 'FaIcons.FaIcons',
 'FaIcons.FaIdBadge',
 'FaIcons.FaIdCardAlt',
 'FaIcons.FaIdCard',
 'FaIcons.FaIgloo',
 'FaIcons.FaImage',
 'FaIcons.FaImages',
 'FaIcons.FaInbox',
 'FaIcons.FaIndent',
 'FaIcons.FaIndustry',
 'FaIcons.FaInfinity',
 'FaIcons.FaInfoCircle',
 'FaIcons.FaInfo',
 'FaIcons.FaItalic',
 'FaIcons.FaJedi',
 'FaIcons.FaJoint',
 'FaIcons.FaJournalWhills',
 'FaIcons.FaKaaba',
 'FaIcons.FaKey',
 'FaIcons.FaKeyboard',
 'FaIcons.FaKhanda',
 'FaIcons.FaKissBeam',
 'FaIcons.FaKissWinkHeart',
 'FaIcons.FaKiss',
 'FaIcons.FaKiwiBird',
 'FaIcons.FaLandmark',
 'FaIcons.FaLanguage',
 'FaIcons.FaLaptopCode',
 'FaIcons.FaLaptopHouse',
 'FaIcons.FaLaptopMedical',
 'FaIcons.FaLaptop',
 'FaIcons.FaLaughBeam',
 'FaIcons.FaLaughSquint',
 'FaIcons.FaLaughWink',
 'FaIcons.FaLaugh',
 'FaIcons.FaLayerGroup',
 'FaIcons.FaLeaf',
 'FaIcons.FaLemon',
 'FaIcons.FaLessThanEqual',
 'FaIcons.FaLessThan',
 'FaIcons.FaLevelDownAlt',
 'FaIcons.FaLevelUpAlt',
 'FaIcons.FaLifeRing',
 'FaIcons.FaLightbulb',
 'FaIcons.FaLink',
 'FaIcons.FaLiraSign',
 'FaIcons.FaListAlt',
 'FaIcons.FaListOl',
 'FaIcons.FaListUl',
 'FaIcons.FaList',
 'FaIcons.FaLocationArrow',
 'FaIcons.FaLockOpen',
 'FaIcons.FaLock',
 'FaIcons.FaLongArrowAltDown',
 'FaIcons.FaLongArrowAltLeft',
 'FaIcons.FaLongArrowAltRight',
 'FaIcons.FaLongArrowAltUp',
 'FaIcons.FaLowVision',
 'FaIcons.FaLuggageCart',
 'FaIcons.FaLungsVirus',
 'FaIcons.FaLungs',
 'FaIcons.FaMagic',
 'FaIcons.FaMagnet',
 'FaIcons.FaMailBulk',
 'FaIcons.FaMale',
 'FaIcons.FaMapMarkedAlt',
 'FaIcons.FaMapMarked',
 'FaIcons.FaMapMarkerAlt',
 'FaIcons.FaMapMarker',
 'FaIcons.FaMapPin',
 'FaIcons.FaMapSigns',
 'FaIcons.FaMap',
 'FaIcons.FaMarker',
 'FaIcons.FaMarsDouble',
 'FaIcons.FaMarsStrokeH',
 'FaIcons.FaMarsStrokeV',
 'FaIcons.FaMarsStroke',
 'FaIcons.FaMars',
 'FaIcons.FaMask',
 'FaIcons.FaMedal',
 'FaIcons.FaMedkit',
 'FaIcons.FaMehBlank',
 'FaIcons.FaMehRollingEyes',
 'FaIcons.FaMeh',
 'FaIcons.FaMemory',
 'FaIcons.FaMenorah',
 'FaIcons.FaMercury',
 'FaIcons.FaMeteor',
 'FaIcons.FaMicrochip',
 'FaIcons.FaMicrophoneAltSlash',
 'FaIcons.FaMicrophoneAlt',
 'FaIcons.FaMicrophoneSlash',
 'FaIcons.FaMicrophone',
 'FaIcons.FaMicroscope',
 'FaIcons.FaMinusCircle',
 'FaIcons.FaMinusSquare',
 'FaIcons.FaMinus',
 'FaIcons.FaMitten',
 'FaIcons.FaMobileAlt',
 'FaIcons.FaMobile',
 'FaIcons.FaMoneyBillAlt',
 'FaIcons.FaMoneyBillWaveAlt',
 'FaIcons.FaMoneyBillWave',
 'FaIcons.FaMoneyBill',
 'FaIcons.FaMoneyCheckAlt',
 'FaIcons.FaMoneyCheck',
 'FaIcons.FaMonument',
 'FaIcons.FaMoon',
 'FaIcons.FaMortarPestle',
 'FaIcons.FaMosque',
 'FaIcons.FaMotorcycle',
 'FaIcons.FaMountain',
 'FaIcons.FaMousePointer',
 'FaIcons.FaMouse',
 'FaIcons.FaMugHot',
 'FaIcons.FaMusic',
 'FaIcons.FaNetworkWired',
 'FaIcons.FaNeuter',
 'FaIcons.FaNewspaper',
 'FaIcons.FaNotEqual',
 'FaIcons.FaNotesMedical',
 'FaIcons.FaObjectGroup',
 'FaIcons.FaObjectUngroup',
 'FaIcons.FaOilCan',
 'FaIcons.FaOm',
 'FaIcons.FaOtter',
 'FaIcons.FaOutdent',
 'FaIcons.FaPager',
 'FaIcons.FaPaintBrush',
 'FaIcons.FaPaintRoller',
 'FaIcons.FaPalette',
 'FaIcons.FaPallet',
 'FaIcons.FaPaperPlane',
 'FaIcons.FaPaperclip',
 'FaIcons.FaParachuteBox',
 'FaIcons.FaParagraph',
 'FaIcons.FaParking',
 'FaIcons.FaPassport',
 'FaIcons.FaPastafarianism',
 'FaIcons.FaPaste',
 'FaIcons.FaPauseCircle',
 'FaIcons.FaPause',
 'FaIcons.FaPaw',
 'FaIcons.FaPeace',
 'FaIcons.FaPenAlt',
 'FaIcons.FaPenFancy',
 'FaIcons.FaPenNib',
 'FaIcons.FaPenSquare',
 'FaIcons.FaPen',
 'FaIcons.FaPencilAlt',
 'FaIcons.FaPencilRuler',
 'FaIcons.FaPeopleArrows',
 'FaIcons.FaPeopleCarry',
 'FaIcons.FaPepperHot',
 'FaIcons.FaPercent',
 'FaIcons.FaPercentage',
 'FaIcons.FaPersonBooth',
 'FaIcons.FaPhoneAlt',
 'FaIcons.FaPhoneSlash',
 'FaIcons.FaPhoneSquareAlt',
 'FaIcons.FaPhoneSquare',
 'FaIcons.FaPhoneVolume',
 'FaIcons.FaPhone',
 'FaIcons.FaPhotoVideo',
 'FaIcons.FaPiggyBank',
 'FaIcons.FaPills',
 'FaIcons.FaPizzaSlice',
 'FaIcons.FaPlaceOfWorship',
 'FaIcons.FaPlaneArrival',
 'FaIcons.FaPlaneDeparture',
 'FaIcons.FaPlaneSlash',
 'FaIcons.FaPlane',
 'FaIcons.FaPlayCircle',
 'FaIcons.FaPlay',
 'FaIcons.FaPlug',
 'FaIcons.FaPlusCircle',
 'FaIcons.FaPlusSquare',
 'FaIcons.FaPlus',
 'FaIcons.FaPodcast',
 'FaIcons.FaPollH',
 'FaIcons.FaPoll',
 'FaIcons.FaPooStorm',
 'FaIcons.FaPoo',
 'FaIcons.FaPoop',
 'FaIcons.FaPortrait',
 'FaIcons.FaPoundSign',
 'FaIcons.FaPowerOff',
 'FaIcons.FaPray',
 'FaIcons.FaPrayingHands',
 'FaIcons.FaPrescriptionBottleAlt',
 'FaIcons.FaPrescriptionBottle',
 'FaIcons.FaPrescription',
 'FaIcons.FaPrint',
 'FaIcons.FaProcedures',
 'FaIcons.FaProjectDiagram',
 'FaIcons.FaPumpMedical',
 'FaIcons.FaPumpSoap',
 'FaIcons.FaPuzzlePiece',
 'FaIcons.FaQrcode',
 'FaIcons.FaQuestionCircle',
 'FaIcons.FaQuestion',
 'FaIcons.FaQuidditch',
 'FaIcons.FaQuoteLeft',
 'FaIcons.FaQuoteRight',
 'FaIcons.FaQuran',
 'FaIcons.FaRadiationAlt',
 'FaIcons.FaRadiation',
 'FaIcons.FaRainbow',
 'FaIcons.FaRandom',
 'FaIcons.FaReceipt',
 'FaIcons.FaRecordVinyl',
 'FaIcons.FaRecycle',
 'FaIcons.FaRedoAlt',
 'FaIcons.FaRedo',
 'FaIcons.FaRegistered',
 'FaIcons.FaRemoveFormat',
 'FaIcons.FaReplyAll',
 'FaIcons.FaReply',
 'FaIcons.FaRepublican',
 'FaIcons.FaRestroom',
 'FaIcons.FaRetweet',
 'FaIcons.FaRibbon',
 'FaIcons.FaRing',
 'FaIcons.FaRoad',
 'FaIcons.FaRobot',
 'FaIcons.FaRocket',
 'FaIcons.FaRoute',
 'FaIcons.FaRssSquare',
 'FaIcons.FaRss',
 'FaIcons.FaRubleSign',
 'FaIcons.FaRulerCombined',
 'FaIcons.FaRulerHorizontal',
 'FaIcons.FaRulerVertical',
 'FaIcons.FaRuler',
 'FaIcons.FaRunning',
 'FaIcons.FaRupeeSign',
 'FaIcons.FaSadCry',
 'FaIcons.FaSadTear',
 'FaIcons.FaSatelliteDish',
 'FaIcons.FaSatellite',
 'FaIcons.FaSave',
 'FaIcons.FaSchool',
 'FaIcons.FaScrewdriver',
 'FaIcons.FaScroll',
 'FaIcons.FaSdCard',
 'FaIcons.FaSearchDollar',
 'FaIcons.FaSearchLocation',
 'FaIcons.FaSearchMinus',
 'FaIcons.FaSearchPlus',
 'FaIcons.FaSearch',
 'FaIcons.FaSeedling',
 'FaIcons.FaServer',
 'FaIcons.FaShapes',
 'FaIcons.FaShareAltSquare',
 'FaIcons.FaShareAlt',
 'FaIcons.FaShareSquare',
 'FaIcons.FaShare',
 'FaIcons.FaShekelSign',
 'FaIcons.FaShieldAlt',
 'FaIcons.FaShieldVirus',
 'FaIcons.FaShip',
 'FaIcons.FaShippingFast',
 'FaIcons.FaShoePrints',
 'FaIcons.FaShoppingBag',
 'FaIcons.FaShoppingBasket',
 'FaIcons.FaShoppingCart',
 'FaIcons.FaShower',
 'FaIcons.FaShuttleVan',
 'FaIcons.FaSignInAlt',
 'FaIcons.FaSignLanguage',
 'FaIcons.FaSignOutAlt',
 'FaIcons.FaSign',
 'FaIcons.FaSignal',
 'FaIcons.FaSignature',
 'FaIcons.FaSimCard',
 'FaIcons.FaSink',
 'FaIcons.FaSitemap',
 'FaIcons.FaSkating',
 'FaIcons.FaSkiingNordic',
 'FaIcons.FaSkiing',
 'FaIcons.FaSkullCrossbones',
 'FaIcons.FaSkull',
 'FaIcons.FaSlash',
 'FaIcons.FaSleigh',
 'FaIcons.FaSlidersH',
 'FaIcons.FaSmileBeam',
 'FaIcons.FaSmileWink',
 'FaIcons.FaSmile',
 'FaIcons.FaSmog',
 'FaIcons.FaSmokingBan',
 'FaIcons.FaSmoking',
 'FaIcons.FaSms',
 'FaIcons.FaSnowboarding',
 'FaIcons.FaSnowflake',
 'FaIcons.FaSnowman',
 'FaIcons.FaSnowplow',
 'FaIcons.FaSoap',
 'FaIcons.FaSocks',
 'FaIcons.FaSolarPanel',
 'FaIcons.FaSortAlphaDownAlt',
 'FaIcons.FaSortAlphaDown',
 'FaIcons.FaSortAlphaUpAlt',
 'FaIcons.FaSortAlphaUp',
 'FaIcons.FaSortAmountDownAlt',
 'FaIcons.FaSortAmountDown',
 'FaIcons.FaSortAmountUpAlt',
 'FaIcons.FaSortAmountUp',
 'FaIcons.FaSortDown',
 'FaIcons.FaSortNumericDownAlt',
 'FaIcons.FaSortNumericDown',
 'FaIcons.FaSortNumericUpAlt',
 'FaIcons.FaSortNumericUp',
 'FaIcons.FaSortUp',
 'FaIcons.FaSort',
 'FaIcons.FaSpa',
 'FaIcons.FaSpaceShuttle',
 'FaIcons.FaSpellCheck',
 'FaIcons.FaSpider',
 'FaIcons.FaSpinner',
 'FaIcons.FaSplotch',
 'FaIcons.FaSprayCan',
 'FaIcons.FaSquareFull',
 'FaIcons.FaSquareRootAlt',
 'FaIcons.FaSquare',
 'FaIcons.FaStamp',
 'FaIcons.FaStarAndCrescent',
 'FaIcons.FaStarHalfAlt',
 'FaIcons.FaStarHalf',
 'FaIcons.FaStarOfDavid',
 'FaIcons.FaStarOfLife',
 'FaIcons.FaStar',
 'FaIcons.FaStepBackward',
 'FaIcons.FaStepForward',
 'FaIcons.FaStethoscope',
 'FaIcons.FaStickyNote',
 'FaIcons.FaStopCircle',
 'FaIcons.FaStop',
 'FaIcons.FaStopwatch20',
 'FaIcons.FaStopwatch',
 'FaIcons.FaStoreAltSlash',
 'FaIcons.FaStoreAlt',
 'FaIcons.FaStoreSlash',
 'FaIcons.FaStore',
 'FaIcons.FaStream',
 'FaIcons.FaStreetView',
 'FaIcons.FaStrikethrough',
 'FaIcons.FaStroopwafel',
 'FaIcons.FaSubscript',
 'FaIcons.FaSubway',
 'FaIcons.FaSuitcaseRolling',
 'FaIcons.FaSuitcase',
 'FaIcons.FaSun',
 'FaIcons.FaSuperscript',
 'FaIcons.FaSurprise',
 'FaIcons.FaSwatchbook',
 'FaIcons.FaSwimmer',
 'FaIcons.FaSwimmingPool',
 'FaIcons.FaSynagogue',
 'FaIcons.FaSyncAlt',
 'FaIcons.FaSync',
 'FaIcons.FaSyringe',
 'FaIcons.FaTableTennis',
 'FaIcons.FaTable',
 'FaIcons.FaTabletAlt',
 'FaIcons.FaTablet',
 'FaIcons.FaTablets',
 'FaIcons.FaTachometerAlt',
 'FaIcons.FaTag',
 'FaIcons.FaTags',
 'FaIcons.FaTape',
 'FaIcons.FaTasks',
 'FaIcons.FaTaxi',
 'FaIcons.FaTeethOpen',
 'FaIcons.FaTeeth',
 'FaIcons.FaTemperatureHigh',
 'FaIcons.FaTemperatureLow',
 'FaIcons.FaTenge',
 'FaIcons.FaTerminal',
 'FaIcons.FaTextHeight',
 'FaIcons.FaTextWidth',
 'FaIcons.FaThLarge',
 'FaIcons.FaThList',
 'FaIcons.FaTh',
 'FaIcons.FaTheaterMasks',
 'FaIcons.FaThermometerEmpty',
 'FaIcons.FaThermometerFull',
 'FaIcons.FaThermometerHalf',
 'FaIcons.FaThermometerQuarter',
 'FaIcons.FaThermometerThreeQuarters',
 'FaIcons.FaThermometer',
 'FaIcons.FaThumbsDown',
 'FaIcons.FaThumbsUp',
 'FaIcons.FaThumbtack',
 'FaIcons.FaTicketAlt',
 'FaIcons.FaTimesCircle',
 'FaIcons.FaTimes',
 'FaIcons.FaTintSlash',
 'FaIcons.FaTint',
 'FaIcons.FaTired',
 'FaIcons.FaToggleOff',
 'FaIcons.FaToggleOn',
 'FaIcons.FaToiletPaperSlash',
 'FaIcons.FaToiletPaper',
 'FaIcons.FaToilet',
 'FaIcons.FaToolbox',
 'FaIcons.FaTools',
 'FaIcons.FaTooth',
 'FaIcons.FaTorah',
 'FaIcons.FaToriiGate',
 'FaIcons.FaTractor',
 'FaIcons.FaTrademark',
 'FaIcons.FaTrafficLight',
 'FaIcons.FaTrailer',
 'FaIcons.FaTrain',
 'FaIcons.FaTram',
 'FaIcons.FaTransgenderAlt',
 'FaIcons.FaTransgender',
 'FaIcons.FaTrashAlt',
 'FaIcons.FaTrashRestoreAlt',
 'FaIcons.FaTrashRestore',
 'FaIcons.FaTrash',
 'FaIcons.FaTree',
 'FaIcons.FaTrophy',
 'FaIcons.FaTruckLoading',
 'FaIcons.FaTruckMonster',
 'FaIcons.FaTruckMoving',
 'FaIcons.FaTruckPickup',
 'FaIcons.FaTruck',
 'FaIcons.FaTshirt',
 'FaIcons.FaTty',
 'FaIcons.FaTv',
 'FaIcons.FaUmbrellaBeach',
 'FaIcons.FaUmbrella',
 'FaIcons.FaUnderline',
 'FaIcons.FaUndoAlt',
 'FaIcons.FaUndo',
 'FaIcons.FaUniversalAccess',
 'FaIcons.FaUniversity',
 'FaIcons.FaUnlink',
 'FaIcons.FaUnlockAlt',
 'FaIcons.FaUnlock',
 'FaIcons.FaUpload',
 'FaIcons.FaUserAltSlash',
 'FaIcons.FaUserAlt',
 'FaIcons.FaUserAstronaut',
 'FaIcons.FaUserCheck',
 'FaIcons.FaUserCircle',
 'FaIcons.FaUserClock',
 'FaIcons.FaUserCog',
 'FaIcons.FaUserEdit',
 'FaIcons.FaUserFriends',
 'FaIcons.FaUserGraduate',
 'FaIcons.FaUserInjured',
 'FaIcons.FaUserLock',
 'FaIcons.FaUserMd',
 'FaIcons.FaUserMinus',
 'FaIcons.FaUserNinja',
 'FaIcons.FaUserNurse',
 'FaIcons.FaUserPlus',
 'FaIcons.FaUserSecret',
 'FaIcons.FaUserShield',
 'FaIcons.FaUserSlash',
 'FaIcons.FaUserTag',
 'FaIcons.FaUserTie',
 'FaIcons.FaUserTimes',
 'FaIcons.FaUser',
 'FaIcons.FaUsersCog',
 'FaIcons.FaUsersSlash',
 'FaIcons.FaUsers',
 'FaIcons.FaUtensilSpoon',
 'FaIcons.FaUtensils',
 'FaIcons.FaVectorSquare',
 'FaIcons.FaVenusDouble',
 'FaIcons.FaVenusMars',
 'FaIcons.FaVenus',
 'FaIcons.FaVestPatches',
 'FaIcons.FaVest',
 'FaIcons.FaVial',
 'FaIcons.FaVials',
 'FaIcons.FaVideoSlash',
 'FaIcons.FaVideo',
 'FaIcons.FaVihara',
 'FaIcons.FaVirusSlash',
 'FaIcons.FaVirus',
 'FaIcons.FaViruses',
 'FaIcons.FaVoicemail',
 'FaIcons.FaVolleyballBall',
 'FaIcons.FaVolumeDown',
 'FaIcons.FaVolumeMute',
 'FaIcons.FaVolumeOff',
 'FaIcons.FaVolumeUp',
 'FaIcons.FaVoteYea',
 'FaIcons.FaVrCardboard',
 'FaIcons.FaWalking',
 'FaIcons.FaWallet',
 'FaIcons.FaWarehouse',
 'FaIcons.FaWater',
 'FaIcons.FaWaveSquare',
 'FaIcons.FaWeightHanging',
 'FaIcons.FaWeight',
 'FaIcons.FaWheelchair',
 'FaIcons.FaWifi',
 'FaIcons.FaWind',
 'FaIcons.FaWindowClose',
 'FaIcons.FaWindowMaximize',
 'FaIcons.FaWindowMinimize',
 'FaIcons.FaWindowRestore',
 'FaIcons.FaWineBottle',
 'FaIcons.FaWineGlassAlt',
 'FaIcons.FaWineGlass',
 'FaIcons.FaWonSign',
 'FaIcons.FaWrench',
 'FaIcons.FaXRay',
 'FaIcons.FaYenSign',
 'FaIcons.FaYinYang',
 'FaIcons.FaRegAddressBook',
 'FaIcons.FaRegAddressCard',
 'FaIcons.FaRegAngry',
 'FaIcons.FaRegArrowAltCircleDown',
 'FaIcons.FaRegArrowAltCircleLeft',
 'FaIcons.FaRegArrowAltCircleRight',
 'FaIcons.FaRegArrowAltCircleUp',
 'FaIcons.FaRegBellSlash',
 'FaIcons.FaRegBell',
 'FaIcons.FaRegBookmark',
 'FaIcons.FaRegBuilding',
 'FaIcons.FaRegCalendarAlt',
 'FaIcons.FaRegCalendarCheck',
 'FaIcons.FaRegCalendarMinus',
 'FaIcons.FaRegCalendarPlus',
 'FaIcons.FaRegCalendarTimes',
 'FaIcons.FaRegCalendar',
 'FaIcons.FaRegCaretSquareDown',
 'FaIcons.FaRegCaretSquareLeft',
 'FaIcons.FaRegCaretSquareRight',
 'FaIcons.FaRegCaretSquareUp',
 'FaIcons.FaRegChartBar',
 'FaIcons.FaRegCheckCircle',
 'FaIcons.FaRegCheckSquare',
 'FaIcons.FaRegCircle',
 'FaIcons.FaRegClipboard',
 'FaIcons.FaRegClock',
 'FaIcons.FaRegClone',
 'FaIcons.FaRegClosedCaptioning',
 'FaIcons.FaRegCommentAlt',
 'FaIcons.FaRegCommentDots',
 'FaIcons.FaRegComment',
 'FaIcons.FaRegComments',
 'FaIcons.FaRegCompass',
 'FaIcons.FaRegCopy',
 'FaIcons.FaRegCopyright',
 'FaIcons.FaRegCreditCard',
 'FaIcons.FaRegDizzy',
 'FaIcons.FaRegDotCircle',
 'FaIcons.FaRegEdit',
 'FaIcons.FaRegEnvelopeOpen',
 'FaIcons.FaRegEnvelope',
 'FaIcons.FaRegEyeSlash',
 'FaIcons.FaRegEye',
 'FaIcons.FaRegFileAlt',
 'FaIcons.FaRegFileArchive',
 'FaIcons.FaRegFileAudio',
 'FaIcons.FaRegFileCode',
 'FaIcons.FaRegFileExcel',
 'FaIcons.FaRegFileImage',
 'FaIcons.FaRegFilePdf',
 'FaIcons.FaRegFilePowerpoint',
 'FaIcons.FaRegFileVideo',
 'FaIcons.FaRegFileWord',
 'FaIcons.FaRegFile',
 'FaIcons.FaRegFlag',
 'FaIcons.FaRegFlushed',
 'FaIcons.FaRegFolderOpen',
 'FaIcons.FaRegFolder',
 'FaIcons.FaRegFontAwesomeLogoFull',
 'FaIcons.FaRegFrownOpen',
 'FaIcons.FaRegFrown',
 'FaIcons.FaRegFutbol',
 'FaIcons.FaRegGem',
 'FaIcons.FaRegGrimace',
 'FaIcons.FaRegGrinAlt',
 'FaIcons.FaRegGrinBeamSweat',
 'FaIcons.FaRegGrinBeam',
 'FaIcons.FaRegGrinHearts',
 'FaIcons.FaRegGrinSquintTears',
 'FaIcons.FaRegGrinSquint',
 'FaIcons.FaRegGrinStars',
 'FaIcons.FaRegGrinTears',
 'FaIcons.FaRegGrinTongueSquint',
 'FaIcons.FaRegGrinTongueWink',
 'FaIcons.FaRegGrinTongue',
 'FaIcons.FaRegGrinWink',
 'FaIcons.FaRegGrin',
 'FaIcons.FaRegHandLizard',
 'FaIcons.FaRegHandPaper',
 'FaIcons.FaRegHandPeace',
 'FaIcons.FaRegHandPointDown',
 'FaIcons.FaRegHandPointLeft',
 'FaIcons.FaRegHandPointRight',
 'FaIcons.FaRegHandPointUp',
 'FaIcons.FaRegHandPointer',
 'FaIcons.FaRegHandRock',
 'FaIcons.FaRegHandScissors',
 'FaIcons.FaRegHandSpock',
 'FaIcons.FaRegHandshake',
 'FaIcons.FaRegHdd',
 'FaIcons.FaRegHeart',
 'FaIcons.FaRegHospital',
 'FaIcons.FaRegHourglass',
 'FaIcons.FaRegIdBadge',
 'FaIcons.FaRegIdCard',
 'FaIcons.FaRegImage',
 'FaIcons.FaRegImages',
 'FaIcons.FaRegKeyboard',
 'FaIcons.FaRegKissBeam',
 'FaIcons.FaRegKissWinkHeart',
 'FaIcons.FaRegKiss',
 'FaIcons.FaRegLaughBeam',
 'FaIcons.FaRegLaughSquint',
 'FaIcons.FaRegLaughWink',
 'FaIcons.FaRegLaugh',
 'FaIcons.FaRegLemon',
 'FaIcons.FaRegLifeRing',
 'FaIcons.FaRegLightbulb',
 'FaIcons.FaRegListAlt',
 'FaIcons.FaRegMap',
 'FaIcons.FaRegMehBlank',
 'FaIcons.FaRegMehRollingEyes',
 'FaIcons.FaRegMeh',
 'FaIcons.FaRegMinusSquare',
 'FaIcons.FaRegMoneyBillAlt',
 'FaIcons.FaRegMoon',
 'FaIcons.FaRegNewspaper',
 'FaIcons.FaRegObjectGroup',
 'FaIcons.FaRegObjectUngroup',
 'FaIcons.FaRegPaperPlane',
 'FaIcons.FaRegPauseCircle',
 'FaIcons.FaRegPlayCircle',
 'FaIcons.FaRegPlusSquare',
 'FaIcons.FaRegQuestionCircle',
 'FaIcons.FaRegRegistered',
 'FaIcons.FaRegSadCry',
 'FaIcons.FaRegSadTear',
 'FaIcons.FaRegSave',
 'FaIcons.FaRegShareSquare',
 'FaIcons.FaRegSmileBeam',
 'FaIcons.FaRegSmileWink',
 'FaIcons.FaRegSmile',
 'FaIcons.FaRegSnowflake',
 'FaIcons.FaRegSquare',
 'FaIcons.FaRegStarHalf',
 'FaIcons.FaRegStar',
 'FaIcons.FaRegStickyNote',
 'FaIcons.FaRegStopCircle',
 'FaIcons.FaRegSun',
 'FaIcons.FaRegSurprise',
 'FaIcons.FaRegThumbsDown',
 'FaIcons.FaRegThumbsUp',
 'FaIcons.FaRegTimesCircle',
 'FaIcons.FaRegTired',
 'FaIcons.FaRegTrashAlt',
 'FaIcons.FaRegUserCircle',
 'FaIcons.FaRegUser',
 'FaIcons.FaRegWindowClose',
 'FaIcons.FaRegWindowMaximize',
 'FaIcons.FaRegWindowMinimize',
 'FaIcons.FaRegWindowRestore',

 
 'FcIcons.FcAbout',
 'FcIcons.FcAcceptDatabase',
 'FcIcons.FcAddColumn',
 'FcIcons.FcAddDatabase',
 'FcIcons.FcAddImage',
 'FcIcons.FcAddRow',
 'FcIcons.FcAddressBook',
 'FcIcons.FcAdvance',
 'FcIcons.FcAdvertising',
 'FcIcons.FcAlarmClock',
 'FcIcons.FcAlphabeticalSortingAz',
 'FcIcons.FcAlphabeticalSortingZa',
 'FcIcons.FcAndroidOs',
 'FcIcons.FcAnswers',
 'FcIcons.FcApproval',
 'FcIcons.FcApprove',
 'FcIcons.FcAreaChart',
 'FcIcons.FcAssistant',
 'FcIcons.FcAudioFile',
 'FcIcons.FcAutomatic',
 'FcIcons.FcAutomotive',
 'FcIcons.FcBadDecision',
 'FcIcons.FcBarChart',
 'FcIcons.FcBbc',
 'FcIcons.FcBearish',
 'FcIcons.FcBinoculars',
 'FcIcons.FcBiohazard',
 'FcIcons.FcBiomass',
 'FcIcons.FcBiotech',
 'FcIcons.FcBookmark',
 'FcIcons.FcBriefcase',
 'FcIcons.FcBrokenLink',
 'FcIcons.FcBullish',
 'FcIcons.FcBusinessContact',
 'FcIcons.FcBusiness',
 'FcIcons.FcBusinessman',
 'FcIcons.FcBusinesswoman',
 'FcIcons.FcButtingIn',
 'FcIcons.FcCableRelease',
 'FcIcons.FcCalculator',
 'FcIcons.FcCalendar',
 'FcIcons.FcCallTransfer',
 'FcIcons.FcCallback',
 'FcIcons.FcCamcorderPro',
 'FcIcons.FcCamcorder',
 'FcIcons.FcCameraAddon',
 'FcIcons.FcCameraIdentification',
 'FcIcons.FcCamera',
 'FcIcons.FcCancel',
 'FcIcons.FcCandleSticks',
 'FcIcons.FcCapacitor',
 'FcIcons.FcCdLogo',
 'FcIcons.FcCellPhone',
 'FcIcons.FcChargeBattery',
 'FcIcons.FcCheckmark',
 'FcIcons.FcCircuit',
 'FcIcons.FcClapperboard',
 'FcIcons.FcClearFilters',
 'FcIcons.FcClock',
 'FcIcons.FcCloseUpMode',
 'FcIcons.FcCloth',
 'FcIcons.FcCollaboration',
 'FcIcons.FcCollapse',
 'FcIcons.FcCollect',
 'FcIcons.FcComboChart',
 'FcIcons.FcCommandLine',
 'FcIcons.FcComments',
 'FcIcons.FcCompactCamera',
 'FcIcons.FcConferenceCall',
 'FcIcons.FcContacts',
 'FcIcons.FcCopyleft',
 'FcIcons.FcCopyright',
 'FcIcons.FcCrystalOscillator',
 'FcIcons.FcCurrencyExchange',
 'FcIcons.FcCursor',
 'FcIcons.FcCustomerSupport',
 'FcIcons.FcDam',
 'FcIcons.FcDataBackup',
 'FcIcons.FcDataConfiguration',
 'FcIcons.FcDataEncryption',
 'FcIcons.FcDataProtection',
 'FcIcons.FcDataRecovery',
 'FcIcons.FcDataSheet',
 'FcIcons.FcDatabase',
 'FcIcons.FcDebian',
 'FcIcons.FcDebt',
 'FcIcons.FcDecision',
 'FcIcons.FcDeleteColumn',
 'FcIcons.FcDeleteDatabase',
 'FcIcons.FcDeleteRow',
 'FcIcons.FcDepartment',
 'FcIcons.FcDeployment',
 'FcIcons.FcDiploma1',
 'FcIcons.FcDiploma2',
 'FcIcons.FcDisapprove',
 'FcIcons.FcDisclaimer',
 'FcIcons.FcDislike',
 'FcIcons.FcDisplay',
 'FcIcons.FcDoNotInhale',
 'FcIcons.FcDoNotInsert',
 'FcIcons.FcDoNotMix',
 'FcIcons.FcDocument',
 'FcIcons.FcDonate',
 'FcIcons.FcDoughnutChart',
 'FcIcons.FcDownLeft',
 'FcIcons.FcDownRight',
 'FcIcons.FcDown',
 'FcIcons.FcDownload',
 'FcIcons.FcDribbble',
 'FcIcons.FcDvdLogo',
 'FcIcons.FcEditImage',
 'FcIcons.FcElectricalSensor',
 'FcIcons.FcElectricalThreshold',
 'FcIcons.FcElectricity',
 'FcIcons.FcElectroDevices',
 'FcIcons.FcElectronics',
 'FcIcons.FcEmptyBattery',
 'FcIcons.FcEmptyFilter',
 'FcIcons.FcEmptyTrash',
 'FcIcons.FcEndCall',
 'FcIcons.FcEngineering',
 'FcIcons.FcEnteringHeavenAlive',
 'FcIcons.FcExpand',
 'FcIcons.FcExpired',
 'FcIcons.FcExport',
 'FcIcons.FcExternal',
 'FcIcons.FcFactoryBreakdown',
 'FcIcons.FcFactory',
 'FcIcons.FcFaq',
 'FcIcons.FcFeedIn',
 'FcIcons.FcFeedback',
 'FcIcons.FcFile',
 'FcIcons.FcFilingCabinet',
 'FcIcons.FcFilledFilter',
 'FcIcons.FcFilmReel',
 'FcIcons.FcFilm',
 'FcIcons.FcFinePrint',
 'FcIcons.FcFlashAuto',
 'FcIcons.FcFlashOff',
 'FcIcons.FcFlashOn',
 'FcIcons.FcFlowChart',
 'FcIcons.FcFolder',
 'FcIcons.FcFrame',
 'FcIcons.FcFullBattery',
 'FcIcons.FcFullTrash',
 'FcIcons.FcGallery',
 'FcIcons.FcGenealogy',
 'FcIcons.FcGenericSortingAsc',
 'FcIcons.FcGenericSortingDesc',
 'FcIcons.FcGlobe',
 'FcIcons.FcGoodDecision',
 'FcIcons.FcGoogle',
 'FcIcons.FcGraduationCap',
 'FcIcons.FcGrid',
 'FcIcons.FcHeadset',
 'FcIcons.FcHeatMap',
 'FcIcons.FcHighBattery',
 'FcIcons.FcHighPriority',
 'FcIcons.FcHome',
 'FcIcons.FcIcons8Cup',
 'FcIcons.FcIdea',
 'FcIcons.FcImageFile',
 'FcIcons.FcImport',
 'FcIcons.FcInTransit',
 'FcIcons.FcInfo',
 'FcIcons.FcInspection',
 'FcIcons.FcIntegratedWebcam',
 'FcIcons.FcInternal',
 'FcIcons.FcInvite',
 'FcIcons.FcIpad',
 'FcIcons.FcIphone',
 'FcIcons.FcKey',
 'FcIcons.FcKindle',
 'FcIcons.FcLandscape',
 'FcIcons.FcLeave',
 'FcIcons.FcLeftDown',
 'FcIcons.FcLeftDown2',
 'FcIcons.FcLeftUp',
 'FcIcons.FcLeftUp2',
 'FcIcons.FcLeft',
 'FcIcons.FcLibrary',
 'FcIcons.FcLightAtTheEndOfTunnel',
 'FcIcons.FcLikePlaceholder',
 'FcIcons.FcLike',
 'FcIcons.FcLineChart',
 'FcIcons.FcLink',
 'FcIcons.FcLinux',
 'FcIcons.FcList',
 'FcIcons.FcLockLandscape',
 'FcIcons.FcLockPortrait',
 'FcIcons.FcLock',
 'FcIcons.FcLowBattery',
 'FcIcons.FcLowPriority',
 'FcIcons.FcMakeDecision',
 'FcIcons.FcManager',
 'FcIcons.FcMediumPriority',
 'FcIcons.FcMenu',
 'FcIcons.FcMiddleBattery',
 'FcIcons.FcMindMap',
 'FcIcons.FcMinus',
 'FcIcons.FcMissedCall',
 'FcIcons.FcMms',
 'FcIcons.FcMoneyTransfer',
 'FcIcons.FcMultipleCameras',
 'FcIcons.FcMultipleDevices',
 'FcIcons.FcMultipleInputs',
 'FcIcons.FcMultipleSmartphones',
 'FcIcons.FcMusic',
 'FcIcons.FcNegativeDynamic',
 'FcIcons.FcNeutralDecision',
 'FcIcons.FcNeutralTrading',
 'FcIcons.FcNews',
 'FcIcons.FcNext',
 'FcIcons.FcNfcSign',
 'FcIcons.FcNightLandscape',
 'FcIcons.FcNightPortrait',
 'FcIcons.FcNoIdea',
 'FcIcons.FcNoVideo',
 'FcIcons.FcNook',
 'FcIcons.FcNumericalSorting12',
 'FcIcons.FcNumericalSorting21',
 'FcIcons.FcOk',
 'FcIcons.FcOldTimeCamera',
 'FcIcons.FcOnlineSupport',
 'FcIcons.FcOpenedFolder',
 'FcIcons.FcOrgUnit',
 'FcIcons.FcOrganization',
 'FcIcons.FcOvertime',
 'FcIcons.FcPackage',
 'FcIcons.FcPaid',
 'FcIcons.FcPanorama',
 'FcIcons.FcParallelTasks',
 'FcIcons.FcPhoneAndroid',
 'FcIcons.FcPhone',
 'FcIcons.FcPhotoReel',
 'FcIcons.FcPicture',
 'FcIcons.FcPieChart',
 'FcIcons.FcPlanner',
 'FcIcons.FcPlus',
 'FcIcons.FcPodiumWithAudience',
 'FcIcons.FcPodiumWithSpeaker',
 'FcIcons.FcPodiumWithoutSpeaker',
 'FcIcons.FcPortraitMode',
 'FcIcons.FcPositiveDynamic',
 'FcIcons.FcPrevious',
 'FcIcons.FcPrint',
 'FcIcons.FcPrivacy',
 'FcIcons.FcProcess',
 'FcIcons.FcPuzzle',
 'FcIcons.FcQuestions',
 'FcIcons.FcRadarPlot',
 'FcIcons.FcRating',
 'FcIcons.FcRatings',
 'FcIcons.FcReadingEbook',
 'FcIcons.FcReading',
 'FcIcons.FcReddit',
 'FcIcons.FcRedo',
 'FcIcons.FcRefresh',
 'FcIcons.FcRegisteredTrademark',
 'FcIcons.FcRemoveImage',
 'FcIcons.FcReuse',
 'FcIcons.FcRightDown',
 'FcIcons.FcRightDown2',
 'FcIcons.FcRightUp',
 'FcIcons.FcRightUp2',
 'FcIcons.FcRight',
 'FcIcons.FcRotateCamera',
 'FcIcons.FcRotateToLandscape',
 'FcIcons.FcRotateToPortrait',
 'FcIcons.FcRuler',
 'FcIcons.FcRules',
 'FcIcons.FcSafe',
 'FcIcons.FcSalesPerformance',
 'FcIcons.FcScatterPlot',
 'FcIcons.FcSearch',
 'FcIcons.FcSelfServiceKiosk',
 'FcIcons.FcSelfie',
 'FcIcons.FcSerialTasks',
 'FcIcons.FcServiceMark',
 'FcIcons.FcServices',
 'FcIcons.FcSettings',
 'FcIcons.FcShare',
 'FcIcons.FcShipped',
 'FcIcons.FcShop',
 'FcIcons.FcSignature',
 'FcIcons.FcSimCardChip',
 'FcIcons.FcSimCard',
 'FcIcons.FcSlrBackSide',
 'FcIcons.FcSmartphoneTablet',
 'FcIcons.FcSms',
 'FcIcons.FcSoundRecordingCopyright',
 'FcIcons.FcSpeaker',
 'FcIcons.FcSportsMode',
 'FcIcons.FcStackOfPhotos',
 'FcIcons.FcStart',
 'FcIcons.FcStatistics',
 'FcIcons.FcSteam',
 'FcIcons.FcStumbleupon',
 'FcIcons.FcSupport',
 'FcIcons.FcSurvey',
 'FcIcons.FcSwitchCamera',
 'FcIcons.FcSynchronize',
 'FcIcons.FcTabletAndroid',
 'FcIcons.FcTemplate',
 'FcIcons.FcTimeline',
 'FcIcons.FcTodoList',
 'FcIcons.FcTouchscreenSmartphone',
 'FcIcons.FcTrademark',
 'FcIcons.FcTreeStructure',
 'FcIcons.FcTwoSmartphones',
 'FcIcons.FcUndo',
 'FcIcons.FcUnlock',
 'FcIcons.FcUpLeft',
 'FcIcons.FcUpRight',
 'FcIcons.FcUp',
 'FcIcons.FcUpload',
 'FcIcons.FcUsb',
 'FcIcons.FcVideoCall',
 'FcIcons.FcVideoFile',
 'FcIcons.FcVideoProjector',
 'FcIcons.FcViewDetails',
 'FcIcons.FcVip',
 'FcIcons.FcVlc',
 'FcIcons.FcVoicePresentation',
 'FcIcons.FcVoicemail',
 'FcIcons.FcWebcam',
 'FcIcons.FcWiFiLogo',
 'FcIcons.FcWikipedia',
 'FcIcons.FcWorkflow',  

 
'RiIcons.RiAncientGateFill',
'RiIcons.RiAncientGateLine',
'RiIcons.RiAncientPavilionFill',
'RiIcons.RiAncientPavilionLine',
'RiIcons.RiBankFill',
'RiIcons.RiBankLine',
'RiIcons.RiBuilding2Fill',
'RiIcons.RiBuilding2Line',
'RiIcons.RiBuilding3Fill',
'RiIcons.RiBuilding3Line',
'RiIcons.RiBuilding4Fill',
'RiIcons.RiBuilding4Line',
'RiIcons.RiBuildingFill',
'RiIcons.RiBuildingLine',
'RiIcons.RiCommunityFill',
'RiIcons.RiCommunityLine',
'RiIcons.RiGovernmentFill',
'RiIcons.RiGovernmentLine',
'RiIcons.RiHome2Fill',
'RiIcons.RiHome2Line',
'RiIcons.RiHome3Fill',
'RiIcons.RiHome3Line',
'RiIcons.RiHome4Fill',
'RiIcons.RiHome4Line',
'RiIcons.RiHome5Fill',
'RiIcons.RiHome5Line',
'RiIcons.RiHome6Fill',
'RiIcons.RiHome6Line',
'RiIcons.RiHome7Fill',
'RiIcons.RiHome7Line',
'RiIcons.RiHome8Fill',
'RiIcons.RiHome8Line',
'RiIcons.RiHomeFill',
'RiIcons.RiHomeGearFill',
'RiIcons.RiHomeGearLine',
'RiIcons.RiHomeHeartFill',
'RiIcons.RiHomeHeartLine',
'RiIcons.RiHomeLine',
'RiIcons.RiHomeSmile2Fill',
'RiIcons.RiHomeSmile2Line',
'RiIcons.RiHomeSmileFill',
'RiIcons.RiHomeSmileLine',
'RiIcons.RiHomeWifiFill',
'RiIcons.RiHomeWifiLine',
'RiIcons.RiHospitalFill',
'RiIcons.RiHospitalLine',
'RiIcons.RiHotelFill',
'RiIcons.RiHotelLine',
'RiIcons.RiStore2Fill',
'RiIcons.RiStore2Line',
'RiIcons.RiStore3Fill',
'RiIcons.RiStore3Line',
'RiIcons.RiStoreFill',
'RiIcons.RiStoreLine',
'RiIcons.RiAdvertisementFill',
'RiIcons.RiAdvertisementLine',
'RiIcons.RiArchiveDrawerFill',
'RiIcons.RiArchiveDrawerLine',
'RiIcons.RiArchiveFill',
'RiIcons.RiArchiveLine',
'RiIcons.RiAtFill',
'RiIcons.RiAtLine',
'RiIcons.RiAttachmentFill',
'RiIcons.RiAttachmentLine',
'RiIcons.RiAwardFill',
'RiIcons.RiAwardLine',
'RiIcons.RiBarChart2Fill',
'RiIcons.RiBarChart2Line',
'RiIcons.RiBarChartBoxFill',
'RiIcons.RiBarChartBoxLine',
'RiIcons.RiBarChartFill',
'RiIcons.RiBarChartGroupedFill',
'RiIcons.RiBarChartGroupedLine',
'RiIcons.RiBarChartHorizontalFill',
'RiIcons.RiBarChartHorizontalLine',
'RiIcons.RiBarChartLine',
'RiIcons.RiBookmark2Fill',
'RiIcons.RiBookmark2Line',
'RiIcons.RiBookmark3Fill',
'RiIcons.RiBookmark3Line',
'RiIcons.RiBookmarkFill',
'RiIcons.RiBookmarkLine',
'RiIcons.RiBriefcase2Fill',
'RiIcons.RiBriefcase2Line',
'RiIcons.RiBriefcase3Fill',
'RiIcons.RiBriefcase3Line',
'RiIcons.RiBriefcase4Fill',
'RiIcons.RiBriefcase4Line',
'RiIcons.RiBriefcase5Fill',
'RiIcons.RiBriefcase5Line',
'RiIcons.RiBriefcaseFill',
'RiIcons.RiBriefcaseLine',
'RiIcons.RiBubbleChartFill',
'RiIcons.RiBubbleChartLine',
'RiIcons.RiCalculatorFill',
'RiIcons.RiCalculatorLine',
'RiIcons.RiCalendar2Fill',
'RiIcons.RiCalendar2Line',
'RiIcons.RiCalendarCheckFill',
'RiIcons.RiCalendarCheckLine',
'RiIcons.RiCalendarEventFill',
'RiIcons.RiCalendarEventLine',
'RiIcons.RiCalendarFill',
'RiIcons.RiCalendarLine',
'RiIcons.RiCalendarTodoFill',
'RiIcons.RiCalendarTodoLine',
'RiIcons.RiCloudFill',
'RiIcons.RiCloudLine',
'RiIcons.RiCloudOffFill',
'RiIcons.RiCloudOffLine',
'RiIcons.RiCopyleftFill',
'RiIcons.RiCopyleftLine',
'RiIcons.RiCopyrightFill',
'RiIcons.RiCopyrightLine',
'RiIcons.RiCreativeCommonsByFill',
'RiIcons.RiCreativeCommonsByLine',
'RiIcons.RiCreativeCommonsFill',
'RiIcons.RiCreativeCommonsLine',
'RiIcons.RiCreativeCommonsNcFill',
'RiIcons.RiCreativeCommonsNcLine',
'RiIcons.RiCreativeCommonsNdFill',
'RiIcons.RiCreativeCommonsNdLine',
'RiIcons.RiCreativeCommonsSaFill',
'RiIcons.RiCreativeCommonsSaLine',
'RiIcons.RiCreativeCommonsZeroFill',
'RiIcons.RiCreativeCommonsZeroLine',
'RiIcons.RiCustomerService2Fill',
'RiIcons.RiCustomerService2Line',
'RiIcons.RiCustomerServiceFill',
'RiIcons.RiCustomerServiceLine',
'RiIcons.RiDonutChartFill',
'RiIcons.RiDonutChartLine',
'RiIcons.RiFlag2Fill',
'RiIcons.RiFlag2Line',
'RiIcons.RiFlagFill',
'RiIcons.RiFlagLine',
'RiIcons.RiGlobalFill',
'RiIcons.RiGlobalLine',
'RiIcons.RiHonourFill',
'RiIcons.RiHonourLine',
'RiIcons.RiInboxArchiveFill',
'RiIcons.RiInboxArchiveLine',
'RiIcons.RiInboxFill',
'RiIcons.RiInboxLine',
'RiIcons.RiInboxUnarchiveFill',
'RiIcons.RiInboxUnarchiveLine',
'RiIcons.RiLineChartFill',
'RiIcons.RiLineChartLine',
'RiIcons.RiLinksFill',
'RiIcons.RiLinksLine',
'RiIcons.RiMailAddFill',
'RiIcons.RiMailAddLine',
'RiIcons.RiMailCheckFill',
'RiIcons.RiMailCheckLine',
'RiIcons.RiMailCloseFill',
'RiIcons.RiMailCloseLine',
'RiIcons.RiMailDownloadFill',
'RiIcons.RiMailDownloadLine',
'RiIcons.RiMailFill',
'RiIcons.RiMailForbidFill',
'RiIcons.RiMailForbidLine',
'RiIcons.RiMailLine',
'RiIcons.RiMailLockFill',
'RiIcons.RiMailLockLine',
'RiIcons.RiMailOpenFill',
'RiIcons.RiMailOpenLine',
'RiIcons.RiMailSendFill',
'RiIcons.RiMailSendLine',
'RiIcons.RiMailSettingsFill',
'RiIcons.RiMailSettingsLine',
'RiIcons.RiMailStarFill',
'RiIcons.RiMailStarLine',
'RiIcons.RiMailUnreadFill',
'RiIcons.RiMailUnreadLine',
'RiIcons.RiMailVolumeFill',
'RiIcons.RiMailVolumeLine',
'RiIcons.RiMedal2Fill',
'RiIcons.RiMedal2Line',
'RiIcons.RiMedalFill',
'RiIcons.RiMedalLine',
'RiIcons.RiPieChart2Fill',
'RiIcons.RiPieChart2Line',
'RiIcons.RiPieChartBoxFill',
'RiIcons.RiPieChartBoxLine',
'RiIcons.RiPieChartFill',
'RiIcons.RiPieChartLine',
'RiIcons.RiPrinterCloudFill',
'RiIcons.RiPrinterCloudLine',
'RiIcons.RiPrinterFill',
'RiIcons.RiPrinterLine',
'RiIcons.RiProfileFill',
'RiIcons.RiProfileLine',
'RiIcons.RiProjector2Fill',
'RiIcons.RiProjector2Line',
'RiIcons.RiProjectorFill',
'RiIcons.RiProjectorLine',
'RiIcons.RiRecordMailFill',
'RiIcons.RiRecordMailLine',
'RiIcons.RiRegisteredFill',
'RiIcons.RiRegisteredLine',
'RiIcons.RiReplyAllFill',
'RiIcons.RiReplyAllLine',
'RiIcons.RiReplyFill',
'RiIcons.RiReplyLine',
'RiIcons.RiSendPlane2Fill',
'RiIcons.RiSendPlane2Line',
'RiIcons.RiSendPlaneFill',
'RiIcons.RiSendPlaneLine',
'RiIcons.RiServiceFill',
'RiIcons.RiServiceLine',
'RiIcons.RiSlideshow2Fill',
'RiIcons.RiSlideshow2Line',
'RiIcons.RiSlideshow3Fill',
'RiIcons.RiSlideshow3Line',
'RiIcons.RiSlideshow4Fill',
'RiIcons.RiSlideshow4Line',
'RiIcons.RiSlideshowFill',
'RiIcons.RiSlideshowLine',
'RiIcons.RiStackFill',
'RiIcons.RiStackLine',
'RiIcons.RiTrademarkFill',
'RiIcons.RiTrademarkLine',
'RiIcons.RiWindow2Fill',
'RiIcons.RiWindow2Line',
'RiIcons.RiWindowFill',
'RiIcons.RiWindowLine',
'RiIcons.RiChat1Fill',
'RiIcons.RiChat1Line',
'RiIcons.RiChat2Fill',
'RiIcons.RiChat2Line',
'RiIcons.RiChat3Fill',
'RiIcons.RiChat3Line',
'RiIcons.RiChat4Fill',
'RiIcons.RiChat4Line',
'RiIcons.RiChatCheckFill',
'RiIcons.RiChatCheckLine',
'RiIcons.RiChatDeleteFill',
'RiIcons.RiChatDeleteLine',
'RiIcons.RiChatDownloadFill',
'RiIcons.RiChatDownloadLine',
'RiIcons.RiChatFollowUpFill',
'RiIcons.RiChatFollowUpLine',
'RiIcons.RiChatForwardFill',
'RiIcons.RiChatForwardLine',
'RiIcons.RiChatHeartFill',
'RiIcons.RiChatHeartLine',
'RiIcons.RiChatHistoryFill',
'RiIcons.RiChatHistoryLine',
'RiIcons.RiChatNewFill',
'RiIcons.RiChatNewLine',
'RiIcons.RiChatOffFill',
'RiIcons.RiChatOffLine',
'RiIcons.RiChatPollFill',
'RiIcons.RiChatPollLine',
'RiIcons.RiChatPrivateFill',
'RiIcons.RiChatPrivateLine',
'RiIcons.RiChatQuoteFill',
'RiIcons.RiChatQuoteLine',
'RiIcons.RiChatSettingsFill',
'RiIcons.RiChatSettingsLine',
'RiIcons.RiChatSmile2Fill',
'RiIcons.RiChatSmile2Line',
'RiIcons.RiChatSmile3Fill',
'RiIcons.RiChatSmile3Line',
'RiIcons.RiChatSmileFill',
'RiIcons.RiChatSmileLine',
'RiIcons.RiChatUploadFill',
'RiIcons.RiChatUploadLine',
'RiIcons.RiChatVoiceFill',
'RiIcons.RiChatVoiceLine',
'RiIcons.RiDiscussFill',
'RiIcons.RiDiscussLine',
'RiIcons.RiFeedbackFill',
'RiIcons.RiFeedbackLine',
'RiIcons.RiMessage2Fill',
'RiIcons.RiMessage2Line',
'RiIcons.RiMessage3Fill',
'RiIcons.RiMessage3Line',
'RiIcons.RiMessageFill',
'RiIcons.RiMessageLine',
'RiIcons.RiQuestionAnswerFill',
'RiIcons.RiQuestionAnswerLine',
'RiIcons.RiQuestionnaireFill',
'RiIcons.RiQuestionnaireLine',
'RiIcons.RiVideoChatFill',
'RiIcons.RiVideoChatLine',
'RiIcons.RiAnticlockwise2Fill',
'RiIcons.RiAnticlockwise2Line',
'RiIcons.RiAnticlockwiseFill',
'RiIcons.RiAnticlockwiseLine',
'RiIcons.RiArtboard2Fill',
'RiIcons.RiArtboard2Line',
'RiIcons.RiArtboardFill',
'RiIcons.RiArtboardLine',
'RiIcons.RiBallPenFill',
'RiIcons.RiBallPenLine',
'RiIcons.RiBlurOffFill',
'RiIcons.RiBlurOffLine',
'RiIcons.RiBrush2Fill',
'RiIcons.RiBrush2Line',
'RiIcons.RiBrush3Fill',
'RiIcons.RiBrush3Line',
'RiIcons.RiBrush4Fill',
'RiIcons.RiBrush4Line',
'RiIcons.RiBrushFill',
'RiIcons.RiBrushLine',
'RiIcons.RiClockwise2Fill',
'RiIcons.RiClockwise2Line',
'RiIcons.RiClockwiseFill',
'RiIcons.RiClockwiseLine',
'RiIcons.RiCollageFill',
'RiIcons.RiCollageLine',
'RiIcons.RiCompasses2Fill',
'RiIcons.RiCompasses2Line',
'RiIcons.RiCompassesFill',
'RiIcons.RiCompassesLine',
'RiIcons.RiContrast2Fill',
'RiIcons.RiContrast2Line',
'RiIcons.RiContrastDrop2Fill',
'RiIcons.RiContrastDrop2Line',
'RiIcons.RiContrastDropFill',
'RiIcons.RiContrastDropLine',
'RiIcons.RiContrastFill',
'RiIcons.RiContrastLine',
'RiIcons.RiCrop2Fill',
'RiIcons.RiCrop2Line',
'RiIcons.RiCropFill',
'RiIcons.RiCropLine',
'RiIcons.RiDragDropFill',
'RiIcons.RiDragDropLine',
'RiIcons.RiDragMove2Fill',
'RiIcons.RiDragMove2Line',
'RiIcons.RiDragMoveFill',
'RiIcons.RiDragMoveLine',
'RiIcons.RiDropFill',
'RiIcons.RiDropLine',
'RiIcons.RiEdit2Fill',
'RiIcons.RiEdit2Line',
'RiIcons.RiEditBoxFill',
'RiIcons.RiEditBoxLine',
'RiIcons.RiEditCircleFill',
'RiIcons.RiEditCircleLine',
'RiIcons.RiEditFill',
'RiIcons.RiEditLine',
'RiIcons.RiEraserFill',
'RiIcons.RiEraserLine',
'RiIcons.RiFocus2Fill',
'RiIcons.RiFocus2Line',
'RiIcons.RiFocus3Fill',
'RiIcons.RiFocus3Line',
'RiIcons.RiFocusFill',
'RiIcons.RiFocusLine',
'RiIcons.RiGridFill',
'RiIcons.RiGridLine',
'RiIcons.RiHammerFill',
'RiIcons.RiHammerLine',
'RiIcons.RiInkBottleFill',
'RiIcons.RiInkBottleLine',
'RiIcons.RiInputMethodFill',
'RiIcons.RiInputMethodLine',
'RiIcons.RiLayout2Fill',
'RiIcons.RiLayout2Line',
'RiIcons.RiLayout3Fill',
'RiIcons.RiLayout3Line',
'RiIcons.RiLayout4Fill',
'RiIcons.RiLayout4Line',
'RiIcons.RiLayout5Fill',
'RiIcons.RiLayout5Line',
'RiIcons.RiLayout6Fill',
'RiIcons.RiLayout6Line',
'RiIcons.RiLayoutBottom2Fill',
'RiIcons.RiLayoutBottom2Line',
'RiIcons.RiLayoutBottomFill',
'RiIcons.RiLayoutBottomLine',
'RiIcons.RiLayoutColumnFill',
'RiIcons.RiLayoutColumnLine',
'RiIcons.RiLayoutFill',
'RiIcons.RiLayoutGridFill',
'RiIcons.RiLayoutGridLine',
'RiIcons.RiLayoutLeft2Fill',
'RiIcons.RiLayoutLeft2Line',
'RiIcons.RiLayoutLeftFill',
'RiIcons.RiLayoutLeftLine',
'RiIcons.RiLayoutLine',
'RiIcons.RiLayoutMasonryFill',
'RiIcons.RiLayoutMasonryLine',
'RiIcons.RiLayoutRight2Fill',
'RiIcons.RiLayoutRight2Line',
'RiIcons.RiLayoutRightFill',
'RiIcons.RiLayoutRightLine',
'RiIcons.RiLayoutRowFill',
'RiIcons.RiLayoutRowLine',
'RiIcons.RiLayoutTop2Fill',
'RiIcons.RiLayoutTop2Line',
'RiIcons.RiLayoutTopFill',
'RiIcons.RiLayoutTopLine',
'RiIcons.RiMagicFill',
'RiIcons.RiMagicLine',
'RiIcons.RiMarkPenFill',
'RiIcons.RiMarkPenLine',
'RiIcons.RiMarkupFill',
'RiIcons.RiMarkupLine',
'RiIcons.RiPaintBrushFill',
'RiIcons.RiPaintBrushLine',
'RiIcons.RiPaintFill',
'RiIcons.RiPaintLine',
'RiIcons.RiPaletteFill',
'RiIcons.RiPaletteLine',
'RiIcons.RiPantoneFill',
'RiIcons.RiPantoneLine',
'RiIcons.RiPenNibFill',
'RiIcons.RiPenNibLine',
'RiIcons.RiPencilFill',
'RiIcons.RiPencilLine',
'RiIcons.RiPencilRuler2Fill',
'RiIcons.RiPencilRuler2Line',
'RiIcons.RiPencilRulerFill',
'RiIcons.RiPencilRulerLine',
'RiIcons.RiQuillPenFill',
'RiIcons.RiQuillPenLine',
'RiIcons.RiRuler2Fill',
'RiIcons.RiRuler2Line',
'RiIcons.RiRulerFill',
'RiIcons.RiRulerLine',
'RiIcons.RiScissors2Fill',
'RiIcons.RiScissors2Line',
'RiIcons.RiScissorsCutFill',
'RiIcons.RiScissorsCutLine',
'RiIcons.RiScissorsFill',
'RiIcons.RiScissorsLine',
'RiIcons.RiScreenshot2Fill',
'RiIcons.RiScreenshot2Line',
'RiIcons.RiScreenshotFill',
'RiIcons.RiScreenshotLine',
'RiIcons.RiShape2Fill',
'RiIcons.RiShape2Line',
'RiIcons.RiShapeFill',
'RiIcons.RiShapeLine',
'RiIcons.RiSipFill',
'RiIcons.RiSipLine',
'RiIcons.RiSliceFill',
'RiIcons.RiSliceLine',
'RiIcons.RiTBoxFill',
'RiIcons.RiTBoxLine',
'RiIcons.RiTableAltFill',
'RiIcons.RiTableAltLine',
'RiIcons.RiTableFill',
'RiIcons.RiTableLine',
'RiIcons.RiToolsFill',
'RiIcons.RiToolsLine',
'RiIcons.RiBracesFill',
'RiIcons.RiBracesLine',
'RiIcons.RiBracketsFill',
'RiIcons.RiBracketsLine',
'RiIcons.RiBug2Fill',
'RiIcons.RiBug2Line',
'RiIcons.RiBugFill',
'RiIcons.RiBugLine',
'RiIcons.RiCodeBoxFill',
'RiIcons.RiCodeBoxLine',
'RiIcons.RiCodeFill',
'RiIcons.RiCodeLine',
'RiIcons.RiCodeSFill',
'RiIcons.RiCodeSLine',
'RiIcons.RiCodeSSlashFill',
'RiIcons.RiCodeSSlashLine',
'RiIcons.RiCommandFill',
'RiIcons.RiCommandLine',
'RiIcons.RiCss3Fill',
'RiIcons.RiCss3Line',
'RiIcons.RiCursorFill',
'RiIcons.RiCursorLine',
'RiIcons.RiGitBranchFill',
'RiIcons.RiGitBranchLine',
'RiIcons.RiGitCommitFill',
'RiIcons.RiGitCommitLine',
'RiIcons.RiGitMergeFill',
'RiIcons.RiGitMergeLine',
'RiIcons.RiGitPullRequestFill',
'RiIcons.RiGitPullRequestLine',
'RiIcons.RiGitRepositoryCommitsFill',
'RiIcons.RiGitRepositoryCommitsLine',
'RiIcons.RiGitRepositoryFill',
'RiIcons.RiGitRepositoryLine',
'RiIcons.RiGitRepositoryPrivateFill',
'RiIcons.RiGitRepositoryPrivateLine',
'RiIcons.RiHtml5Fill',
'RiIcons.RiHtml5Line',
'RiIcons.RiParenthesesFill',
'RiIcons.RiParenthesesLine',
'RiIcons.RiTerminalBoxFill',
'RiIcons.RiTerminalBoxLine',
'RiIcons.RiTerminalFill',
'RiIcons.RiTerminalLine',
'RiIcons.RiTerminalWindowFill',
'RiIcons.RiTerminalWindowLine',
'RiIcons.RiAirplayFill',
'RiIcons.RiAirplayLine',
'RiIcons.RiBarcodeBoxFill',
'RiIcons.RiBarcodeBoxLine',
'RiIcons.RiBarcodeFill',
'RiIcons.RiBarcodeLine',
'RiIcons.RiBaseStationFill',
'RiIcons.RiBaseStationLine',
'RiIcons.RiBattery2ChargeFill',
'RiIcons.RiBattery2ChargeLine',
'RiIcons.RiBattery2Fill',
'RiIcons.RiBattery2Line',
'RiIcons.RiBatteryChargeFill',
'RiIcons.RiBatteryChargeLine',
'RiIcons.RiBatteryFill',
'RiIcons.RiBatteryLine',
'RiIcons.RiBatteryLowFill',
'RiIcons.RiBatteryLowLine',
'RiIcons.RiBatterySaverFill',
'RiIcons.RiBatterySaverLine',
'RiIcons.RiBatteryShareFill',
'RiIcons.RiBatteryShareLine',
'RiIcons.RiBluetoothConnectFill',
'RiIcons.RiBluetoothConnectLine',
'RiIcons.RiBluetoothFill',
'RiIcons.RiBluetoothLine',
'RiIcons.RiCastFill',
'RiIcons.RiCastLine',
'RiIcons.RiCellphoneFill',
'RiIcons.RiCellphoneLine',
'RiIcons.RiComputerFill',
'RiIcons.RiComputerLine',
'RiIcons.RiCpuFill',
'RiIcons.RiCpuLine',
'RiIcons.RiDashboard2Fill',
'RiIcons.RiDashboard2Line',
'RiIcons.RiDashboard3Fill',
'RiIcons.RiDashboard3Line',
'RiIcons.RiDatabase2Fill',
'RiIcons.RiDatabase2Line',
'RiIcons.RiDatabaseFill',
'RiIcons.RiDatabaseLine',
'RiIcons.RiDeviceFill',
'RiIcons.RiDeviceLine',
'RiIcons.RiDeviceRecoverFill',
'RiIcons.RiDeviceRecoverLine',
'RiIcons.RiDualSim1Fill',
'RiIcons.RiDualSim1Line',
'RiIcons.RiDualSim2Fill',
'RiIcons.RiDualSim2Line',
'RiIcons.RiFingerprint2Fill',
'RiIcons.RiFingerprint2Line',
'RiIcons.RiFingerprintFill',
'RiIcons.RiFingerprintLine',
'RiIcons.RiGamepadFill',
'RiIcons.RiGamepadLine',
'RiIcons.RiGpsFill',
'RiIcons.RiGpsLine',
'RiIcons.RiGradienterFill',
'RiIcons.RiGradienterLine',
'RiIcons.RiHardDrive2Fill',
'RiIcons.RiHardDrive2Line',
'RiIcons.RiHardDriveFill',
'RiIcons.RiHardDriveLine',
'RiIcons.RiHotspotFill',
'RiIcons.RiHotspotLine',
'RiIcons.RiInstallFill',
'RiIcons.RiInstallLine',
'RiIcons.RiKeyboardBoxFill',
'RiIcons.RiKeyboardBoxLine',
'RiIcons.RiKeyboardFill',
'RiIcons.RiKeyboardLine',
'RiIcons.RiMacFill',
'RiIcons.RiMacLine',
'RiIcons.RiMacbookFill',
'RiIcons.RiMacbookLine',
'RiIcons.RiMouseFill',
'RiIcons.RiMouseLine',
'RiIcons.RiPhoneFill',
'RiIcons.RiPhoneFindFill',
'RiIcons.RiPhoneFindLine',
'RiIcons.RiPhoneLine',
'RiIcons.RiPhoneLockFill',
'RiIcons.RiPhoneLockLine',
'RiIcons.RiQrCodeFill',
'RiIcons.RiQrCodeLine',
'RiIcons.RiQrScan2Fill',
'RiIcons.RiQrScan2Line',
'RiIcons.RiQrScanFill',
'RiIcons.RiQrScanLine',
'RiIcons.RiRadarFill',
'RiIcons.RiRadarLine',
'RiIcons.RiRemoteControl2Fill',
'RiIcons.RiRemoteControl2Line',
'RiIcons.RiRemoteControlFill',
'RiIcons.RiRemoteControlLine',
'RiIcons.RiRestartFill',
'RiIcons.RiRestartLine',
'RiIcons.RiRotateLockFill',
'RiIcons.RiRotateLockLine',
'RiIcons.RiRouterFill',
'RiIcons.RiRouterLine',
'RiIcons.RiRssFill',
'RiIcons.RiRssLine',
'RiIcons.RiSave2Fill',
'RiIcons.RiSave2Line',
'RiIcons.RiSave3Fill',
'RiIcons.RiSave3Line',
'RiIcons.RiSaveFill',
'RiIcons.RiSaveLine',
'RiIcons.RiScan2Fill',
'RiIcons.RiScan2Line',
'RiIcons.RiScanFill',
'RiIcons.RiScanLine',
'RiIcons.RiSdCardFill',
'RiIcons.RiSdCardLine',
'RiIcons.RiSdCardMiniFill',
'RiIcons.RiSdCardMiniLine',
'RiIcons.RiSensorFill',
'RiIcons.RiSensorLine',
'RiIcons.RiServerFill',
'RiIcons.RiServerLine',
'RiIcons.RiShutDownFill',
'RiIcons.RiShutDownLine',
'RiIcons.RiSignalWifi1Fill',
'RiIcons.RiSignalWifi1Line',
'RiIcons.RiSignalWifi2Fill',
'RiIcons.RiSignalWifi2Line',
'RiIcons.RiSignalWifi3Fill',
'RiIcons.RiSignalWifi3Line',
'RiIcons.RiSignalWifiErrorFill',
'RiIcons.RiSignalWifiErrorLine',
'RiIcons.RiSignalWifiFill',
'RiIcons.RiSignalWifiLine',
'RiIcons.RiSignalWifiOffFill',
'RiIcons.RiSignalWifiOffLine',
'RiIcons.RiSimCard2Fill',
'RiIcons.RiSimCard2Line',
'RiIcons.RiSimCardFill',
'RiIcons.RiSimCardLine',
'RiIcons.RiSmartphoneFill',
'RiIcons.RiSmartphoneLine',
'RiIcons.RiTabletFill',
'RiIcons.RiTabletLine',
'RiIcons.RiTv2Fill',
'RiIcons.RiTv2Line',
'RiIcons.RiTvFill',
'RiIcons.RiTvLine',
'RiIcons.RiUDiskFill',
'RiIcons.RiUDiskLine',
'RiIcons.RiUninstallFill',
'RiIcons.RiUninstallLine',
'RiIcons.RiUsbFill',
'RiIcons.RiUsbLine',
'RiIcons.RiWifiFill',
'RiIcons.RiWifiLine',
'RiIcons.RiWifiOffFill',
'RiIcons.RiWifiOffLine',
'RiIcons.RiWirelessChargingFill',
'RiIcons.RiWirelessChargingLine',
'RiIcons.RiArticleFill',
'RiIcons.RiArticleLine',
'RiIcons.RiBillFill',
'RiIcons.RiBillLine',
'RiIcons.RiBook2Fill',
'RiIcons.RiBook2Line',
'RiIcons.RiBook3Fill',
'RiIcons.RiBook3Line',
'RiIcons.RiBookFill',
'RiIcons.RiBookLine',
'RiIcons.RiBookMarkFill',
'RiIcons.RiBookMarkLine',
'RiIcons.RiBookOpenFill',
'RiIcons.RiBookOpenLine',
'RiIcons.RiBookReadFill',
'RiIcons.RiBookReadLine',
'RiIcons.RiBookletFill',
'RiIcons.RiBookletLine',
'RiIcons.RiClipboardFill',
'RiIcons.RiClipboardLine',
'RiIcons.RiContactsBook2Fill',
'RiIcons.RiContactsBook2Line',
'RiIcons.RiContactsBookFill',
'RiIcons.RiContactsBookLine',
'RiIcons.RiContactsBookUploadFill',
'RiIcons.RiContactsBookUploadLine',
'RiIcons.RiDraftFill',
'RiIcons.RiDraftLine',
'RiIcons.RiFile2Fill',
'RiIcons.RiFile2Line',
'RiIcons.RiFile3Fill',
'RiIcons.RiFile3Line',
'RiIcons.RiFile4Fill',
'RiIcons.RiFile4Line',
'RiIcons.RiFileAddFill',
'RiIcons.RiFileAddLine',
'RiIcons.RiFileChart2Fill',
'RiIcons.RiFileChart2Line',
'RiIcons.RiFileChartFill',
'RiIcons.RiFileChartLine',
'RiIcons.RiFileCloudFill',
'RiIcons.RiFileCloudLine',
'RiIcons.RiFileCodeFill',
'RiIcons.RiFileCodeLine',
'RiIcons.RiFileCopy2Fill',
'RiIcons.RiFileCopy2Line',
'RiIcons.RiFileCopyFill',
'RiIcons.RiFileCopyLine',
'RiIcons.RiFileDamageFill',
'RiIcons.RiFileDamageLine',
'RiIcons.RiFileDownloadFill',
'RiIcons.RiFileDownloadLine',
'RiIcons.RiFileEditFill',
'RiIcons.RiFileEditLine',
'RiIcons.RiFileExcel2Fill',
'RiIcons.RiFileExcel2Line',
'RiIcons.RiFileExcelFill',
'RiIcons.RiFileExcelLine',
'RiIcons.RiFileFill',
'RiIcons.RiFileForbidFill',
'RiIcons.RiFileForbidLine',
'RiIcons.RiFileGifFill',
'RiIcons.RiFileGifLine',
'RiIcons.RiFileHistoryFill',
'RiIcons.RiFileHistoryLine',
'RiIcons.RiFileHwpFill',
'RiIcons.RiFileHwpLine',
'RiIcons.RiFileInfoFill',
'RiIcons.RiFileInfoLine',
'RiIcons.RiFileLine',
'RiIcons.RiFileList2Fill',
'RiIcons.RiFileList2Line',
'RiIcons.RiFileList3Fill',
'RiIcons.RiFileList3Line',
'RiIcons.RiFileListFill',
'RiIcons.RiFileListLine',
'RiIcons.RiFileLockFill',
'RiIcons.RiFileLockLine',
'RiIcons.RiFileMarkFill',
'RiIcons.RiFileMarkLine',
'RiIcons.RiFileMusicFill',
'RiIcons.RiFileMusicLine',
'RiIcons.RiFilePaper2Fill',
'RiIcons.RiFilePaper2Line',
'RiIcons.RiFilePaperFill',
'RiIcons.RiFilePaperLine',
'RiIcons.RiFilePdfFill',
'RiIcons.RiFilePdfLine',
'RiIcons.RiFilePpt2Fill',
'RiIcons.RiFilePpt2Line',
'RiIcons.RiFilePptFill',
'RiIcons.RiFilePptLine',
'RiIcons.RiFileReduceFill',
'RiIcons.RiFileReduceLine',
'RiIcons.RiFileSearchFill',
'RiIcons.RiFileSearchLine',
'RiIcons.RiFileSettingsFill',
'RiIcons.RiFileSettingsLine',
'RiIcons.RiFileShield2Fill',
'RiIcons.RiFileShield2Line',
'RiIcons.RiFileShieldFill',
'RiIcons.RiFileShieldLine',
'RiIcons.RiFileShredFill',
'RiIcons.RiFileShredLine',
'RiIcons.RiFileTextFill',
'RiIcons.RiFileTextLine',
'RiIcons.RiFileTransferFill',
'RiIcons.RiFileTransferLine',
'RiIcons.RiFileUnknowFill',
'RiIcons.RiFileUnknowLine',
'RiIcons.RiFileUploadFill',
'RiIcons.RiFileUploadLine',
'RiIcons.RiFileUserFill',
'RiIcons.RiFileUserLine',
'RiIcons.RiFileWarningFill',
'RiIcons.RiFileWarningLine',
'RiIcons.RiFileWord2Fill',
'RiIcons.RiFileWord2Line',
'RiIcons.RiFileWordFill',
'RiIcons.RiFileWordLine',
'RiIcons.RiFileZipFill',
'RiIcons.RiFileZipLine',
'RiIcons.RiFolder2Fill',
'RiIcons.RiFolder2Line',
'RiIcons.RiFolder3Fill',
'RiIcons.RiFolder3Line',
'RiIcons.RiFolder4Fill',
'RiIcons.RiFolder4Line',
'RiIcons.RiFolder5Fill',
'RiIcons.RiFolder5Line',
'RiIcons.RiFolderAddFill',
'RiIcons.RiFolderAddLine',
'RiIcons.RiFolderChart2Fill',
'RiIcons.RiFolderChart2Line',
'RiIcons.RiFolderChartFill',
'RiIcons.RiFolderChartLine',
'RiIcons.RiFolderDownloadFill',
'RiIcons.RiFolderDownloadLine',
'RiIcons.RiFolderFill',
'RiIcons.RiFolderForbidFill',
'RiIcons.RiFolderForbidLine',
'RiIcons.RiFolderHistoryFill',
'RiIcons.RiFolderHistoryLine',
'RiIcons.RiFolderInfoFill',
'RiIcons.RiFolderInfoLine',
'RiIcons.RiFolderKeyholeFill',
'RiIcons.RiFolderKeyholeLine',
'RiIcons.RiFolderLine',
'RiIcons.RiFolderLockFill',
'RiIcons.RiFolderLockLine',
'RiIcons.RiFolderMusicFill',
'RiIcons.RiFolderMusicLine',
'RiIcons.RiFolderOpenFill',
'RiIcons.RiFolderOpenLine',
'RiIcons.RiFolderReceivedFill',
'RiIcons.RiFolderReceivedLine',
'RiIcons.RiFolderReduceFill',
'RiIcons.RiFolderReduceLine',
'RiIcons.RiFolderSettingsFill',
'RiIcons.RiFolderSettingsLine',
'RiIcons.RiFolderSharedFill',
'RiIcons.RiFolderSharedLine',
'RiIcons.RiFolderShield2Fill',
'RiIcons.RiFolderShield2Line',
'RiIcons.RiFolderShieldFill',
'RiIcons.RiFolderShieldLine',
'RiIcons.RiFolderTransferFill',
'RiIcons.RiFolderTransferLine',
'RiIcons.RiFolderUnknowFill',
'RiIcons.RiFolderUnknowLine',
'RiIcons.RiFolderUploadFill',
'RiIcons.RiFolderUploadLine',
'RiIcons.RiFolderUserFill',
'RiIcons.RiFolderUserLine',
'RiIcons.RiFolderWarningFill',
'RiIcons.RiFolderWarningLine',
'RiIcons.RiFolderZipFill',
'RiIcons.RiFolderZipLine',
'RiIcons.RiFoldersFill',
'RiIcons.RiFoldersLine',
'RiIcons.RiKeynoteFill',
'RiIcons.RiKeynoteLine',
'RiIcons.RiMarkdownFill',
'RiIcons.RiMarkdownLine',
'RiIcons.RiNewspaperFill',
'RiIcons.RiNewspaperLine',
'RiIcons.RiNumbersFill',
'RiIcons.RiNumbersLine',
'RiIcons.RiPagesFill',
'RiIcons.RiPagesLine',
'RiIcons.RiStickyNote2Fill',
'RiIcons.RiStickyNote2Line',
'RiIcons.RiStickyNoteFill',
'RiIcons.RiStickyNoteLine',
'RiIcons.RiSurveyFill',
'RiIcons.RiSurveyLine',
'RiIcons.RiTaskFill',
'RiIcons.RiTaskLine',
'RiIcons.RiTodoFill',
'RiIcons.RiTodoLine',
'RiIcons.RiAB',
'RiIcons.RiAlignBottom',
'RiIcons.RiAlignCenter',
'RiIcons.RiAlignJustify',
'RiIcons.RiAlignLeft',
'RiIcons.RiAlignRight',
'RiIcons.RiAlignTop',
'RiIcons.RiAlignVertically',
'RiIcons.RiAsterisk',
'RiIcons.RiAttachment2',
'RiIcons.RiBold',
'RiIcons.RiBringForward',
'RiIcons.RiBringToFront',
'RiIcons.RiCodeView',
'RiIcons.RiDeleteColumn',
'RiIcons.RiDeleteRow',
'RiIcons.RiDoubleQuotesL',
'RiIcons.RiDoubleQuotesR',
'RiIcons.RiEmphasisCn',
'RiIcons.RiEmphasis',
'RiIcons.RiEnglishInput',
'RiIcons.RiFlowChart',
'RiIcons.RiFontColor',
'RiIcons.RiFontSize2',
'RiIcons.RiFontSize',
'RiIcons.RiFormatClear',
'RiIcons.RiFunctions',
'RiIcons.RiH1',
'RiIcons.RiH2',
'RiIcons.RiH3',
'RiIcons.RiH4',
'RiIcons.RiH5',
'RiIcons.RiH6',
'RiIcons.RiHashtag',
'RiIcons.RiHeading',
'RiIcons.RiIndentDecrease',
'RiIcons.RiIndentIncrease',
'RiIcons.RiInputCursorMove',
'RiIcons.RiInsertColumnLeft',
'RiIcons.RiInsertColumnRight',
'RiIcons.RiInsertRowBottom',
'RiIcons.RiInsertRowTop',
'RiIcons.RiItalic',
'RiIcons.RiLineHeight',
'RiIcons.RiLinkM',
'RiIcons.RiLinkUnlinkM',
'RiIcons.RiLinkUnlink',
'RiIcons.RiLink',
'RiIcons.RiListCheck2',
'RiIcons.RiListCheck',
'RiIcons.RiListOrdered',
'RiIcons.RiListUnordered',
'RiIcons.RiMergeCellsHorizontal',
'RiIcons.RiMergeCellsVertical',
'RiIcons.RiMindMap',
'RiIcons.RiNodeTree',
'RiIcons.RiNumber0',
'RiIcons.RiNumber1',
'RiIcons.RiNumber2',
'RiIcons.RiNumber3',
'RiIcons.RiNumber4',
'RiIcons.RiNumber5',
'RiIcons.RiNumber6',
'RiIcons.RiNumber7',
'RiIcons.RiNumber8',
'RiIcons.RiNumber9',
'RiIcons.RiOmega',
'RiIcons.RiOrganizationChart',
'RiIcons.RiPageSeparator',
'RiIcons.RiParagraph',
'RiIcons.RiPinyinInput',
'RiIcons.RiQuestionMark',
'RiIcons.RiRoundedCorner',
'RiIcons.RiSendBackward',
'RiIcons.RiSendToBack',
'RiIcons.RiSeparator',
'RiIcons.RiSingleQuotesL',
'RiIcons.RiSingleQuotesR',
'RiIcons.RiSortAsc',
'RiIcons.RiSortDesc',
'RiIcons.RiSpace',
'RiIcons.RiSplitCellsHorizontal',
'RiIcons.RiSplitCellsVertical',
'RiIcons.RiStrikethrough2',
'RiIcons.RiStrikethrough',
'RiIcons.RiSubscript2',
'RiIcons.RiSubscript',
'RiIcons.RiSuperscript2',
'RiIcons.RiSuperscript',
'RiIcons.RiTable2',
'RiIcons.RiTextDirectionL',
'RiIcons.RiTextDirectionR',
'RiIcons.RiTextSpacing',
'RiIcons.RiTextWrap',
'RiIcons.RiText',
'RiIcons.RiTranslate2',
'RiIcons.RiTranslate',
'RiIcons.RiUnderline',
'RiIcons.RiWubiInput',
'RiIcons.Ri24HoursFill',
'RiIcons.Ri24HoursLine',
'RiIcons.RiAuctionFill',
'RiIcons.RiAuctionLine',
'RiIcons.RiBankCard2Fill',
'RiIcons.RiBankCard2Line',
'RiIcons.RiBankCardFill',
'RiIcons.RiBankCardLine',
'RiIcons.RiBitCoinFill',
'RiIcons.RiBitCoinLine',
'RiIcons.RiCoinFill',
'RiIcons.RiCoinLine',
'RiIcons.RiCoinsFill',
'RiIcons.RiCoinsLine',
'RiIcons.RiCopperCoinFill',
'RiIcons.RiCopperCoinLine',
'RiIcons.RiCopperDiamondFill',
'RiIcons.RiCopperDiamondLine',
'RiIcons.RiCoupon2Fill',
'RiIcons.RiCoupon2Line',
'RiIcons.RiCoupon3Fill',
'RiIcons.RiCoupon3Line',
'RiIcons.RiCoupon4Fill',
'RiIcons.RiCoupon4Line',
'RiIcons.RiCoupon5Fill',
'RiIcons.RiCoupon5Line',
'RiIcons.RiCouponFill',
'RiIcons.RiCouponLine',
'RiIcons.RiCurrencyFill',
'RiIcons.RiCurrencyLine',
'RiIcons.RiExchangeBoxFill',
'RiIcons.RiExchangeBoxLine',
'RiIcons.RiExchangeCnyFill',
'RiIcons.RiExchangeCnyLine',
'RiIcons.RiExchangeDollarFill',
'RiIcons.RiExchangeDollarLine',
'RiIcons.RiExchangeFill',
'RiIcons.RiExchangeFundsFill',
'RiIcons.RiExchangeFundsLine',
'RiIcons.RiExchangeLine',
'RiIcons.RiFundsBoxFill',
'RiIcons.RiFundsBoxLine',
'RiIcons.RiFundsFill',
'RiIcons.RiFundsLine',
'RiIcons.RiGift2Fill',
'RiIcons.RiGift2Line',
'RiIcons.RiGiftFill',
'RiIcons.RiGiftLine',
'RiIcons.RiHandCoinFill',
'RiIcons.RiHandCoinLine',
'RiIcons.RiHandHeartFill',
'RiIcons.RiHandHeartLine',
'RiIcons.RiIncreaseDecreaseFill',
'RiIcons.RiIncreaseDecreaseLine',
'RiIcons.RiMoneyCnyBoxFill',
'RiIcons.RiMoneyCnyBoxLine',
'RiIcons.RiMoneyCnyCircleFill',
'RiIcons.RiMoneyCnyCircleLine',
'RiIcons.RiMoneyDollarBoxFill',
'RiIcons.RiMoneyDollarBoxLine',
'RiIcons.RiMoneyDollarCircleFill',
'RiIcons.RiMoneyDollarCircleLine',
'RiIcons.RiMoneyEuroBoxFill',
'RiIcons.RiMoneyEuroBoxLine',
'RiIcons.RiMoneyEuroCircleFill',
'RiIcons.RiMoneyEuroCircleLine',
'RiIcons.RiMoneyPoundBoxFill',
'RiIcons.RiMoneyPoundBoxLine',
'RiIcons.RiMoneyPoundCircleFill',
'RiIcons.RiMoneyPoundCircleLine',
'RiIcons.RiPercentFill',
'RiIcons.RiPercentLine',
'RiIcons.RiPriceTag2Fill',
'RiIcons.RiPriceTag2Line',
'RiIcons.RiPriceTag3Fill',
'RiIcons.RiPriceTag3Line',
'RiIcons.RiPriceTagFill',
'RiIcons.RiPriceTagLine',
'RiIcons.RiRedPacketFill',
'RiIcons.RiRedPacketLine',
'RiIcons.RiRefund2Fill',
'RiIcons.RiRefund2Line',
'RiIcons.RiRefundFill',
'RiIcons.RiRefundLine',
'RiIcons.RiSafe2Fill',
'RiIcons.RiSafe2Line',
'RiIcons.RiSafeFill',
'RiIcons.RiSafeLine',
'RiIcons.RiSecurePaymentFill',
'RiIcons.RiSecurePaymentLine',
'RiIcons.RiShoppingBag2Fill',
'RiIcons.RiShoppingBag2Line',
'RiIcons.RiShoppingBag3Fill',
'RiIcons.RiShoppingBag3Line',
'RiIcons.RiShoppingBagFill',
'RiIcons.RiShoppingBagLine',
'RiIcons.RiShoppingBasket2Fill',
'RiIcons.RiShoppingBasket2Line',
'RiIcons.RiShoppingBasketFill',
'RiIcons.RiShoppingBasketLine',
'RiIcons.RiShoppingCart2Fill',
'RiIcons.RiShoppingCart2Line',
'RiIcons.RiShoppingCartFill',
'RiIcons.RiShoppingCartLine',
'RiIcons.RiStockFill',
'RiIcons.RiStockLine',
'RiIcons.RiSwapBoxFill',
'RiIcons.RiSwapBoxLine',
'RiIcons.RiSwapFill',
'RiIcons.RiSwapLine',
'RiIcons.RiTicket2Fill',
'RiIcons.RiTicket2Line',
'RiIcons.RiTicketFill',
'RiIcons.RiTicketLine',
'RiIcons.RiTrophyFill',
'RiIcons.RiTrophyLine',
'RiIcons.RiVipCrown2Fill',
'RiIcons.RiVipCrown2Line',
'RiIcons.RiVipCrownFill',
'RiIcons.RiVipCrownLine',
'RiIcons.RiVipDiamondFill',
'RiIcons.RiVipDiamondLine',
'RiIcons.RiVipFill',
'RiIcons.RiVipLine',
'RiIcons.RiWallet2Fill',
'RiIcons.RiWallet2Line',
'RiIcons.RiWallet3Fill',
'RiIcons.RiWallet3Line',
'RiIcons.RiWalletFill',
'RiIcons.RiWalletLine',
'RiIcons.RiWaterFlashFill',
'RiIcons.RiWaterFlashLine',
'RiIcons.RiCapsuleFill',
'RiIcons.RiCapsuleLine',
'RiIcons.RiDislikeFill',
'RiIcons.RiDislikeLine',
'RiIcons.RiDossierFill',
'RiIcons.RiDossierLine',
'RiIcons.RiEmpathizeFill',
'RiIcons.RiEmpathizeLine',
'RiIcons.RiFirstAidKitFill',
'RiIcons.RiFirstAidKitLine',
'RiIcons.RiFlaskFill',
'RiIcons.RiFlaskLine',
'RiIcons.RiHandSanitizerFill',
'RiIcons.RiHandSanitizerLine',
'RiIcons.RiHealthBookFill',
'RiIcons.RiHealthBookLine',
'RiIcons.RiHeart2Fill',
'RiIcons.RiHeart2Line',
'RiIcons.RiHeart3Fill',
'RiIcons.RiHeart3Line',
'RiIcons.RiHeartAddFill',
'RiIcons.RiHeartAddLine',
'RiIcons.RiHeartFill',
'RiIcons.RiHeartLine',
'RiIcons.RiHeartPulseFill',
'RiIcons.RiHeartPulseLine',
'RiIcons.RiHeartsFill',
'RiIcons.RiHeartsLine',
'RiIcons.RiInfraredThermometerFill',
'RiIcons.RiInfraredThermometerLine',
'RiIcons.RiLungsFill',
'RiIcons.RiLungsLine',
'RiIcons.RiMedicineBottleFill',
'RiIcons.RiMedicineBottleLine',
'RiIcons.RiMentalHealthFill',
'RiIcons.RiMentalHealthLine',
'RiIcons.RiMicroscopeFill',
'RiIcons.RiMicroscopeLine',
'RiIcons.RiNurseFill',
'RiIcons.RiNurseLine',
'RiIcons.RiPsychotherapyFill',
'RiIcons.RiPsychotherapyLine',
'RiIcons.RiPulseFill',
'RiIcons.RiPulseLine',
'RiIcons.RiRestTimeFill',
'RiIcons.RiRestTimeLine',
'RiIcons.RiStethoscopeFill',
'RiIcons.RiStethoscopeLine',
'RiIcons.RiSurgicalMaskFill',
'RiIcons.RiSurgicalMaskLine',
'RiIcons.RiSyringeFill',
'RiIcons.RiSyringeLine',
'RiIcons.RiTestTubeFill',
'RiIcons.RiTestTubeLine',
'RiIcons.RiThermometerFill',
'RiIcons.RiThermometerLine',
'RiIcons.RiVirusFill',
'RiIcons.RiVirusLine',
'RiIcons.RiZzzFill',
'RiIcons.RiZzzLine',
'RiIcons.RiAlipayFill',
'RiIcons.RiAlipayLine',
'RiIcons.RiAmazonFill',
'RiIcons.RiAmazonLine',
'RiIcons.RiAndroidFill',
'RiIcons.RiAndroidLine',
'RiIcons.RiAngularjsFill',
'RiIcons.RiAngularjsLine',
'RiIcons.RiAppStoreFill',
'RiIcons.RiAppStoreLine',
'RiIcons.RiAppleFill',
'RiIcons.RiAppleLine',
'RiIcons.RiBaiduFill',
'RiIcons.RiBaiduLine',
'RiIcons.RiBehanceFill',
'RiIcons.RiBehanceLine',
'RiIcons.RiBilibiliFill',
'RiIcons.RiBilibiliLine',
'RiIcons.RiCentosFill',
'RiIcons.RiCentosLine',
'RiIcons.RiChromeFill',
'RiIcons.RiChromeLine',
'RiIcons.RiCodepenFill',
'RiIcons.RiCodepenLine',
'RiIcons.RiCoreosFill',
'RiIcons.RiCoreosLine',
'RiIcons.RiDingdingFill',
'RiIcons.RiDingdingLine',
'RiIcons.RiDiscordFill',
'RiIcons.RiDiscordLine',
'RiIcons.RiDisqusFill',
'RiIcons.RiDisqusLine',
'RiIcons.RiDoubanFill',
'RiIcons.RiDoubanLine',
'RiIcons.RiDribbbleFill',
'RiIcons.RiDribbbleLine',
'RiIcons.RiDriveFill',
'RiIcons.RiDriveLine',
'RiIcons.RiDropboxFill',
'RiIcons.RiDropboxLine',
'RiIcons.RiEdgeFill',
'RiIcons.RiEdgeLine',
'RiIcons.RiEvernoteFill',
'RiIcons.RiEvernoteLine',
'RiIcons.RiFacebookBoxFill',
'RiIcons.RiFacebookBoxLine',
'RiIcons.RiFacebookCircleFill',
'RiIcons.RiFacebookCircleLine',
'RiIcons.RiFacebookFill',
'RiIcons.RiFacebookLine',
'RiIcons.RiFinderFill',
'RiIcons.RiFinderLine',
'RiIcons.RiFirefoxFill',
'RiIcons.RiFirefoxLine',
'RiIcons.RiFlutterFill',
'RiIcons.RiFlutterLine',
'RiIcons.RiGatsbyFill',
'RiIcons.RiGatsbyLine',
'RiIcons.RiGithubFill',
'RiIcons.RiGithubLine',
'RiIcons.RiGitlabFill',
'RiIcons.RiGitlabLine',
'RiIcons.RiGoogleFill',
'RiIcons.RiGoogleLine',
'RiIcons.RiGooglePlayFill',
'RiIcons.RiGooglePlayLine',
'RiIcons.RiHonorOfKingsFill',
'RiIcons.RiHonorOfKingsLine',
'RiIcons.RiIeFill',
'RiIcons.RiIeLine',
'RiIcons.RiInstagramFill',
'RiIcons.RiInstagramLine',
'RiIcons.RiInvisionFill',
'RiIcons.RiInvisionLine',
'RiIcons.RiKakaoTalkFill',
'RiIcons.RiKakaoTalkLine',
'RiIcons.RiLineFill',
'RiIcons.RiLineLine',
'RiIcons.RiLinkedinBoxFill',
'RiIcons.RiLinkedinBoxLine',
'RiIcons.RiLinkedinFill',
'RiIcons.RiLinkedinLine',
'RiIcons.RiMastercardFill',
'RiIcons.RiMastercardLine',
'RiIcons.RiMastodonFill',
'RiIcons.RiMastodonLine',
'RiIcons.RiMediumFill',
'RiIcons.RiMediumLine',
'RiIcons.RiMessengerFill',
'RiIcons.RiMessengerLine',
'RiIcons.RiMicrosoftFill',
'RiIcons.RiMicrosoftLine',
'RiIcons.RiMiniProgramFill',
'RiIcons.RiMiniProgramLine',
'RiIcons.RiNeteaseCloudMusicFill',
'RiIcons.RiNeteaseCloudMusicLine',
'RiIcons.RiNetflixFill',
'RiIcons.RiNetflixLine',
'RiIcons.RiNpmjsFill',
'RiIcons.RiNpmjsLine',
'RiIcons.RiOpenSourceFill',
'RiIcons.RiOpenSourceLine',
'RiIcons.RiOperaFill',
'RiIcons.RiOperaLine',
'RiIcons.RiPatreonFill',
'RiIcons.RiPatreonLine',
'RiIcons.RiPaypalFill',
'RiIcons.RiPaypalLine',
'RiIcons.RiPinterestFill',
'RiIcons.RiPinterestLine',
'RiIcons.RiPixelfedFill',
'RiIcons.RiPixelfedLine',
'RiIcons.RiPlaystationFill',
'RiIcons.RiPlaystationLine',
'RiIcons.RiProductHuntFill',
'RiIcons.RiProductHuntLine',
'RiIcons.RiQqFill',
'RiIcons.RiQqLine',
'RiIcons.RiReactjsFill',
'RiIcons.RiReactjsLine',
'RiIcons.RiRedditFill',
'RiIcons.RiRedditLine',
'RiIcons.RiRemixiconFill',
'RiIcons.RiRemixiconLine',
'RiIcons.RiSafariFill',
'RiIcons.RiSafariLine',
'RiIcons.RiSkypeFill',
'RiIcons.RiSkypeLine',
'RiIcons.RiSlackFill',
'RiIcons.RiSlackLine',
'RiIcons.RiSnapchatFill',
'RiIcons.RiSnapchatLine',
'RiIcons.RiSoundcloudFill',
'RiIcons.RiSoundcloudLine',
'RiIcons.RiSpectrumFill',
'RiIcons.RiSpectrumLine',
'RiIcons.RiSpotifyFill',
'RiIcons.RiSpotifyLine',
'RiIcons.RiStackOverflowFill',
'RiIcons.RiStackOverflowLine',
'RiIcons.RiStackshareFill',
'RiIcons.RiStackshareLine',
'RiIcons.RiSteamFill',
'RiIcons.RiSteamLine',
'RiIcons.RiSwitchFill',
'RiIcons.RiSwitchLine',
'RiIcons.RiTaobaoFill',
'RiIcons.RiTaobaoLine',
'RiIcons.RiTelegramFill',
'RiIcons.RiTelegramLine',
'RiIcons.RiTrelloFill',
'RiIcons.RiTrelloLine',
'RiIcons.RiTumblrFill',
'RiIcons.RiTumblrLine',
'RiIcons.RiTwitchFill',
'RiIcons.RiTwitchLine',
'RiIcons.RiTwitterFill',
'RiIcons.RiTwitterLine',
'RiIcons.RiUbuntuFill',
'RiIcons.RiUbuntuLine',
'RiIcons.RiUnsplashFill',
'RiIcons.RiUnsplashLine',
'RiIcons.RiVimeoFill',
'RiIcons.RiVimeoLine',
'RiIcons.RiVisaFill',
'RiIcons.RiVisaLine',
'RiIcons.RiVuejsFill',
'RiIcons.RiVuejsLine',
'RiIcons.RiWechat2Fill',
'RiIcons.RiWechat2Line',
'RiIcons.RiWechatFill',
'RiIcons.RiWechatLine',
'RiIcons.RiWechatPayFill',
'RiIcons.RiWechatPayLine',
'RiIcons.RiWeiboFill',
'RiIcons.RiWeiboLine',
'RiIcons.RiWhatsappFill',
'RiIcons.RiWhatsappLine',
'RiIcons.RiWindowsFill',
'RiIcons.RiWindowsLine',
'RiIcons.RiXboxFill',
'RiIcons.RiXboxLine',
'RiIcons.RiXingFill',
'RiIcons.RiXingLine',
'RiIcons.RiYoutubeFill',
'RiIcons.RiYoutubeLine',
'RiIcons.RiZcoolFill',
'RiIcons.RiZcoolLine',
'RiIcons.RiZhihuFill',
'RiIcons.RiZhihuLine',
'RiIcons.RiAnchorFill',
'RiIcons.RiAnchorLine',
'RiIcons.RiBarricadeFill',
'RiIcons.RiBarricadeLine',
'RiIcons.RiBikeFill',
'RiIcons.RiBikeLine',
'RiIcons.RiBus2Fill',
'RiIcons.RiBus2Line',
'RiIcons.RiBusFill',
'RiIcons.RiBusLine',
'RiIcons.RiBusWifiFill',
'RiIcons.RiBusWifiLine',
'RiIcons.RiCarFill',
'RiIcons.RiCarLine',
'RiIcons.RiCarWashingFill',
'RiIcons.RiCarWashingLine',
'RiIcons.RiCaravanFill',
'RiIcons.RiCaravanLine',
'RiIcons.RiChargingPile2Fill',
'RiIcons.RiChargingPile2Line',
'RiIcons.RiChargingPileFill',
'RiIcons.RiChargingPileLine',
'RiIcons.RiChinaRailwayFill',
'RiIcons.RiChinaRailwayLine',
'RiIcons.RiCompass2Fill',
'RiIcons.RiCompass2Line',
'RiIcons.RiCompass3Fill',
'RiIcons.RiCompass3Line',
'RiIcons.RiCompass4Fill',
'RiIcons.RiCompass4Line',
'RiIcons.RiCompassDiscoverFill',
'RiIcons.RiCompassDiscoverLine',
'RiIcons.RiCompassFill',
'RiIcons.RiCompassLine',
'RiIcons.RiCupFill',
'RiIcons.RiCupLine',
'RiIcons.RiDirectionFill',
'RiIcons.RiDirectionLine',
'RiIcons.RiEBike2Fill',
'RiIcons.RiEBike2Line',
'RiIcons.RiEBikeFill',
'RiIcons.RiEBikeLine',
'RiIcons.RiEarthFill',
'RiIcons.RiEarthLine',
'RiIcons.RiFlightLandFill',
'RiIcons.RiFlightLandLine',
'RiIcons.RiFlightTakeoffFill',
'RiIcons.RiFlightTakeoffLine',
'RiIcons.RiFootprintFill',
'RiIcons.RiFootprintLine',
'RiIcons.RiGasStationFill',
'RiIcons.RiGasStationLine',
'RiIcons.RiGlobeFill',
'RiIcons.RiGlobeLine',
'RiIcons.RiGobletFill',
'RiIcons.RiGobletLine',
'RiIcons.RiGuideFill',
'RiIcons.RiGuideLine',
'RiIcons.RiHotelBedFill',
'RiIcons.RiHotelBedLine',
'RiIcons.RiLifebuoyFill',
'RiIcons.RiLifebuoyLine',
'RiIcons.RiLuggageCartFill',
'RiIcons.RiLuggageCartLine',
'RiIcons.RiLuggageDepositFill',
'RiIcons.RiLuggageDepositLine',
'RiIcons.RiMap2Fill',
'RiIcons.RiMap2Line',
'RiIcons.RiMapFill',
'RiIcons.RiMapLine',
'RiIcons.RiMapPin2Fill',
'RiIcons.RiMapPin2Line',
'RiIcons.RiMapPin3Fill',
'RiIcons.RiMapPin3Line',
'RiIcons.RiMapPin4Fill',
'RiIcons.RiMapPin4Line',
'RiIcons.RiMapPin5Fill',
'RiIcons.RiMapPin5Line',
'RiIcons.RiMapPinAddFill',
'RiIcons.RiMapPinAddLine',
'RiIcons.RiMapPinFill',
'RiIcons.RiMapPinLine',
'RiIcons.RiMapPinRangeFill',
'RiIcons.RiMapPinRangeLine',
'RiIcons.RiMapPinTimeFill',
'RiIcons.RiMapPinTimeLine',
'RiIcons.RiMapPinUserFill',
'RiIcons.RiMapPinUserLine',
'RiIcons.RiMotorbikeFill',
'RiIcons.RiMotorbikeLine',
'RiIcons.RiNavigationFill',
'RiIcons.RiNavigationLine',
'RiIcons.RiOilFill',
'RiIcons.RiOilLine',
'RiIcons.RiParkingBoxFill',
'RiIcons.RiParkingBoxLine',
'RiIcons.RiParkingFill',
'RiIcons.RiParkingLine',
'RiIcons.RiPassportFill',
'RiIcons.RiPassportLine',
'RiIcons.RiPinDistanceFill',
'RiIcons.RiPinDistanceLine',
'RiIcons.RiPlaneFill',
'RiIcons.RiPlaneLine',
'RiIcons.RiPoliceCarFill',
'RiIcons.RiPoliceCarLine',
'RiIcons.RiPushpin2Fill',
'RiIcons.RiPushpin2Line',
'RiIcons.RiPushpinFill',
'RiIcons.RiPushpinLine',
'RiIcons.RiRestaurant2Fill',
'RiIcons.RiRestaurant2Line',
'RiIcons.RiRestaurantFill',
'RiIcons.RiRestaurantLine',
'RiIcons.RiRidingFill',
'RiIcons.RiRidingLine',
'RiIcons.RiRoadMapFill',
'RiIcons.RiRoadMapLine',
'RiIcons.RiRoadsterFill',
'RiIcons.RiRoadsterLine',
'RiIcons.RiRocket2Fill',
'RiIcons.RiRocket2Line',
'RiIcons.RiRocketFill',
'RiIcons.RiRocketLine',
'RiIcons.RiRouteFill',
'RiIcons.RiRouteLine',
'RiIcons.RiRunFill',
'RiIcons.RiRunLine',
'RiIcons.RiSailboatFill',
'RiIcons.RiSailboatLine',
'RiIcons.RiShip2Fill',
'RiIcons.RiShip2Line',
'RiIcons.RiShipFill',
'RiIcons.RiShipLine',
'RiIcons.RiSignalTowerFill',
'RiIcons.RiSignalTowerLine',
'RiIcons.RiSpaceShipFill',
'RiIcons.RiSpaceShipLine',
'RiIcons.RiSteering2Fill',
'RiIcons.RiSteering2Line',
'RiIcons.RiSteeringFill',
'RiIcons.RiSteeringLine',
'RiIcons.RiSubwayFill',
'RiIcons.RiSubwayLine',
'RiIcons.RiSubwayWifiFill',
'RiIcons.RiSubwayWifiLine',
'RiIcons.RiSuitcase2Fill',
'RiIcons.RiSuitcase2Line',
'RiIcons.RiSuitcase3Fill',
'RiIcons.RiSuitcase3Line',
'RiIcons.RiSuitcaseFill',
'RiIcons.RiSuitcaseLine',
'RiIcons.RiTakeawayFill',
'RiIcons.RiTakeawayLine',
'RiIcons.RiTaxiFill',
'RiIcons.RiTaxiLine',
'RiIcons.RiTaxiWifiFill',
'RiIcons.RiTaxiWifiLine',
'RiIcons.RiTrafficLightFill',
'RiIcons.RiTrafficLightLine',
'RiIcons.RiTrainFill',
'RiIcons.RiTrainLine',
'RiIcons.RiTrainWifiFill',
'RiIcons.RiTrainWifiLine',
'RiIcons.RiTreasureMapFill',
'RiIcons.RiTreasureMapLine',
'RiIcons.RiTruckFill',
'RiIcons.RiTruckLine',
'RiIcons.RiWalkFill',
'RiIcons.RiWalkLine',
'RiIcons.Ri4KFill',
'RiIcons.Ri4KLine',
'RiIcons.RiAlbumFill',
'RiIcons.RiAlbumLine',
'RiIcons.RiAspectRatioFill',
'RiIcons.RiAspectRatioLine',
'RiIcons.RiBroadcastFill',
'RiIcons.RiBroadcastLine',
'RiIcons.RiCamera2Fill',
'RiIcons.RiCamera2Line',
'RiIcons.RiCamera3Fill',
'RiIcons.RiCamera3Line',
'RiIcons.RiCameraFill',
'RiIcons.RiCameraLensFill',
'RiIcons.RiCameraLensLine',
'RiIcons.RiCameraLine',
'RiIcons.RiCameraOffFill',
'RiIcons.RiCameraOffLine',
'RiIcons.RiCameraSwitchFill',
'RiIcons.RiCameraSwitchLine',
'RiIcons.RiClapperboardFill',
'RiIcons.RiClapperboardLine',
'RiIcons.RiClosedCaptioningFill',
'RiIcons.RiClosedCaptioningLine',
'RiIcons.RiDiscFill',
'RiIcons.RiDiscLine',
'RiIcons.RiDvFill',
'RiIcons.RiDvLine',
'RiIcons.RiDvdFill',
'RiIcons.RiDvdLine',
'RiIcons.RiEjectFill',
'RiIcons.RiEjectLine',
'RiIcons.RiEqualizerFill',
'RiIcons.RiEqualizerLine',
'RiIcons.RiFilmFill',
'RiIcons.RiFilmLine',
'RiIcons.RiFullscreenExitFill',
'RiIcons.RiFullscreenExitLine',
'RiIcons.RiFullscreenFill',
'RiIcons.RiFullscreenLine',
'RiIcons.RiGalleryFill',
'RiIcons.RiGalleryLine',
'RiIcons.RiGalleryUploadFill',
'RiIcons.RiGalleryUploadLine',
'RiIcons.RiHdFill',
'RiIcons.RiHdLine',
'RiIcons.RiHeadphoneFill',
'RiIcons.RiHeadphoneLine',
'RiIcons.RiHqFill',
'RiIcons.RiHqLine',
'RiIcons.RiImage2Fill',
'RiIcons.RiImage2Line',
'RiIcons.RiImageAddFill',
'RiIcons.RiImageAddLine',
'RiIcons.RiImageEditFill',
'RiIcons.RiImageEditLine',
'RiIcons.RiImageFill',
'RiIcons.RiImageLine',
'RiIcons.RiLandscapeFill',
'RiIcons.RiLandscapeLine',
'RiIcons.RiLiveFill',
'RiIcons.RiLiveLine',
'RiIcons.RiMic2Fill',
'RiIcons.RiMic2Line',
'RiIcons.RiMicFill',
'RiIcons.RiMicLine',
'RiIcons.RiMicOffFill',
'RiIcons.RiMicOffLine',
'RiIcons.RiMovie2Fill',
'RiIcons.RiMovie2Line',
'RiIcons.RiMovieFill',
'RiIcons.RiMovieLine',
'RiIcons.RiMusic2Fill',
'RiIcons.RiMusic2Line',
'RiIcons.RiMusicFill',
'RiIcons.RiMusicLine',
'RiIcons.RiMvFill',
'RiIcons.RiMvLine',
'RiIcons.RiNotification2Fill',
'RiIcons.RiNotification2Line',
'RiIcons.RiNotification3Fill',
'RiIcons.RiNotification3Line',
'RiIcons.RiNotification4Fill',
'RiIcons.RiNotification4Line',
'RiIcons.RiNotificationFill',
'RiIcons.RiNotificationLine',
'RiIcons.RiNotificationOffFill',
'RiIcons.RiNotificationOffLine',
'RiIcons.RiOrderPlayFill',
'RiIcons.RiOrderPlayLine',
'RiIcons.RiPauseCircleFill',
'RiIcons.RiPauseCircleLine',
'RiIcons.RiPauseFill',
'RiIcons.RiPauseLine',
'RiIcons.RiPauseMiniFill',
'RiIcons.RiPauseMiniLine',
'RiIcons.RiPhoneCameraFill',
'RiIcons.RiPhoneCameraLine',
'RiIcons.RiPictureInPicture2Fill',
'RiIcons.RiPictureInPicture2Line',
'RiIcons.RiPictureInPictureExitFill',
'RiIcons.RiPictureInPictureExitLine',
'RiIcons.RiPictureInPictureFill',
'RiIcons.RiPictureInPictureLine',
'RiIcons.RiPlayCircleFill',
'RiIcons.RiPlayCircleLine',
'RiIcons.RiPlayFill',
'RiIcons.RiPlayLine',
'RiIcons.RiPlayList2Fill',
'RiIcons.RiPlayList2Line',
'RiIcons.RiPlayListAddFill',
'RiIcons.RiPlayListAddLine',
'RiIcons.RiPlayListFill',
'RiIcons.RiPlayListLine',
'RiIcons.RiPlayMiniFill',
'RiIcons.RiPlayMiniLine',
'RiIcons.RiPolaroid2Fill',
'RiIcons.RiPolaroid2Line',
'RiIcons.RiPolaroidFill',
'RiIcons.RiPolaroidLine',
'RiIcons.RiRadio2Fill',
'RiIcons.RiRadio2Line',
'RiIcons.RiRadioFill',
'RiIcons.RiRadioLine',
'RiIcons.RiRecordCircleFill',
'RiIcons.RiRecordCircleLine',
'RiIcons.RiRepeat2Fill',
'RiIcons.RiRepeat2Line',
'RiIcons.RiRepeatFill',
'RiIcons.RiRepeatLine',
'RiIcons.RiRepeatOneFill',
'RiIcons.RiRepeatOneLine',
'RiIcons.RiRewindFill',
'RiIcons.RiRewindLine',
'RiIcons.RiRewindMiniFill',
'RiIcons.RiRewindMiniLine',
'RiIcons.RiRhythmFill',
'RiIcons.RiRhythmLine',
'RiIcons.RiShuffleFill',
'RiIcons.RiShuffleLine',
'RiIcons.RiSkipBackFill',
'RiIcons.RiSkipBackLine',
'RiIcons.RiSkipBackMiniFill',
'RiIcons.RiSkipBackMiniLine',
'RiIcons.RiSkipForwardFill',
'RiIcons.RiSkipForwardLine',
'RiIcons.RiSkipForwardMiniFill',
'RiIcons.RiSkipForwardMiniLine',
'RiIcons.RiSoundModuleFill',
'RiIcons.RiSoundModuleLine',
'RiIcons.RiSpeaker2Fill',
'RiIcons.RiSpeaker2Line',
'RiIcons.RiSpeaker3Fill',
'RiIcons.RiSpeaker3Line',
'RiIcons.RiSpeakerFill',
'RiIcons.RiSpeakerLine',
'RiIcons.RiSpeedFill',
'RiIcons.RiSpeedLine',
'RiIcons.RiSpeedMiniFill',
'RiIcons.RiSpeedMiniLine',
'RiIcons.RiStopCircleFill',
'RiIcons.RiStopCircleLine',
'RiIcons.RiStopFill',
'RiIcons.RiStopLine',
'RiIcons.RiStopMiniFill',
'RiIcons.RiStopMiniLine',
'RiIcons.RiSurroundSoundFill',
'RiIcons.RiSurroundSoundLine',
'RiIcons.RiTapeFill',
'RiIcons.RiTapeLine',
'RiIcons.RiVideoAddFill',
'RiIcons.RiVideoAddLine',
'RiIcons.RiVideoDownloadFill',
'RiIcons.RiVideoDownloadLine',
'RiIcons.RiVideoFill',
'RiIcons.RiVideoLine',
'RiIcons.RiVideoUploadFill',
'RiIcons.RiVideoUploadLine',
'RiIcons.RiVidicon2Fill',
'RiIcons.RiVidicon2Line',
'RiIcons.RiVidiconFill',
'RiIcons.RiVidiconLine',
'RiIcons.RiVoiceprintFill',
'RiIcons.RiVoiceprintLine',
'RiIcons.RiVolumeDownFill',
'RiIcons.RiVolumeDownLine',
'RiIcons.RiVolumeMuteFill',
'RiIcons.RiVolumeMuteLine',
'RiIcons.RiVolumeOffVibrateFill',
'RiIcons.RiVolumeOffVibrateLine',
'RiIcons.RiVolumeUpFill',
'RiIcons.RiVolumeUpLine',
'RiIcons.RiVolumeVibrateFill',
'RiIcons.RiVolumeVibrateLine',
'RiIcons.RiWebcamFill',
'RiIcons.RiWebcamLine',
'RiIcons.RiBasketballFill',
'RiIcons.RiBasketballLine',
'RiIcons.RiBellFill',
'RiIcons.RiBellLine',
'RiIcons.RiBilliardsFill',
'RiIcons.RiBilliardsLine',
'RiIcons.RiBoxingFill',
'RiIcons.RiBoxingLine',
'RiIcons.RiCactusFill',
'RiIcons.RiCactusLine',
'RiIcons.RiCake2Fill',
'RiIcons.RiCake2Line',
'RiIcons.RiCake3Fill',
'RiIcons.RiCake3Line',
'RiIcons.RiCakeFill',
'RiIcons.RiCakeLine',
'RiIcons.RiCharacterRecognitionFill',
'RiIcons.RiCharacterRecognitionLine',
'RiIcons.RiDoorClosedFill',
'RiIcons.RiDoorClosedLine',
'RiIcons.RiDoorFill',
'RiIcons.RiDoorLine',
'RiIcons.RiDoorLockBoxFill',
'RiIcons.RiDoorLockBoxLine',
'RiIcons.RiDoorLockFill',
'RiIcons.RiDoorLockLine',
'RiIcons.RiDoorOpenFill',
'RiIcons.RiDoorOpenLine',
'RiIcons.RiFootballFill',
'RiIcons.RiFootballLine',
'RiIcons.RiFridgeFill',
'RiIcons.RiFridgeLine',
'RiIcons.RiGameFill',
'RiIcons.RiGameLine',
'RiIcons.RiHandbagFill',
'RiIcons.RiHandbagLine',
'RiIcons.RiKey2Fill',
'RiIcons.RiKey2Line',
'RiIcons.RiKeyFill',
'RiIcons.RiKeyLine',
'RiIcons.RiKnifeBloodFill',
'RiIcons.RiKnifeBloodLine',
'RiIcons.RiKnifeFill',
'RiIcons.RiKnifeLine',
'RiIcons.RiLeafFill',
'RiIcons.RiLeafLine',
'RiIcons.RiLightbulbFill',
'RiIcons.RiLightbulbFlashFill',
'RiIcons.RiLightbulbFlashLine',
'RiIcons.RiLightbulbLine',
'RiIcons.RiOutlet2Fill',
'RiIcons.RiOutlet2Line',
'RiIcons.RiOutletFill',
'RiIcons.RiOutletLine',
'RiIcons.RiPingPongFill',
'RiIcons.RiPingPongLine',
'RiIcons.RiPlantFill',
'RiIcons.RiPlantLine',
'RiIcons.RiPlug2Fill',
'RiIcons.RiPlug2Line',
'RiIcons.RiPlugFill',
'RiIcons.RiPlugLine',
'RiIcons.RiRecycleFill',
'RiIcons.RiRecycleLine',
'RiIcons.RiReservedFill',
'RiIcons.RiReservedLine',
'RiIcons.RiScales2Fill',
'RiIcons.RiScales2Line',
'RiIcons.RiScales3Fill',
'RiIcons.RiScales3Line',
'RiIcons.RiScalesFill',
'RiIcons.RiScalesLine',
'RiIcons.RiSeedlingFill',
'RiIcons.RiSeedlingLine',
'RiIcons.RiShirtFill',
'RiIcons.RiShirtLine',
'RiIcons.RiSwordFill',
'RiIcons.RiSwordLine',
'RiIcons.RiTShirt2Fill',
'RiIcons.RiTShirt2Line',
'RiIcons.RiTShirtAirFill',
'RiIcons.RiTShirtAirLine',
'RiIcons.RiTShirtFill',
'RiIcons.RiTShirtLine',
'RiIcons.RiUmbrellaFill',
'RiIcons.RiUmbrellaLine',
'RiIcons.RiVoiceRecognitionFill',
'RiIcons.RiVoiceRecognitionLine',
'RiIcons.RiWheelchairFill',
'RiIcons.RiWheelchairLine',
'RiIcons.RiAddBoxFill',
'RiIcons.RiAddBoxLine',
'RiIcons.RiAddCircleFill',
'RiIcons.RiAddCircleLine',
'RiIcons.RiAddFill',
'RiIcons.RiAddLine',
'RiIcons.RiAlarmFill',
'RiIcons.RiAlarmLine',
'RiIcons.RiAlarmWarningFill',
'RiIcons.RiAlarmWarningLine',
'RiIcons.RiAlertFill',
'RiIcons.RiAlertLine',
'RiIcons.RiApps2Fill',
'RiIcons.RiApps2Line',
'RiIcons.RiAppsFill',
'RiIcons.RiAppsLine',
'RiIcons.RiArrowDownCircleFill',
'RiIcons.RiArrowDownCircleLine',
'RiIcons.RiArrowDownFill',
'RiIcons.RiArrowDownLine',
'RiIcons.RiArrowDownSFill',
'RiIcons.RiArrowDownSLine',
'RiIcons.RiArrowDropDownFill',
'RiIcons.RiArrowDropDownLine',
'RiIcons.RiArrowDropLeftFill',
'RiIcons.RiArrowDropLeftLine',
'RiIcons.RiArrowDropRightFill',
'RiIcons.RiArrowDropRightLine',
'RiIcons.RiArrowDropUpFill',
'RiIcons.RiArrowDropUpLine',
'RiIcons.RiArrowGoBackFill',
'RiIcons.RiArrowGoBackLine',
'RiIcons.RiArrowGoForwardFill',
'RiIcons.RiArrowGoForwardLine',
'RiIcons.RiArrowLeftCircleFill',
'RiIcons.RiArrowLeftCircleLine',
'RiIcons.RiArrowLeftDownFill',
'RiIcons.RiArrowLeftDownLine',
'RiIcons.RiArrowLeftFill',
'RiIcons.RiArrowLeftLine',
'RiIcons.RiArrowLeftRightFill',
'RiIcons.RiArrowLeftRightLine',
'RiIcons.RiArrowLeftSFill',
'RiIcons.RiArrowLeftSLine',
'RiIcons.RiArrowLeftUpFill',
'RiIcons.RiArrowLeftUpLine',
'RiIcons.RiArrowRightCircleFill',
'RiIcons.RiArrowRightCircleLine',
'RiIcons.RiArrowRightDownFill',
'RiIcons.RiArrowRightDownLine',
'RiIcons.RiArrowRightFill',
'RiIcons.RiArrowRightLine',
'RiIcons.RiArrowRightSFill',
'RiIcons.RiArrowRightSLine',
'RiIcons.RiArrowRightUpFill',
'RiIcons.RiArrowRightUpLine',
'RiIcons.RiArrowUpCircleFill',
'RiIcons.RiArrowUpCircleLine',
'RiIcons.RiArrowUpDownFill',
'RiIcons.RiArrowUpDownLine',
'RiIcons.RiArrowUpFill',
'RiIcons.RiArrowUpLine',
'RiIcons.RiArrowUpSFill',
'RiIcons.RiArrowUpSLine',
'RiIcons.RiCheckDoubleFill',
'RiIcons.RiCheckDoubleLine',
'RiIcons.RiCheckFill',
'RiIcons.RiCheckLine',
'RiIcons.RiCheckboxBlankCircleFill',
'RiIcons.RiCheckboxBlankCircleLine',
'RiIcons.RiCheckboxBlankFill',
'RiIcons.RiCheckboxBlankLine',
'RiIcons.RiCheckboxCircleFill',
'RiIcons.RiCheckboxCircleLine',
'RiIcons.RiCheckboxFill',
'RiIcons.RiCheckboxIndeterminateFill',
'RiIcons.RiCheckboxIndeterminateLine',
'RiIcons.RiCheckboxLine',
'RiIcons.RiCheckboxMultipleBlankFill',
'RiIcons.RiCheckboxMultipleBlankLine',
'RiIcons.RiCheckboxMultipleFill',
'RiIcons.RiCheckboxMultipleLine',
'RiIcons.RiCloseCircleFill',
'RiIcons.RiCloseCircleLine',
'RiIcons.RiCloseFill',
'RiIcons.RiCloseLine',
'RiIcons.RiDashboardFill',
'RiIcons.RiDashboardLine',
'RiIcons.RiDeleteBack2Fill',
'RiIcons.RiDeleteBack2Line',
'RiIcons.RiDeleteBackFill',
'RiIcons.RiDeleteBackLine',
'RiIcons.RiDeleteBin2Fill',
'RiIcons.RiDeleteBin2Line',
'RiIcons.RiDeleteBin3Fill',
'RiIcons.RiDeleteBin3Line',
'RiIcons.RiDeleteBin4Fill',
'RiIcons.RiDeleteBin4Line',
'RiIcons.RiDeleteBin5Fill',
'RiIcons.RiDeleteBin5Line',
'RiIcons.RiDeleteBin6Fill',
'RiIcons.RiDeleteBin6Line',
'RiIcons.RiDeleteBin7Fill',
'RiIcons.RiDeleteBin7Line',
'RiIcons.RiDeleteBinFill',
'RiIcons.RiDeleteBinLine',
'RiIcons.RiDivideFill',
'RiIcons.RiDivideLine',
'RiIcons.RiDownload2Fill',
'RiIcons.RiDownload2Line',
'RiIcons.RiDownloadCloud2Fill',
'RiIcons.RiDownloadCloud2Line',
'RiIcons.RiDownloadCloudFill',
'RiIcons.RiDownloadCloudLine',
'RiIcons.RiDownloadFill',
'RiIcons.RiDownloadLine',
'RiIcons.RiErrorWarningFill',
'RiIcons.RiErrorWarningLine',
'RiIcons.RiExternalLinkFill',
'RiIcons.RiExternalLinkLine',
'RiIcons.RiEye2Fill',
'RiIcons.RiEye2Line',
'RiIcons.RiEyeCloseFill',
'RiIcons.RiEyeCloseLine',
'RiIcons.RiEyeFill',
'RiIcons.RiEyeLine',
'RiIcons.RiEyeOffFill',
'RiIcons.RiEyeOffLine',
'RiIcons.RiFilter2Fill',
'RiIcons.RiFilter2Line',
'RiIcons.RiFilter3Fill',
'RiIcons.RiFilter3Line',
'RiIcons.RiFilterFill',
'RiIcons.RiFilterLine',
'RiIcons.RiFilterOffFill',
'RiIcons.RiFilterOffLine',
'RiIcons.RiFindReplaceFill',
'RiIcons.RiFindReplaceLine',
'RiIcons.RiForbid2Fill',
'RiIcons.RiForbid2Line',
'RiIcons.RiForbidFill',
'RiIcons.RiForbidLine',
'RiIcons.RiFunctionFill',
'RiIcons.RiFunctionLine',
'RiIcons.RiHistoryFill',
'RiIcons.RiHistoryLine',
'RiIcons.RiIndeterminateCircleFill',
'RiIcons.RiIndeterminateCircleLine',
'RiIcons.RiInformationFill',
'RiIcons.RiInformationLine',
'RiIcons.RiListSettingsFill',
'RiIcons.RiListSettingsLine',
'RiIcons.RiLoader2Fill',
'RiIcons.RiLoader2Line',
'RiIcons.RiLoader3Fill',
'RiIcons.RiLoader3Line',
'RiIcons.RiLoader4Fill',
'RiIcons.RiLoader4Line',
'RiIcons.RiLoader5Fill',
'RiIcons.RiLoader5Line',
'RiIcons.RiLoaderFill',
'RiIcons.RiLoaderLine',
'RiIcons.RiLock2Fill',
'RiIcons.RiLock2Line',
'RiIcons.RiLockFill',
'RiIcons.RiLockLine',
'RiIcons.RiLockPasswordFill',
'RiIcons.RiLockPasswordLine',
'RiIcons.RiLockUnlockFill',
'RiIcons.RiLockUnlockLine',
'RiIcons.RiLoginBoxFill',
'RiIcons.RiLoginBoxLine',
'RiIcons.RiLoginCircleFill',
'RiIcons.RiLoginCircleLine',
'RiIcons.RiLogoutBoxFill',
'RiIcons.RiLogoutBoxLine',
'RiIcons.RiLogoutBoxRFill',
'RiIcons.RiLogoutBoxRLine',
'RiIcons.RiLogoutCircleFill',
'RiIcons.RiLogoutCircleLine',
'RiIcons.RiLogoutCircleRFill',
'RiIcons.RiLogoutCircleRLine',
'RiIcons.RiMenu2Fill',
'RiIcons.RiMenu2Line',
'RiIcons.RiMenu3Fill',
'RiIcons.RiMenu3Line',
'RiIcons.RiMenu4Fill',
'RiIcons.RiMenu4Line',
'RiIcons.RiMenu5Fill',
'RiIcons.RiMenu5Line',
'RiIcons.RiMenuAddFill',
'RiIcons.RiMenuAddLine',
'RiIcons.RiMenuFill',
'RiIcons.RiMenuFoldFill',
'RiIcons.RiMenuFoldLine',
'RiIcons.RiMenuLine',
'RiIcons.RiMenuUnfoldFill',
'RiIcons.RiMenuUnfoldLine',
'RiIcons.RiMore2Fill',
'RiIcons.RiMore2Line',
'RiIcons.RiMoreFill',
'RiIcons.RiMoreLine',
'RiIcons.RiNotificationBadgeFill',
'RiIcons.RiNotificationBadgeLine',
'RiIcons.RiQuestionFill',
'RiIcons.RiQuestionLine',
'RiIcons.RiRadioButtonFill',
'RiIcons.RiRadioButtonLine',
'RiIcons.RiRefreshFill',
'RiIcons.RiRefreshLine',
'RiIcons.RiSearch2Fill',
'RiIcons.RiSearch2Line',
'RiIcons.RiSearchEyeFill',
'RiIcons.RiSearchEyeLine',
'RiIcons.RiSearchFill',
'RiIcons.RiSearchLine',
'RiIcons.RiSettings2Fill',
'RiIcons.RiSettings2Line',
'RiIcons.RiSettings3Fill',
'RiIcons.RiSettings3Line',
'RiIcons.RiSettings4Fill',
'RiIcons.RiSettings4Line',
'RiIcons.RiSettings5Fill',
'RiIcons.RiSettings5Line',
'RiIcons.RiSettings6Fill',
'RiIcons.RiSettings6Line',
'RiIcons.RiSettingsFill',
'RiIcons.RiSettingsLine',
'RiIcons.RiShareBoxFill',
'RiIcons.RiShareBoxLine',
'RiIcons.RiShareCircleFill',
'RiIcons.RiShareCircleLine',
'RiIcons.RiShareFill',
'RiIcons.RiShareForward2Fill',
'RiIcons.RiShareForward2Line',
'RiIcons.RiShareForwardBoxFill',
'RiIcons.RiShareForwardBoxLine',
'RiIcons.RiShareForwardFill',
'RiIcons.RiShareForwardLine',
'RiIcons.RiShareLine',
'RiIcons.RiShieldCheckFill',
'RiIcons.RiShieldCheckLine',
'RiIcons.RiShieldCrossFill',
'RiIcons.RiShieldCrossLine',
'RiIcons.RiShieldFill',
'RiIcons.RiShieldFlashFill',
'RiIcons.RiShieldFlashLine',
'RiIcons.RiShieldKeyholeFill',
'RiIcons.RiShieldKeyholeLine',
'RiIcons.RiShieldLine',
'RiIcons.RiShieldStarFill',
'RiIcons.RiShieldStarLine',
'RiIcons.RiShieldUserFill',
'RiIcons.RiShieldUserLine',
'RiIcons.RiSideBarFill',
'RiIcons.RiSideBarLine',
'RiIcons.RiSpam2Fill',
'RiIcons.RiSpam2Line',
'RiIcons.RiSpam3Fill',
'RiIcons.RiSpam3Line',
'RiIcons.RiSpamFill',
'RiIcons.RiSpamLine',
'RiIcons.RiStarFill',
'RiIcons.RiStarHalfFill',
'RiIcons.RiStarHalfLine',
'RiIcons.RiStarHalfSFill',
'RiIcons.RiStarHalfSLine',
'RiIcons.RiStarLine',
'RiIcons.RiStarSFill',
'RiIcons.RiStarSLine',
'RiIcons.RiSubtractFill',
'RiIcons.RiSubtractLine',
'RiIcons.RiThumbDownFill',
'RiIcons.RiThumbDownLine',
'RiIcons.RiThumbUpFill',
'RiIcons.RiThumbUpLine',
'RiIcons.RiTimeFill',
'RiIcons.RiTimeLine',
'RiIcons.RiTimer2Fill',
'RiIcons.RiTimer2Line',
'RiIcons.RiTimerFill',
'RiIcons.RiTimerFlashFill',
'RiIcons.RiTimerFlashLine',
'RiIcons.RiTimerLine',
'RiIcons.RiToggleFill',
'RiIcons.RiToggleLine',
'RiIcons.RiUpload2Fill',
'RiIcons.RiUpload2Line',
'RiIcons.RiUploadCloud2Fill',
'RiIcons.RiUploadCloud2Line',
'RiIcons.RiUploadCloudFill',
'RiIcons.RiUploadCloudLine',
'RiIcons.RiUploadFill',
'RiIcons.RiUploadLine',
'RiIcons.RiZoomInFill',
'RiIcons.RiZoomInLine',
'RiIcons.RiZoomOutFill',
'RiIcons.RiZoomOutLine',
'RiIcons.RiAccountBoxFill',
'RiIcons.RiAccountBoxLine',
'RiIcons.RiAccountCircleFill',
'RiIcons.RiAccountCircleLine',
'RiIcons.RiAccountPinBoxFill',
'RiIcons.RiAccountPinBoxLine',
'RiIcons.RiAccountPinCircleFill',
'RiIcons.RiAccountPinCircleLine',
'RiIcons.RiAdminFill',
'RiIcons.RiAdminLine',
'RiIcons.RiAliensFill',
'RiIcons.RiAliensLine',
'RiIcons.RiBearSmileFill',
'RiIcons.RiBearSmileLine',
'RiIcons.RiBodyScanFill',
'RiIcons.RiBodyScanLine',
'RiIcons.RiContactsFill',
'RiIcons.RiContactsLine',
'RiIcons.RiCriminalFill',
'RiIcons.RiCriminalLine',
'RiIcons.RiEmotion2Fill',
'RiIcons.RiEmotion2Line',
'RiIcons.RiEmotionFill',
'RiIcons.RiEmotionHappyFill',
'RiIcons.RiEmotionHappyLine',
'RiIcons.RiEmotionLaughFill',
'RiIcons.RiEmotionLaughLine',
'RiIcons.RiEmotionLine',
'RiIcons.RiEmotionNormalFill',
'RiIcons.RiEmotionNormalLine',
'RiIcons.RiEmotionSadFill',
'RiIcons.RiEmotionSadLine',
'RiIcons.RiEmotionUnhappyFill',
'RiIcons.RiEmotionUnhappyLine',
'RiIcons.RiGenderlessFill',
'RiIcons.RiGenderlessLine',
'RiIcons.RiGhost2Fill',
'RiIcons.RiGhost2Line',
'RiIcons.RiGhostFill',
'RiIcons.RiGhostLine',
'RiIcons.RiGhostSmileFill',
'RiIcons.RiGhostSmileLine',
'RiIcons.RiGroup2Fill',
'RiIcons.RiGroup2Line',
'RiIcons.RiGroupFill',
'RiIcons.RiGroupLine',
'RiIcons.RiMenFill',
'RiIcons.RiMenLine',
'RiIcons.RiMickeyFill',
'RiIcons.RiMickeyLine',
'RiIcons.RiOpenArmFill',
'RiIcons.RiOpenArmLine',
'RiIcons.RiParentFill',
'RiIcons.RiParentLine',
'RiIcons.RiRobotFill',
'RiIcons.RiRobotLine',
'RiIcons.RiSkull2Fill',
'RiIcons.RiSkull2Line',
'RiIcons.RiSkullFill',
'RiIcons.RiSkullLine',
'RiIcons.RiSpyFill',
'RiIcons.RiSpyLine',
'RiIcons.RiStarSmileFill',
'RiIcons.RiStarSmileLine',
'RiIcons.RiTeamFill',
'RiIcons.RiTeamLine',
'RiIcons.RiTravestiFill',
'RiIcons.RiTravestiLine',
'RiIcons.RiUser2Fill',
'RiIcons.RiUser2Line',
'RiIcons.RiUser3Fill',
'RiIcons.RiUser3Line',
'RiIcons.RiUser4Fill',
'RiIcons.RiUser4Line',
'RiIcons.RiUser5Fill',
'RiIcons.RiUser5Line',
'RiIcons.RiUser6Fill',
'RiIcons.RiUser6Line',
'RiIcons.RiUserAddFill',
'RiIcons.RiUserAddLine',
'RiIcons.RiUserFill',
'RiIcons.RiUserFollowFill',
'RiIcons.RiUserFollowLine',
'RiIcons.RiUserHeartFill',
'RiIcons.RiUserHeartLine',
'RiIcons.RiUserLine',
'RiIcons.RiUserLocationFill',
'RiIcons.RiUserLocationLine',
'RiIcons.RiUserReceived2Fill',
'RiIcons.RiUserReceived2Line',
'RiIcons.RiUserReceivedFill',
'RiIcons.RiUserReceivedLine',
'RiIcons.RiUserSearchFill',
'RiIcons.RiUserSearchLine',
'RiIcons.RiUserSettingsFill',
'RiIcons.RiUserSettingsLine',
'RiIcons.RiUserShared2Fill',
'RiIcons.RiUserShared2Line',
'RiIcons.RiUserSharedFill',
'RiIcons.RiUserSharedLine',
'RiIcons.RiUserSmileFill',
'RiIcons.RiUserSmileLine',
'RiIcons.RiUserStarFill',
'RiIcons.RiUserStarLine',
'RiIcons.RiUserUnfollowFill',
'RiIcons.RiUserUnfollowLine',
'RiIcons.RiUserVoiceFill',
'RiIcons.RiUserVoiceLine',
'RiIcons.RiWomenFill',
'RiIcons.RiWomenLine',
'RiIcons.RiBlazeFill',
'RiIcons.RiBlazeLine',
'RiIcons.RiCelsiusFill',
'RiIcons.RiCelsiusLine',
'RiIcons.RiCloudWindyFill',
'RiIcons.RiCloudWindyLine',
'RiIcons.RiCloudy2Fill',
'RiIcons.RiCloudy2Line',
'RiIcons.RiCloudyFill',
'RiIcons.RiCloudyLine',
'RiIcons.RiDrizzleFill',
'RiIcons.RiDrizzleLine',
'RiIcons.RiEarthquakeFill',
'RiIcons.RiEarthquakeLine',
'RiIcons.RiFahrenheitFill',
'RiIcons.RiFahrenheitLine',
'RiIcons.RiFireFill',
'RiIcons.RiFireLine',
'RiIcons.RiFlashlightFill',
'RiIcons.RiFlashlightLine',
'RiIcons.RiFloodFill',
'RiIcons.RiFloodLine',
'RiIcons.RiFoggyFill',
'RiIcons.RiFoggyLine',
'RiIcons.RiHailFill',
'RiIcons.RiHailLine',
'RiIcons.RiHaze2Fill',
'RiIcons.RiHaze2Line',
'RiIcons.RiHazeFill',
'RiIcons.RiHazeLine',
'RiIcons.RiHeavyShowersFill',
'RiIcons.RiHeavyShowersLine',
'RiIcons.RiMeteorFill',
'RiIcons.RiMeteorLine',
'RiIcons.RiMistFill',
'RiIcons.RiMistLine',
'RiIcons.RiMoonClearFill',
'RiIcons.RiMoonClearLine',
'RiIcons.RiMoonCloudyFill',
'RiIcons.RiMoonCloudyLine',
'RiIcons.RiMoonFill',
'RiIcons.RiMoonFoggyFill',
'RiIcons.RiMoonFoggyLine',
'RiIcons.RiMoonLine',
'RiIcons.RiRainbowFill',
'RiIcons.RiRainbowLine',
'RiIcons.RiRainyFill',
'RiIcons.RiRainyLine',
'RiIcons.RiShowersFill',
'RiIcons.RiShowersLine',
'RiIcons.RiSnowyFill',
'RiIcons.RiSnowyLine',
'RiIcons.RiSunCloudyFill',
'RiIcons.RiSunCloudyLine',
'RiIcons.RiSunFill',
'RiIcons.RiSunFoggyFill',
'RiIcons.RiSunFoggyLine',
'RiIcons.RiSunLine',
'RiIcons.RiTempColdFill',
'RiIcons.RiTempColdLine',
'RiIcons.RiTempHotFill',
'RiIcons.RiTempHotLine',
'RiIcons.RiThunderstormsFill',
'RiIcons.RiThunderstormsLine',
'RiIcons.RiTornadoFill',
'RiIcons.RiTornadoLine',
'RiIcons.RiTyphoonFill',
'RiIcons.RiTyphoonLine',
'RiIcons.RiWindyFill',
'RiIcons.RiWindyLine',

];
