import React, { Component } from 'react'
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Alert,Toast,Modal} from 'react-bootstrap'
import styled from 'styled-components';
import DataGrid from '../Components/Misc/DataGrid';
import RoleEditModal from '../Components/RoleEditModal';
const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    // background-color: white;
    font-weight: bold;
    text-align: center;`;

    const AddRole=styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:15px;
    margin-right:15px;
    `;

export class Form1 extends Component {


  constructor(props) {
    super(props);
    this.state = {
      rolename: '',
      roledescription: '',
      userroles:[],
      ready:false,
      showtoast1:false,
      showtoast2:false,
      showtoast3:false,
      error:'',
      roleeditid:0,
      showmodal:false,
      columnstoview:["Name","Description"],
      roleedittag:<RoleEditModal showmodal={true} roleeditid={1}/>
       
      }
}
toggleShowA=(event)=>{
  this.setState({showtoast1:false});
}
toggleShowB=(event)=>{
  this.setState({showtoast2:false});
  this.setState({error:''});
}
toggleShowC=(event)=>{
  this.setState({showtoast3:false});
}

componentDidMount() { 
  
  var userRoles=JSON.parse(localStorage.getItem("UserRoles") || "[]");
  this.setState({userroles:userRoles},()=>{this.setState({ready:true})});
  //gbconsole.log('userroles=',userRoles);
}

  submitRole=(event)=>{
    event.preventDefault();
    
    let postData = {
     
      "RoleName": this.state.rolename ,   
      "RoleDescription":this.state.roledescription,
      "RoleID":this.state.roleeditid

       }
     //gbconsole.log('postdata=',postData)
     let url = process.env.REACT_APP_DEBUG_URL+'/api/updaterole';
     let token =localStorage.getItem("UserToken");
     const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
      body: JSON.stringify(postData)
     // body: postData
  };

  fetch(url, requestOptions)
        .then(res => res.json())
        .then(result => {
          //gbconsole.log(result)          
          var status=result['status'];
          
    if(status=='Failure'){
    
    //gbconsole.log("error=",result['error'])
    this.setState({error:result['error']});
   
    this.setState({showtoast2:true});
          
    }else
    {
       
        localStorage.setItem("UserRoles", JSON.stringify(result.roles));
        var userRoles=JSON.parse(localStorage.getItem("UserRoles") || "[]");
        this.setState({userroles:userRoles});
        //gbconsole.log('userroles=',userRoles);
        this.setState({rolename:'',roledescription:'',showmodal:false});
        this.setState({showtoast1:true});
        
    }   

        }); 


  }
  markup (val) {
		return { __html: val }
	}
  hideModal=()=>{
    this.setState({showmodal:false,roleeditid:0});
}
  handleChange = (event) => {    
    this.setState({[event.target.name]: event.target.value});    
  }
  seteditid=(editid)=>{
   
    for(var i=0;i<this.state.userroles.length;i++){
      if(this.state.userroles[i]['ID']==editid){
        this.setState({rolename:this.state.userroles[i]['Name'],roledescription:this.state.userroles[i]['Description']});
        break;
      }
    }
  this.setState({roleeditid:editid},()=>{
    //gbconsole.log('roleeditid=',this.state.roleeditid)
    this.setState({showmodal:true},()=>{
     
    })
  });
  
  }
  
    render() {
      
            if(this.state.ready==false)
            return(<div></div>)
            else
     

        return (
            <Container>
          <Row>
              <Col>
              

<Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-success">Success</strong>                    
</Toast.Header>
<Toast.Body>Role created/updated</Toast.Body>
</Toast>

<Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-failure">Failure</strong>                    
</Toast.Header>
<Toast.Body dangerouslySetInnerHTML={this.markup(this.state.error)}></Toast.Body>
</Toast>

              </Col>
         
         </Row>
        
          <AddRole>
           <Button variant="primary" type="button" onClick={()=>{this.setState({showmodal:true,roleeditid:0,rolename:'',roledescription:''})}}>Add Role</Button>
           </AddRole>
         
         <Row>
              <Col>
              
              
              <FormHeader>Existing Roles</FormHeader>
              
              <DataGrid  rowsarray={this.state.userroles}  seteditid={this.seteditid } columns={this.state.columnstoview} />

              </Col>
        </Row>



        <Modal show={this.state.showmodal} onHide={this.hideModal} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Update Role
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
              <Container>
              <Row>
              <Col>
              
              <Form onSubmit={this.submitRole}>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Role</Form.Label>
    <Form.Control type="text"  name="rolename" value={this.state.rolename} onChange={this.handleChange} />
    {/* <Form.Text className="text-muted">
      We'll never share your email with anyone else.
    </Form.Text> */}
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
    <Form.Label>Role Description</Form.Label>
    <Form.Control as="textarea" rows={3} name="roledescription" value={this.state.roledescription} onChange={this.handleChange} />
  </Form.Group>
  
  <Button variant="primary" type="submit">
    Submit
  </Button>
  
</Form>

<Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-success">Success</strong>                    
</Toast.Header>
<Toast.Body>Role created/updated</Toast.Body>
</Toast>

<Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-failure">Failure</strong>                    
</Toast.Header>
<Toast.Body dangerouslySetInnerHTML={this.markup(this.state.error)}></Toast.Body>
</Toast>

              </Col>
         
         </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hideModal}>Close</Button>
            </Modal.Footer>
          </Modal>








</Container>
        )
    }
}

export default Form1
