import React from 'react';
import '../style.css';
import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import Module2 from './Module2';


class Module1 extends React.Component {


    constructor(props) {
        super(props);
    }
    
    componentDidMount() { 
       
      }


      render() { 

        const RouteWithSidebar = ({ component: Component, ...rest }) => {

            return (
                    
                <>
                <Header/>
                <Sidebar/>
                {Component}
                <Footer/>                   
                </>
              
              );
            };

        return (
            <RouteWithSidebar  component={<Module2 par={5}/>} />
            
           
        );
      }




}

export default Module1;