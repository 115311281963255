import React, { Component } from 'react'
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Alert,Toast,Modal} from 'react-bootstrap'

export class RoleEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
          rolename: '',
          roledescription: '',
          userroles:[],
          ready:false,
          showtoast1:false,
          showtoast2:false,
          showtoast3:false,
          error:'',
          showmodal:this.props.showmodal,
          roleeditid:this.props.roleeditid,
           
          }
    }
    componentDidMount() { 
		// //gbconsole.log('cmpdidmount');
		// this.setState({roleeditid:this.props.roleeditid},()=>{
        //     if(this.state.roleeditid!=0)
        //     this.setState({showmodal:true});
        //     else
        //     this.setState({showmodal:false});
        //     //gbconsole.log('showmodal=',this.state.showmodal);
        // });
        

	}
    hideModal=()=>{
        this.setState({showmodal:false,roleeditid:0});
    }
    markup (val) {
		return { __html: val }
	}
    render() {
        return (
            <Modal show={this.state.showmodal} onHide={this.hideModal} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Update Role
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
              <Container>
              <Row>
              <Col>
              
              <Form onSubmit={this.submitRole}>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Role</Form.Label>
    <Form.Control type="text"  name="rolename" value={this.state.rolename} onChange={this.handleChange} />
    {/* <Form.Text className="text-muted">
      We'll never share your email with anyone else.
    </Form.Text> */}
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
    <Form.Label>Role Description</Form.Label>
    <Form.Control as="textarea" rows={3} name="roledescription" value={this.state.roledescription} onChange={this.handleChange} />
  </Form.Group>
  
  <Button variant="primary" type="submit">
    Submit
  </Button>
  
</Form>

<Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-success">Success</strong>                    
</Toast.Header>
<Toast.Body>Role created/updated</Toast.Body>
</Toast>

<Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-failure">Failure</strong>                    
</Toast.Header>
<Toast.Body dangerouslySetInnerHTML={this.markup(this.state.error)}></Toast.Body>
</Toast>

              </Col>
         
         </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hideModal}>Close</Button>
            </Modal.Footer>
          </Modal>
        )
    }
}

export default RoleEditModal
