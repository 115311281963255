import React, { Component } from 'react';
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Alert,Toast,Modal,Image} from 'react-bootstrap'
import styled from 'styled-components';
import DataGrid from '../Components/Misc/DataGrid';
import { IconData } from './IconData';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    // background-color: white;
    font-weight: bold;
    text-align: center;`;

    const AddRole=styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:15px;
    margin-right:15px;
    `;
    const AddWeeklyHoliday=styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:15px;
    margin-right:35px;
    `;

    const IconDiv=styled.div`
    border: 1px solid #000;
    padding: 5px;
    width: 28px;
    margin-top: 3px;
    `;


export class Staffcalendar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            ready: false,           
            selectedmonthdates:[],
            selectedmonth:0,
            selectedyear:0,
            holidaylists:[],
            Staffcalendarlist:[],
            weeklyholidays:[0,0,0,0,0,0,0],
            tempweeklyholidays:[0,0,0,0,0,0,0],
            daylists:['S','M','T','W','T','F','S'],
            monthlists:['January','February','March','April','May','June','July','August','September','October','November','December'],
            monthindex:0,
            showmodal:false,
            showmodalweekly:false,
            Startdate:'',
            Enddate:'',
            Holidaydetail:'',
            showtoast1:false,
            showtoast2:false,
            error:'',
            columnstoview:["Startdate","Enddate","Holidaydetail"]
        }
    }
    componentDidMount() { 
        this.setcalendar();
    }
    handleChange = (event) => { 
      this.setState({[event.target.name]: event.target.value}); 

    }
    handleChange2 = (event) => { 
     this.setState({[event.target.name]: event.target.value}); 
     //gbconsole.log(event.target.checked)
     var tname=event.target.name;
     var index=parseInt(tname.substr(tname.length - 1));
     var idvalue=event.target.checked==true?1:0;
     var holidays=[... this.state.weeklyholidays];
     for(var i=0;i<holidays.length;i++){
        if(i==index){
          holidays[i]=idvalue;
        }
     }
     this.setState({weeklyholidays:holidays},()=>{
       //gbconsole.log(this.state.weeklyholidays)
    })

    }
    hideModal=()=>{
        this.setState({showmodal:false,Startdate:'',Enddate:'',Holidaydetail:''});
    }
    hideModalweekly=()=>{
      this.setState({showmodalweekly:false});
    }
    submitWeeklyHoliday=(event)=>{
      event.preventDefault();


      let postData = {
         
        

  
         }
       //gbconsole.log('postdata=',postData)
       let url = 'httpz://school.modernelectronic.co.in/api/UpdateStaffCalendarWeeklyHoliday';
       let token =localStorage.getItem("UserToken");
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
        body: JSON.stringify(postData)
       // body: postData
    };
  
    fetch(url, requestOptions)
          .then(res => res.json())
          .then(result => {
            //gbconsole.log(result)          
            var status=result['Status'];
            
      if(status=='Failure'||status=='ERROR'){
      
      //gbconsole.log("error=",result['Message'])
      this.setState({error:result['Message']});
     
      this.setState({showtoast2:true});
            
      }else
      {
         
        //   localStorage.setItem("StaffCalendar", JSON.stringify(result.staffcalendar));
        //   var staffcalendar=JSON.parse(localStorage.getItem("StaffCalendar") || "[]");
        //   this.setState({Staffcalendarlist:staffcalendar},()=>{this.setcalendar();});
        //   //gbconsole.log('staffcalendar=',staffcalendar);
        //   this.setState({
        //   Startdate:'',
        //   Enddate:'',
        //   Holidaydetail:'',
        //   recordid:0,
         
        //   showmodal:false
        // });
          this.setState({showtoast1:true});
          
      }   
  
          }); 


    }

    submitCalendar=(event)=>{
        event.preventDefault();
        
        let postData = {
         
          "startdate": this.state.Startdate ,   
          "enddate":this.state.Enddate,
          "holidaydetail":this.state.Holidaydetail,
          "recordid":this.state.recordid,

    
           }
         //gbconsole.log('postdata=',postData)
         let url = process.env.REACT_APP_DEBUG_URL+'/api/UpdateStaffCalendar';
         let token =localStorage.getItem("UserToken");
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
          body: JSON.stringify(postData)
         // body: postData
      };
    
      fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
              //gbconsole.log(result)          
              var status=result['Status'];
              
        if(status=='Failure'||status=='ERROR'){
        
        //gbconsole.log("error=",result['Message'])
        this.setState({error:result['Message']});
       
        this.setState({showtoast2:true});
              
        }else
        {
           
            localStorage.setItem("StaffCalendar", JSON.stringify(result.staffcalendar));
            var staffcalendar=JSON.parse(localStorage.getItem("StaffCalendar") || "[]");
            this.setState({Staffcalendarlist:staffcalendar},()=>{this.setcalendar();});
            //gbconsole.log('staffcalendar=',staffcalendar);
            this.setState({
            Startdate:'',
            Enddate:'',
            Holidaydetail:'',
            recordid:0,
           
            showmodal:false
          });
            this.setState({showtoast1:true});
            
        }   
    
            }); 
    
    
      }
      toggleShowA=(event)=>{
        this.setState({showtoast1:false});
      }
      toggleShowB=(event)=>{
        this.setState({showtoast2:false});
        this.setState({error:''});
      }

    setcalendar(){

        var staffcalendar=JSON.parse(localStorage.getItem("StaffCalendar") || "[]");
        


        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth()+this.state.monthindex, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth()+this.state.monthindex + 1, 0)
        var lastdate=lastDay.getDate()
        var firstday=firstDay.getDay()
        //gbconsole.log('first day=',firstDay.getDay()," lastday=",lastDay.getDate(),lastDay.getMonth(),lastDay.getFullYear(),lastDay.getDay())
        this.setState({selectedmonth:lastDay.getMonth(),selectedyear:lastDay.getFullYear(),Staffcalendarlist:staffcalendar},()=>{



          var selectedmonths=[]
          for(var i=0;i<firstday;i++){
              var dayobj={}
          dayobj['day']=''
          dayobj['date']=''
          dayobj['Isholiday']=0
          dayobj['holidaydetail']=''
          selectedmonths.push(dayobj)
  
          }
          for(var i=1;i<=lastdate;i++){
              var dayobj={}
          dayobj['day']=''
          dayobj['date']=i
          dayobj['Isholiday']=0
          dayobj['holidaydetail']=''
          for(var j=0;j<staffcalendar.length;j++){
            var startdate=staffcalendar[j]['Startdate'].split("-");
            var enddate=staffcalendar[j]['Enddate'].split("-");
            var stdate=new Date(parseInt(startdate[0]),parseInt(startdate[1])-1,parseInt(startdate[2]));
            var endate=new Date(parseInt(enddate[0]),parseInt(enddate[1])-1,parseInt(enddate[2]));
            var actualdate=new Date(parseInt(this.state.selectedyear),parseInt(this.state.selectedmonth),parseInt(""+i));
           
            //gbconsole.log('selectedmonth=',this.state.selectedmonth,'actualdate=',actualdate,'stdate=',stdate,'endate=',endate);
  
            if(actualdate>=stdate&&actualdate<=endate){
              dayobj['Isholiday']=1
              dayobj['holidaydetail']=staffcalendar[j]['Holidaydetail']
            }
  
          }
          selectedmonths.push(dayobj)
  
          }
          var remainingdays=35-selectedmonths.length;
          if(selectedmonths.length>35)
          remainingdays=42-selectedmonths.length;
          //gbconsole.log('remaining days=',remainingdays);
          for(var i=1;i<=remainingdays;i++){
              var dayobj={}
          dayobj['day']=''
          dayobj['date']=''
          dayobj['Isholiday']=0
          dayobj['holidaydetail']=''
          selectedmonths.push(dayobj)
  
          }
  
          this.setState({selectedmonthdates:selectedmonths},()=>{this.setState({ready:true})
            //gbconsole.log('selectedmo',this.state.selectedmonthdates,'selectedmonth=',this.state.selectedmonth)
          })

          
        })
        
       
        
    }
    markup (val) {
        return { __html: val }
    }


    seteditid=(editid)=>{
         
        for(var i=0;i<this.state.Staffcalendarlist.length;i++){
          if(this.state.Staffcalendarlist[i]['ID']==editid){
            this.setState({
              Startdate:this.state.Staffcalendarlist[i]['Startdate'],
              Enddate:this.state.Staffcalendarlist[i]['Enddate'],
              Holidaydetail:this.state.Staffcalendarlist[i]['Holidaydetail']
              
             
            
            });
            break;
          }
        }
      this.setState({recordid:editid},()=>{
        //gbconsole.log('recordid=',this.state.recordid)
        this.setState({showmodal:true},()=>{
         
        })
      });
      
      }

    render() {
        if(this.state.ready==false)
        return (
            <div>
                
            </div>

        )
        else{
            var calbody=this.state.selectedmonthdates.map((item, i) => {

                return (
                    <div className={i%7==0?this.state.selectedmonthdates[i].Isholiday==1?'holidayclearcaldate ':'clearcaldate':this.state.selectedmonthdates[i].Isholiday==1?'holidaycaldate ':'caldate'}>
                       
                        {this.state.selectedmonthdates[i].date}
                        <p className='holidaydetail'>{this.state.selectedmonthdates[i].holidaydetail}</p>
                        
                    </div>
                )


            });
            var daynames=this.state.daylists.map((item, i) => {

                return (
                    <div className={i%7==0?'clearcaldate weekdayname ':'caldate weekdayname'}>
                       
                        {this.state.daylists[i]}
                      

                        
                    </div>
                )


            });
            var weeklyholidays=this.state.weeklyholidays.map((item, i) => {

              return (
                  <div className='weeklyholiday'>
                     
                     <Form.Check type="checkbox" name={'check'+i }label={this.state.daylists[i]} onChange={this.handleChange2}/>
                    

                      
                  </div>
              )


          });

        return (
            <div className='calendar'>
                <Container>
                  <Row>
                    <Col>

                    <span className='calcaption'>{this.state.monthlists[this.state.selectedmonth]},{this.state.selectedyear}
                <Button className='calbutton' variant="primary" type="button" onClick={()=>{this.setState({monthindex:this.state.monthindex-1},()=>{this.setcalendar()})}}>&lt;</Button>
                <Button className='calbutton' variant="primary" type="button" onClick={()=>{this.setState({monthindex:this.state.monthindex+1},()=>{this.setcalendar()})}}>&gt;</Button>
                </span>
                 {daynames}
                 {calbody}
                    
                    
                    </Col>
                    <Col>


                    <AddRole>
                 <Button variant="primary" className='margin-right-10' type="button" onClick={()=>{this.setState({showmodalweekly:true})}}>Weekly Holiday</Button>
                 <Button variant="primary" type="button" onClick={()=>{this.setState({showmodal:true,Startdate:'',Enddate:'',Holidaydetail:''})}}>Add Holiday</Button>
                 </AddRole>

                 <Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
      <Toast.Header>            
      <strong className="mr-auto toast-header-success">Success</strong>                    
      </Toast.Header>
      <Toast.Body>Holiday created/updated</Toast.Body>
      </Toast>

                 <Modal show={this.state.showmodal} onHide={this.hideModal} aria-labelledby="contained-modal-title-vcenter">
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Update Calendar
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="show-grid">
                    <Container>
                    <Row>
                    <Col>
                    
                    <Form onSubmit={this.submitCalendar}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Start date *</Form.Label>
          <Form.Control type="date"  name="Startdate" value={this.state.Startdate} onChange={this.handleChange} />
         
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>End date *</Form.Label>
          <Form.Control type="date"  name="Enddate" value={this.state.Enddate} onChange={this.handleChange} />
         
        </Form.Group>
      
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Holiday Detail</Form.Label>
          <Form.Control as="textarea" rows={3} name="Holidaydetail" value={this.state.Holidaydetail} onChange={this.handleChange} />
        </Form.Group>

        
        <Button variant="primary" type="submit">
          Submit
        </Button>
        
      </Form>
      
      
      
      <Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
      <Toast.Header>            
      <strong className="mr-auto toast-header-failure">Failure</strong>                    
      </Toast.Header>
      <Toast.Body dangerouslySetInnerHTML={this.markup(this.state.error)}></Toast.Body>
      </Toast>
      
                    </Col>
               
               </Row>
              
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={this.hideModal}>Close</Button>
                  </Modal.Footer>
                </Modal>


                <Modal show={this.state.showmodalweekly} onHide={this.hideModalweekly} aria-labelledby="contained-modal-title-vcenter">
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Update Weekly Holiday
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="show-grid">
                    <Container>
                    <Row>
                    <Col>
                    
                    <Form onSubmit={this.submitWeeklyHoliday}>
                      
                    {weeklyholidays}

       

        
        <Button variant="primary" type="submit">
          Submit
        </Button>
        
      </Form>
      
      
      
      <Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
      <Toast.Header>            
      <strong className="mr-auto toast-header-failure">Failure</strong>                    
      </Toast.Header>
      <Toast.Body dangerouslySetInnerHTML={this.markup(this.state.error)}></Toast.Body>
      </Toast>
      
                    </Col>
               
               </Row>
              
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={this.hideModalweekly}>Close</Button>
                  </Modal.Footer>
                </Modal>






              
                   
                   <FormHeader>Existing Holidays</FormHeader>
                    
                    <DataGrid  rowsarray={this.state.Staffcalendarlist}  seteditid={this.seteditid } columns={this.state.columnstoview} />
      
                   
                 
                    
                    
                    </Col>
                  </Row>
                  </Container>
                
                
               
                
                 
                

                 

                
                    
            </div>

        )
        }

    }
}

export default Staffcalendar
