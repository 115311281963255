import React from 'react';

import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl,label,input,h2,h3,h4,h5} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';
import styled from 'styled-components';
import DataGrid from './Misc/DataGrid';


class About extends React.Component {


    constructor(props) {
        super(props);
    }
    
    componentDidMount() { 

      
    
        

        
       
      }

      


      render() { 
        const WorkArea = styled.div`
        background: white;
        left:250;
        height: 100vh;
        display: flex;
        
      `;

     

const tablerowarray=[

{
FirstName:"Admin",
LastName:"admin",
Jobtitle:"Administrator"

} ,
{
FirstName:"Govinda",
LastName:"Basu",
Jobtitle:"User"

} ,
{
FirstName:"Suprio",
LastName:"Basu",
Jobtitle:"User"

} ,





]

        return (
            <div>About us</div>
        );
      }




}

export default About;
 