import React from 'react';

import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl,label,Toast,input,h2,h3,h4,h5} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';
import styled from 'styled-components';
import DataGrid from './Misc/DataGrid';
import waitimage from '../assets/images/wait.webp';


class Invoicepayment extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            ready: false,
           

            course:'',
            hostelfacility:'',
            hostelfees:'',
            FirstName:'',
            MiddleName:'',
            LastName:'',
            StudentID:'',
            InvoiceID:'',
            InvoiceTotal:'',
            FeeDetails:[],
            IssueDate:'',
            PaymentDate:'',
            InvoiceStatus:'',
            PaymentType:'Cash',
            BankName:'',
            ChequeNumber:'',
            invoicelists:[],
            BranchName:'',
            AccountNumber:'',
            ReceivedDate:'',
            AccountHolderName:'',
            showtoast1:false,

            

            token:null,
            errors:{}
			
			
            
            
        }
    }
    
    componentDidMount() { 


      var requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        
      
    };
    let url = process.env.REACT_APP_DEBUG_URL+'/api/'+'getinvoicelists';

    fetch(url, requestOptions)
    .then(res => res.json())
    .then(result => {
        //gbconsole.log('invoicelist=',result);
        this.setState({
          invoicelists:result['invoicelists'],
           

        },()=>{
            this.setState({ready:true})
            //gbconsole.log('state=',this.state)
        })
    }

    )
       
        

        
       
      }
      toggleShowA=(event)=>{
        this.setState({showtoast1:false});
      }

      submitpayment=(event)=>{
        if(!this.handleValidation()){ return;}

        let postData = {
         
         
          "InvoiceID":this.state.InvoiceID,
          'BankName':this.state.BankName,
          'BranchName':this.state.BranchName,
          'AccountNumber':this.state.AccountNumber,
          'AccountHolderName':this.state.AccountHolderName,
          'PaymentType':this.state.PaymentType,
          'ReceivedDate':this.state.ReceivedDate,
          'ChequeNumber':this.state.ChequeNumber
          
           }
//gbconsole.log(postData)

           var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData)
          
        };
        let url = process.env.REACT_APP_DEBUG_URL+'/api/'+'invoicepayment';
    
        fetch(url, requestOptions)
        .then(res => res.json())
        .then(result => {
         
          this.setState({ready:false},()=>{

         
          this.setState({
            invoicelists:result['invoicelists']
          },()=>{
            //gbconsole.log(this.state.invoicelists)

            this.setState({
              course:'',
              hostelfacility:'',
              hostelfees:'',
              FirstName:'',
              MiddleName:'',
              LastName:'',
              StudentID:'',
              InvoiceID:'',
              InvoiceTotal:'',
              FeeDetails:'',
              IssueDate:'',
              PaymentDate:'',
              InvoiceStatus:'',
              PaymentType:'Cash',
              BankName:'',
              ChequeNumber:'',
              BranchName:'',
              AccountNumber:'',
              ReceivedDate:'',
              AccountHolderName:'',
              ready:true,
              showtoast1:true,
              errors: {}
            
            })
          



          })
        })


        })



      }

      handleValidation(){

        let errors = {};
        let formIsValid = true;

        if(!this.state.PaymentDate){
           formIsValid = false;
           errors["paymentDate"] = "Payment Date Cannot be empty";
        }
        
        
       
      

        this.setState({errors: errors});
        return formIsValid;
    }

      


      getInvoiceDetail=(event)=>{


let postData = {
         
         
    "InvoiceID":(this.state.InvoiceID)
    
     }
     var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      
    };
    let url = process.env.REACT_APP_DEBUG_URL+'/api/'+'getallinvoicedetails';

    fetch(url, requestOptions)
    .then(res => res.json())
    .then(result => {
        //gbconsole.log(result);
        this.setState({
            course:result['course'],
            hostelfacility:result['hostelfacility'],
            hostelfees:result['hostelfees'],
            FirstName:result['firstname'],
            MiddleName:result['middlenname'],
            LastName:result['lastname'],
            StudentID:result['studentid'],
            InvoiceID:result['invoiceid'],
            InvoiceTotal:result['invoicetotal'],
            FeeDetails:result['fees'],
            IssueDate:result['issuedate'],
            PaymentDate:result['paymentdate'],
            InvoiceStatus:result['invoicestatus'],

        },()=>{
            this.setState({ready:true})
            //gbconsole.log('state=',this.state)
        })
    }

    )
    

}

      handleChange = (event) => {    
        
        this.setState({[event.target.name]: event.target.value}); 
      }

      seteditid=(editid)=>{
        //gbconsole.log(editid)
        this.setState({InvoiceID:editid},()=>{


          let postData = {
         
         
            "InvoiceID":(this.state.InvoiceID)
            
             }
             var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(postData)
              
            };
            let url = process.env.REACT_APP_DEBUG_URL+'/api/'+'getallinvoicedetails';
        
            fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
                //gbconsole.log(result);
                var pdate=result['paymentdate']==null?'':result['paymentdate'].split(" ");
                //pdate=pdate[0].split("-");
                var reciveddate=pdate[0]==undefined?'':pdate[0];
                //gbconsole.log('received date=',reciveddate)

                this.setState({
                    course:result['course']==null?'':result['course'],
                    hostelfacility:result['hostelfacility']==null?'':result['hostelfacility'],
                    hostelfees:result['hostelfees']==null?'':result['hostelfees'],
                    FirstName:result['firstname']==null?'':result['firstname'],
                    MiddleName:result['middlenname']==null?'':result['middlenname'],
                    LastName:result['lastname']==null?'':result['lastname'],
                    StudentID:result['studentid']==null?'':result['studentid'],
                    InvoiceID:result['invoiceid']==null?'':result['invoiceid'],
                    InvoiceTotal:result['invoicetotal']==null?'':result['invoicetotal'],
                    FeeDetails:result['fees']==null?'':result['fees'],
                    IssueDate:result['issuedate']==null?'':result['issuedate'],
                    PaymentDate:result['paymentdate']==null?'':result['paymentdate'],
                    InvoiceStatus:result['invoicestatus']==null?'':result['invoicestatus'],
                    
              
              PaymentType:result['paymenttype'],
              BankName:result['BankName']==null?'':result['BankName'],
              ChequeNumber:result['ChequeNumber']==null?'':result['ChequeNumber'],
              BranchName:result['BranchName']==null?'':result['BranchName'],
              AccountNumber:result['AccountNumber']==null?'':result['AccountNumber'],
              ReceivedDate:reciveddate,
              AccountHolderName:result['AccountHolderName']==null?'':result['AccountHolderName'],
        
                },()=>{
                    this.setState({ready:true})
                  //  //gbconsole.log('state=',this.state)
                })
            }
        
            )


        })

       

      }


      render() { 

        if(this.state.ready==false)
        return(<div className='waitdiv'><center><img className="waitimage" src={waitimage} /></center></div>)
        
     
else


        return (
         

            
            <Container>
              <Row>
               <Col>
               {/* <Form>

               <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Invoice ID</Form.Label>
    <Form.Control name='InvoiceID' value={this.state.InvoiceID} onChange={this.handleChange} type="text" placeholder="Enter invoice id" />
    
    
  </Form.Group>
  <Button variant="primary" type="button" onClick={this.getInvoiceDetail}>
    Submit
  </Button>

               </Form> */}
               
               </Col>
            </Row>

            <Row>
               <Col><div className='invoicepaymentvaluebox'>Invoice ID:&nbsp;&nbsp;{this.state.InvoiceID}</div></Col>
          </Row>
          <Row className='invoicepaymenttitlebox'>
          
               <Col>Course:&nbsp;&nbsp;{this.state.course}</Col>
           
               <Col>Name:&nbsp;&nbsp;{this.state.FirstName +' '+ this.state.MiddleName +' '+this.state.LastName}</Col>
               
               <Col>Due:&nbsp;&nbsp;{this.state.InvoiceTotal}</Col>
           
               <Col>Payment Status:&nbsp;&nbsp;<font style={{color:this.state.InvoiceStatus=='Unpaid'?'red':'green'}}>{this.state.InvoiceStatus}</font></Col>
               
           </Row>
           <hr></hr>

            <Form>
               <Row>
                   <Col>
                  
                   <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Payment Type</Form.Label>
    <Form.Control as="select" id="selectedrole" className="margin-5 w-60" onChange={this.handleChange} name="PaymentType" value={this.state.PaymentType}>
                <option  value={'Cash'}>Cash</option>
                <option  value={'Cheque'}>Cheque</option>
  
</Form.Control>
    
  </Form.Group>
   
                   
                   </Col>
                   <Col>
                   <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Bank Name</Form.Label>
    <Form.Control type="text"  name="BankName" value={this.state.BankName} onChange={this.handleChange} />
    
  </Form.Group>
  
                   
                   </Col>
                   <Col>
                   <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Cheque Number</Form.Label>
    <Form.Control type="text" name="ChequeNumber" value={this.state.ChequeNumber} onChange={this.handleChange} />
    
  </Form.Group>

  </Col>
               </Row>
               <Row>
<Col>
  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Bank Branch Name</Form.Label>
    <Form.Control type="text"  name="BranchName" value={this.state.BranchName} onChange={this.handleChange} />
    
  </Form.Group>
  </Col>
  <Col>
  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Received Date</Form.Label>
    <Form.Control type="date"  name="ReceivedDate" value={this.state.ReceivedDate} onChange={this.handleChange} />
    <span style={{color: "red"}}>{
     this.state.errors["paymentDate"]
    }</span>
    
  </Form.Group>
  </Col>
  <Col>
  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Account Number</Form.Label>
    <Form.Control type="text"  name="AccountNumber" value={this.state.AccountNumber} onChange={this.handleChange} />
    
  </Form.Group>
  </Col>
  <Col>
  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Account Holder Name</Form.Label>
    <Form.Control type="text"  name="AccountHolderName" value={this.state.AccountHolderName} onChange={this.handleChange} />
    
  </Form.Group>
  </Col>
  </Row>
  <Row>
    <Col>

    <Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-success">Success</strong>                    
</Toast.Header>
<Toast.Body>Invoice Updated</Toast.Body>
</Toast>
    
    </Col>
  </Row>

  <Button variant="primary" type="button" onClick={this.submitpayment}>
    Submit
  </Button>
  
                   
                  
  </Form> 
  <Row>
    <Col>
    <DataGrid  rowsarray={this.state.invoicelists} columns={["InvoiceID","Status","Course","PaymentType","IssueDate","Total","HostelFees","PaymentDate","BankName","ChequeNumber"]} tableid={61} seteditid={this.seteditid } editfield={'InvoiceID'} advancefilter={1} />
    
    </Col>
  </Row>
            </Container>
          
      
        );
      }

    


}

export default Invoicepayment;
 