
import React, { Component } from 'react'
import {Form,Button,Container,Row,Col,Navbar,Nav,NavDropdown,FormControl,label,input,h2,h3,h4,h5} from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';
import DataGrid from './Misc/DataGrid';
import styled from 'styled-components';

const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    background-color: white;
    font-weight: bold;
    text-align: center;`;

    const RoleUpdate=styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:15px;
    margin-right:15px;
    padding:10px;
    `;



export class UserRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            intake_value: null,
            financialyrID:0,
            ready: false,
            userrolesassigned:[],
            columnstoview:["UserName","FirstName","LastName","Role"],
            selectedidarray:[],
            selectedrole:0,
            existingRoles:[],
          }
    }

    handleChange = (event) => {    
        this.setState({[event.target.name]: event.target.value},
          ()=>{
            //gbconsole.log('selected role=',this.state.selectedrole);
          });    
      }


    setselectedarray=(selectionarray)=>{
   
        this.setState({selectedidarray:selectionarray},()=>{
        //gbconsole.log('selectedidarray=',this.state.selectedidarray)
        
      });
      
      }




    
    componentDidMount() { 
  
        var userRoles=JSON.parse(localStorage.getItem("UserRoles") || "[]");
        var UserRolesAssigned=JSON.parse(localStorage.getItem("UserRolesAssigned") || "[]");
        this.setState({userrolesassigned:UserRolesAssigned},()=>{
          this.setState({existingRoles:userRoles},()=>{
            //gbconsole.log('roles=',this.state.existingRoles.length," first role=",this.state.existingRoles[0]['ID']);
            
            this.setState({ready:true})

          });
          
        
        });
        //gbconsole.log('userroles=',UserRolesAssigned);
      }
      roleassign =(event)=>{

        if(this.state.selectedrole==0){alert('Select a role first')}
        else{
          if(this.state.selectedidarray.length==0){
            alert('No user is selected');
          }else{
                          
         let postData = {
     
          "SelectedUsers": this.state.selectedidarray ,         
          "RoleID":this.state.selectedrole

       }
       //gbconsole.log('postdata=',postData)
       let url = process.env.REACT_APP_DEBUG_URL+'/api/updateroleassigned';
       let token =localStorage.getItem("UserToken");
       const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
        body: JSON.stringify(postData)
    
     };


     fetch(url, requestOptions)
        .then(res => res.json())
        .then(result => {
          //gbconsole.log(result)          
          var status=result['status'];
          
    if(status=='Failure'){
    
    // //gbconsole.log("error=",result['error'])
    // this.setState({error:result['error']});
   
    // this.setState({showtoast2:true});
          
    }else
    {
        document.getElementById("selectedrole").value=0;
        var checkboxes=document.getElementsByTagName("input")
        for(var i=0;i<checkboxes.length;i++){
          if(checkboxes[i].type=='checkbox'&&checkboxes[i].checked==true){
            checkboxes[i].click();
          }
        }
        this.setState({selectedrole:0,selectedidarray:[]})
        localStorage.setItem("UserRolesAssigned", JSON.stringify(result.rolesassigned));
        var UserRolesAssigned=JSON.parse(localStorage.getItem("UserRolesAssigned") || "[]");
        this.setState({userrolesassigned:UserRolesAssigned},()=>{});
        
    }   

        }); 
            
            
  





          }
         
        }

      }
     
    render() {
        if(this.state.ready==false)
            return(<div></div>)
            else
        return (
            <Container>
            <Row>
            <Col>
            <FormHeader>Existing Roles</FormHeader>
              <RoleUpdate>
             
              <Form.Control as="select" id="selectedrole" className="margin-5 w-60" onChange={this.handleChange} name="selectedrole">
                <option key ={0} value={0}>Select Role</option>
              
               {
              (()=>{
                if(this.state.existingRoles!=undefined&&this.state.existingRoles.length>0){
                
                  let roles=[];
                    
                  for(var i=0;i<this.state.existingRoles.length;i++){
                   
                    roles.push(<option key={this.state.existingRoles[i]['ID']} value={this.state.existingRoles[i]['ID']}>{this.state.existingRoles[i]['Name']} </option>);
                                          
                    
                    

                }
               
                return roles;

                }
                else
                return null;
                  
                    
               })()
    }
             </Form.Control>
             <Button variant="primary" type="button" className="w-40 margin-top-5 height-36" 
             onClick={this.roleassign}>Assign Role</Button>
        

              </RoleUpdate>
              <DataGrid  setselectedarray={this.setselectedarray} rowsarray={this.state.userrolesassigned} columns={this.state.columnstoview} showeditbutton={false} showselection={true}/>
            </Col>
            </Row>
            </Container>
        )
    }
}

export default UserRole
