import React, { Component } from 'react'
import {Card,Container,Row,Col} from 'react-bootstrap'
import { FcBusinessman ,FcBriefcase,FcBullish,} from "react-icons/fc";

export class Dashboard extends Component {
    render() {
        const Cards= [
            '<PriFcBriefcase/>',
            '<FcBusinessman/>',

            
          ].map((variant, idx) => (  
            <Col xs={3}>         
            <Card
              bg={variant.toLowerCase()}
              key={idx}
              text={variant.toLowerCase() === 'light' ? 'dark' : 'white'}
              style={{ width: '18rem' }}
              className="mb-2"
            >
              
              <Card.Body>
                
                <Card.Text>
                <FcBusinessman/>
                {variant}
                </Card.Text>
              </Card.Body>
            </Card>
            </Col>
            
          ));
        return ( <Container><Row> {Cards} </Row> </Container> )         
               
    }
}

export default Dashboard
